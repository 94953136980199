<template>
    <div class="has-margin-top">
        <div class="bp-panel has-no-title" style="min-height: auto">
            <div class="panel-content">
                <div v-for="(object, index) in objects">
                    <div class="field">
                        <div class="control">
                            <div class="level">
                                <div class="level-left">
                                    <label class="checkbox" style="white-space: nowrap;overflow: hidden;width: 100%;">
                                        <a class="is-link" target="_blank" :href="getLink(object)">{{getLabel(object, index+1)}}</a>
                                    </label>
                                </div>
                                <div class="level-right">
                                    <toggle-switch v-if="isHotel" :value="getHotelStatus(object)" @input="value => setHotelStatus(value, object)" btnStyle="padding:5px">
                                        <option value="disabled">
                                            <i class="material-icons" title="nicht auf Homepage anzeigen" style="font-size: 14px">visibility_off</i>
                                        </option>
                                        <option value="example" >
                                            <i class="material-icons" title="als Beispiel für Homepage" style="font-size: 14px">location_off</i>
                                        </option>
                                        <option value="selected">
                                            <i class="material-icons"  title="festgelegtes Hotel für Homepage" style="font-size: 14px">location_on</i>
                                        </option>
                                    </toggle-switch>
                                </div>
                            </div>

                        </div>
                        <div class="control">
                        </div>
                        <p class="is-small-text">
                            <span class="is-text-red" v-if="isHotel && !object.selected">
                                nicht für Homepage ausgewählt
                            </span>
                            <template v-for="(checkItem, i) in component.props.checkItems" v-else>
                                    <span v-if="i > 0"> | </span>
                                    <span
                                        :class="getClass(object, checkItem)"
                                    >{{checkItem}}</span>
                                    <i class="material-icons" v-if="getItem(object, checkItem) && getItem(object, checkItem).doneDate" style="font-size: 10px" :class="getClass(object, checkItem)">check</i>
                                    <i class="material-icons" v-if="!getItem(object, checkItem) || !getItem(object, checkItem).doneDate" style="font-size: 10px" :class="getClass(object, checkItem)">close</i>
                            </template>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Mixin from '../../tab';
import {ToggleSwitch} from "@components/form";


export default {
    mixins: [ Mixin ],



    props: {
        component: {
            type: Object,
            required: true
        },
    },

    data: function() {
        return {
            form: {
                websites: []
            }
        }
    },

    methods: {
        getLabel: function (object, index) {
            if(this.isDay){
                return index + '. Tag: ' + object.name;
            }
            if(this.isHotel){
                return object.name
            }
            if(this.isDestination){
                return object.name
            }
        },


        getLink: function (object) {
            if(this.isDay){
                return '/concepts/days/' + object.id;
            }
            if(this.isHotel){
                return '/hotels/' + object.id;
            }
            if(this.isDestination){
                return '/destinations/' + object.id;
            }
        },

        getItem(object, title) {
            return object.checklistItems ? object.checklistItems.find(item => item.title === title) : null;
        },

        getClass: function (object, title) {
            let item = this.getItem(object, title);
            return item && item.doneDate ? 'is-text-blue' : 'is-text-red';
        },

        getHotelStatus: function (object) {
              return object.selected;
        },

        setHotelStatus: function (value, object) {
            let id = String(object.id)
            if(!this.item.publishedHotelIds) {
                this.item.publishedHotelIds = {};
            }
            this.item.publishedHotelIds[id] = value;
            this.$emit('saveItemDebounce', 'publishedHotelIds', this.item.publishedHotelIds, true);


            this.$store.commit('updateData', {
                conceptProviderHotels: this.item.conceptProviderHotels.map(hotel => ({
                    ...hotel,
                    ...hotel.id === object.id && {selected: value}
                }))
            })



            return;


            if(foundIndex !== -1){
                if(this.item.publishedHotelIds)
                this.item.publishedHotelIds.splice(foundIndex, 1);
                this.$emit('saveItemDebounce', 'publishedHotelIds', this.item.publishedHotelIds, true);
                this.$store.commit('updateData', {
                    conceptProviderHotels: this.item.conceptProviderHotels.map(hotel => ({
                        ...hotel,
                        ...hotel.id === object.id && {selected: false}
                    }))
                })
            } else {
                this.item.publishedHotelIds.push(id);

                this.$emit('saveItemDebounce', 'publishedHotelIds', this.item.publishedHotelIds, true);
                this.$store.commit('updateData', {
                    conceptProviderHotels: this.item.conceptProviderHotels.map(hotel => ({
                        ...hotel,
                        ...hotel.id === object.id && {selected: true}
                    }))
                })
            }
        }
    },

    computed: {

        isDay: function () {
            return this.component.props.object === 'day';
        },

        isHotel: function () {
            return this.component.props.object === 'hotel';
        },

        isDestination: function () {
            return this.component.props.object === 'destination';
        },

        objects: function () {
            if(this.isDay){
                return this.item.orderedDays;
            }
            if(this.isHotel){
                return this.item.conceptProviderHotels;
            }
            if(this.isDestination){
                return this.item.destinations;
            }
        }
    },

    components: {
        ToggleSwitch,
    }
}
</script>
