<template>
    <div>
        <button
            class="button is-large"
            v-on:click="openModal">
        <i class="material-icons">help</i> Zuordnen</button>

        <modal
            v-bind:visible="isVisible"
            v-on:closeModal="closeModal"
            class="is-bp-form is-large"
        >
            <template slot="header">EMAIL EINEM ODER MEHREREN VORGÄNGEN ZUORDNEN</template>


            <template slot="content">
                <div>
                    <label>Vorgänge:</label>
                    <order-select v-model="ordersSelected" multiple />
                </div>


                <div>
                    <label>Beschreibung:</label>
                    <tag-select
                        v-model="tagsSelected"
                        v-bind:option-tags="optionsTags"
                    />
                </div>
            </template>


            <div slot="footer">
                <button class="button is-large" v-on:click="closeModal"><i class="material-icons">close</i> Verwerfen</button>

                <button
                    class="button is-primary is-large"
                    v-bind:class="{ 'is-loading': isLoading.save }"
                    v-on:click="assign"
                ><i class="material-icons">check</i> Zuordnen</button>
            </div>
        </modal>
    </div>
</template>



<script>
import debounce    from 'lodash/debounce'
import Modal       from '@components/Modal';
import Multiselect from '@components/form/Multiselect';
import OrderSelect from '@components/form/OrderSelect';
import { notifySuccess, notifyError } from '@components/Notification';
import { dayMonth, progressPromise }  from '@utilities/functions';
import { addEmail, editEmail, getOrders } from '@api';
import store from '../store';
import TagSelect from "@components/form/TagSelect";
import {defaultEmailTags} from "@clientSpecific/utilities/defaultValues";


export default {
    store: store,


    computed: {
        hasLiveEmails: function () {
            for(let i = 0, len = this.emailsSelected.length; i < len; i++) {
                if (!this.emailsSelected[i].id) {
                    return true;
                }
            }
            // We didn't find any live emails (=> that don't have an ID)
            return false;
        },

        direction:      function () { return this.$store.state.emailsSelected.direction; },
        emailsSelected: function () { return this.$store.state.emailsSelected[this.direction]; }
    },


    data: function () {
        return {
            ordersSelected: [],
            tagsSelected: [],
            orders: [],

            optionsTags: defaultEmailTags,

            isVisible: false,
            isLoading: {
                save: false
            }
        }
    },


    methods: {
        orderLabel: function (order) {
            return '# ' + order.orderNumber +
                    ' - ' + order.client.companyName +
                    ' - ' + dayMonth(order.startAt) + ' - ' + fullYear(order.endAt);
        },


        customTagLabel: function ({ id, name }) {
            if (id) {
                return '# ' + id + ' - ' + name;

            } else {
                return name;
            }
        },


        assign: function () {
            let calls = [];

            this.isLoading.save = true;

            this.emailsSelected.forEach(email => {
                if (email.id) {
                    calls.push(editEmail({
                        id:   email.id,
                        data: {
                            links: this.ordersSelected.map(item => ({ order: '/api/orders/' + item.id })),
                            tags:  this.tagsSelected.map(item => item.name)
                        }
                    }));
                } else {
                    calls.push(addEmail({
                        ...email,
                        links: this.ordersSelected.map(item => ({ order: '/api/orders/' + item.id })),
                        tags:  this.tagsSelected.map(item => item.name)
                    }));
                }
            });

            Promise.all(calls)
                .then(responses => {}, error => {})
                .then(() => {
                    this.isLoading.save = false;
                    this.closeModal();
                    this.$store.commit('updateFolder', true);
                });
        },


        openModal: function () {
            if (this.emailsSelected.length > 0) {
                this.init();
                this.isVisible = true;
            }
        },


        init: function () {
            this.ordersSelected = [];
            this.tagsSelected   = [];

            // If any
            this.prefill();
        },


        prefill: function () {
            if (this.emailsSelected.length === 1) {
                const email = this.emailsSelected[0];

                if (email.tags && email.tags.length > 0) {
                    this.tagsSelected = email.tags.map(tag => { return { name: tag } });
                }

                if (email.links && email.links.length > 0) {
                    this.ordersSelected = email.links.map(link => link.order );
                }
            }
        },


        closeModal: function () {
            if (!this.isLoading.save) {
                this.isVisible = false;
            }
        }
    },


    components: {
        TagSelect,
        Modal,
        Multiselect,
        OrderSelect
    }
}
</script>
