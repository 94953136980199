var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c("loading")
    : _vm.email
    ? _c("div", [
        _c(
          "header",
          [
            _c("div", [
              _c("b", [_vm._v("Betreff:")]),
              _vm._v(" " + _vm._s(_vm.email.subject)),
            ]),
            _vm._v(" "),
            _vm.email.type === "email"
              ? [
                  _c("div", [
                    _c("b", [_vm._v("Von:")]),
                    _vm._v(" " + _vm._s(_vm.email.fromAddress) + " "),
                    _vm.email.fromName
                      ? _c("div", { staticClass: "tag-gray" }, [
                          _vm._v(_vm._s(_vm.email.fromName)),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("b", [_vm._v("An:")]),
                      _vm._v(" "),
                      _vm._l(_vm.email.toAddresses, function (address, index) {
                        return [
                          index > 0 ? [_vm._v(", ")] : _vm._e(),
                          _vm._v(
                            _vm._s(address.email || address) +
                              "\n                "
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              : [
                  _c("div", [
                    _c("b", [_vm._v("Telefonat von:")]),
                    _vm._v(" " + _vm._s(_vm.email.user.firstName)),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("b", [_vm._v("Telefonat mit:")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.email.contact.emailInfos &&
                            _vm.email.contact.emailInfos.length > 0
                            ? _vm.email.contact.emailInfos[2]
                            : ""
                        ) +
                        "\n            "
                    ),
                  ]),
                ],
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.email.links.length == 1 && _vm.email.links[0].order
                  ? _c(
                      "a",
                      {
                        staticClass: "tag-white",
                        attrs: {
                          href: "/orders/" + _vm.email.links[0].order.id,
                          title: "Go to Order",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.email.links[0].order.client.companyName) +
                            " | " +
                            _vm._s(
                              _vm._f("dayMonth")(
                                _vm._f("dateISOtoView")(
                                  _vm.email.links[0].order.startAt
                                )
                              )
                            ) +
                            " - " +
                            _vm._s(
                              _vm._f("fullYear")(
                                _vm._f("dateISOtoView")(
                                  _vm.email.links[0].order.endAt
                                )
                              )
                            ) +
                            " "
                        ),
                        _vm._l(
                          _vm.email.links[0].order.trip.destinations,
                          function (destination) {
                            return [
                              _vm._v(" | " + _vm._s(destination.name) + " "),
                            ]
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.email.tags && _vm.email.links.length > 1
                  ? _c("div", { staticClass: "tag-white" }, [
                      _vm._v("mehrere Reisen"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.email.tags, function (tag, index) {
                  return _vm.email.tags && _vm.email.tags.length > 0
                    ? [
                        _vm.email.tags && _vm.email.tags.length > 0
                          ? _c("div", { staticClass: "tag-white" }, [
                              _vm._v(_vm._s(tag)),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e()
                }),
              ],
              2
            ),
            _vm._v(" "),
            _vm.nrOfAttachments > 0
              ? _c("div", { staticClass: "attachments" }, [
                  _c(
                    "div",
                    { staticClass: "list" },
                    _vm._l(_vm.email.attachments, function (attachment) {
                      return attachment.disposition !== "inline" ||
                        attachment.type !== "image"
                        ? _c(
                            "a",
                            {
                              staticClass: "attachment",
                              attrs: {
                                href: _vm.getHref(attachment),
                                target: "_blank",
                                download: "",
                              },
                            },
                            [
                              _c("span", [
                                _c("i", {
                                  class: _vm.attachmentIcon(
                                    attachment.type,
                                    attachment.subtype
                                  ),
                                }),
                                _c(
                                  "b",
                                  { attrs: { title: attachment.filename } },
                                  [_vm._v(_vm._s(attachment.filename))]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "date" }, [
              _vm._v(_vm._s(_vm.email.date || _vm.dateWithLocalTime)),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { ref: "iframe1", staticClass: "email" },
          [
            _vm.email
              ? _c("email-html", { attrs: { email: _vm.email } })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _c("div", { staticClass: "empty-view" }, [
        _vm._v("\n    Keine Email ausgewählt\n"),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }