var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "placeholder-view",
      class: {
        "has-requests": "!isConcept",
        "has-no-route-description":
          !_vm.placeholder.routeDescription && _vm.isRouteView,
        "is-optional":
          _vm.placeholder.clientOffer.status === "optional" ||
          _vm.placeholder.clientOffer.status === "atLocation",
        "is-additional": _vm.placeholder.clientOffer.status === "additional",
      },
    },
    [
      _vm.isRouteView
        ? _c(
            "div",
            { attrs: { title: _vm.placeholder.routeDescription } },
            [
              _c(
                "i",
                { staticClass: "material-icons", attrs: { title: "Hotel" } },
                [_vm._v("home")]
              ),
              _vm._v(" "),
              _vm.placeholder.routeDescription
                ? [
                    _vm.placeholder.clientOffer.status === "optional"
                      ? [_vm._v("Möglichkeit: ")]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.placeholder.clientOffer.status === "atLocation"
                      ? [_vm._v("vor Ort: ")]
                      : _vm._e(),
                    _vm._v(
                      _vm._s(_vm.placeholder.routeDescription) +
                        "\n            "
                    ),
                  ]
                : [
                    _vm._v(
                      "ohne Routenbeschreibung (" +
                        _vm._s(_vm.placeholder.serviceDescription) +
                        ")"
                    ),
                  ],
            ],
            2
          )
        : _c(
            "div",
            { attrs: { title: _vm.placeholder.serviceDescription } },
            [
              _c(
                "i",
                { staticClass: "material-icons", attrs: { title: "Hotel" } },
                [_vm._v("home")]
              ),
              _vm._v(" "),
              _vm.placeholder.clientOffer.status === "optional"
                ? [_vm._v("Möglichkeit: ")]
                : _vm.placeholder.clientOffer.status === "atLocation"
                ? [_vm._v("vor Ort: ")]
                : _vm._e(),
              _vm._v(
                "\n            " +
                  _vm._s(_vm.placeholder.serviceDescription) +
                  " "
              ),
              _vm.placeholder.selectedRequest
                ? [
                    _vm._v(
                      _vm._s(
                        _vm.placeholder.selectedRequest.info.provider.name
                          ? ""
                          : " (No-Name)"
                      )
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
      _vm._v(" "),
      _c(
        "div",
        { class: { "is-hidden-on-small": !_vm.isConcept } },
        [
          _vm.showDuration
            ? [
                _vm.isConcept
                  ? [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.placeholder.nights) +
                          "\n                    " +
                          _vm._s(
                            _vm.placeholder.nights > 1 ? " Nächte" : " Nacht"
                          ) +
                          "\n                "
                      ),
                    ]
                  : [
                      _vm._v(
                        _vm._s(_vm._f("dayMonth")(_vm.placeholder.startAt)) +
                          " - " +
                          _vm._s(_vm._f("fullYear")(_vm.placeholder.endAt))
                      ),
                    ],
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.isConcept
        ? _c("div", { staticClass: "is-60" }, [
            _vm._v(
              _vm._s(
                _vm.timeUntil(_vm.placeholder.startAt, _vm.placeholder.endAt)
                  .days
              ) + " N"
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "is-100 is-hidden-on-small" }),
      _vm._v(" "),
      !_vm.isConcept
        ? _c(
            "div",
            { staticClass: "contingent-section" },
            [
              _vm.placeholder.info && _vm.placeholder.info.contingent
                ? [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.placeholder.info.contingent) +
                        " Zimmer\n                "
                    ),
                    _vm.hasContingentWarning
                      ? _c("i", {
                          staticClass: "fa fa-warning",
                          attrs: { title: "Unterdeckung" },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isConcept
        ? _c(
            "div",
            [
              _c("deadline", {
                attrs: { value: _vm.placeholder.info.deadline },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isConcept
        ? _c("agency-and-provider", {
            attrs: {
              agency: _vm.placeholder.info.agency,
              provider: _vm.placeholder.info.provider,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isConcept
        ? _c("concept-provider", {
            attrs: { providers: _vm.placeholder.providers },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isConcept
        ? _c(
            "div",
            { staticClass: "is-130" },
            [
              _vm.placeholder.requests && _vm.placeholder.requests.length
                ? [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.getValueWithKey({
                            key: _vm.placeholder.info.status,
                            optionList: _vm.statusesList,
                          })
                        ) +
                        "\n            "
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "action-buttons" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _vm.placeholder.requests && _vm.placeholder.requests.length > 0
        ? _c(
            "button",
            {
              staticClass: "nr-of-requests",
              class: ["is-" + _vm.placeholder.info.urgency],
              attrs: { title: "Anfragen" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("toggleRequests")
                },
              },
            },
            [_vm._v(_vm._s(_vm.placeholder.requests.length))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.placeholder.sortGroup
        ? _c("div", {
            staticClass: "group-icon",
            class: "is-" + _vm.placeholder.sortGroup,
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }