<template>
    <div class="tab-addition">
        <div class="tab-addition__header">
            <header>
                <button
                    :class="{'is-active': category === 'Stornokosten'}"
                    v-on:click="changeCategory('Stornokosten')"
                >Stornokosten</button>
                <button
                    :class="{'is-active': category === 'Zahlungen'}"
                    v-on:click="changeCategory('Zahlungen')"
                >Zahlungen</button>
                <button
                    :class="{'is-active': category === 'Namensänderungen'}"
                    v-on:click="changeCategory('Namensänderungen')"
                >Namensänderungen</button>
            </header>
        </div>

        <div class="main is-gapless">
            <table class="conditions-table">
                <tr>
                    <th class="is-medium">Frist</th>
                    <th class="is-small b-r"></th>
                    <th colspan="2">Freitext</th>
                </tr>

                <condition
                    v-for="condition in conditions"
                    v-bind:key="condition.id"

                    v-bind:condition="condition"
                    v-on:save="editCondition"
                    v-on:delete="deleteCondition(condition)"
                />

                <tr v-if="!addMode" >
                    <td colspan="4">
                        <button v-on:click="addMode = true">
                            <i class="material-icons">add</i>
                        </button>
                    </td>
                </tr>

                <condition
                    v-else
                    v-on:delete="addMode = false"
                    v-on:save="addCondition"
                />
            </table>
        </div>
    </div>
</template>

<script>
import Mixin           from '../tab';
import Condition       from './Condition';

export default {
    mixins: [Mixin],

    data: function () {
        return {
            addMode: false,
            category: 'Stornokosten'
        }
    },

    computed: {
        conditions: function () {
            if(!this.item || !this.item.conditions) {
                return [];
            }
            return this.item.conditions
                .filter(item => item.category === this.category)
        }
    },

    methods: {
        changeCategory: function (category) {
            this.category = category;
            this.addMode = false;
        },

        prepareForBackend: function (data) {
            return {
                ...!!data.id && { id: data.id },
                conditionText: data.conditionText,
                deadlineDate: data.deadlineDate,
                category: this.category
            }
        },

        addCondition: function (condition = {}) {
            this.$store.dispatch('updateProperty', { key: 'conditions', data: this.prepareForBackend(condition)});
            this.addMode = false;
        },

        editCondition: function (condition = {}) {
            this.$store.dispatch('updateProperty', { key: 'conditions', data: this.prepareForBackend(condition)})
        },

        deleteCondition: function (condition) {
            this.deleteItem('conditions', condition);
        }
    },

    components: {
        Condition
    }
}
</script>
