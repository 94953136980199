var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue2-dropzone",
    {
      ref: "dropzone",
      attrs: {
        id: "dropzone",
        options: _vm.dropzoneOptions,
        useCustomSlot: "",
      },
      on: {
        "vdropzone-sending": _vm.sendingEvent,
        "vdropzone-success": _vm.fileSuccess,
        "vdropzone-complete": _vm.fileCompleted,
        "vdropzone-files-added": _vm.filesAdded,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dz-dropzone", attrs: { id: "documentDropZone" } },
        [_vm._v("Dokument zum hochladen hierhin ziehen")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }