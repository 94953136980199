<template>
    <div>
        <span class="badges">
            <span
                class="badge"
                v-for="year in years"
                v-bind:class="`is-${year}`"
                v-bind:key="year"
            >{{year}}</span>
        </span>
    </div>
</template>

<script>
import { years } from '@utilities/variables';
import Cell from './cell';

export default {
    mixins: [ Cell ],

    props: {
        value: {
            type: Array,
        },
    },

    computed: {
        years: function () {
            return years.filter(tag => {
                return this.value.includes(tag)
            });
        },

    },

}
</script>

