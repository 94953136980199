<template>
    <modal-form
        createTitle="Neue Bild"
        editTitle="Bild bearbeiten"
        editButton="Speichern"
        class="is-image-form"
    >
        <table>
            <tr v-if="!multiple">
                <th><label for="title" class="label">Bildtitel:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="title" class="input" v-model="form.title">
                    </div>
                </td>
            </tr>
            <tr v-if="showType">
                <th><label for="type" class="label">Kategorie:</label></th>
                <td>
                    <multiselect
                        v-if="options.categories"
                        v-bind:options="options.categories"
                        track-by="key"
                        label="value"
                        v-model="type"
                    />
                </td>
            </tr>

            <tr v-if="!isHomepageImage">
                <th><label for="folder" class="label">Ordner:</label></th>
                <td>
                    <multiselect
                        v-if="imageFolders"
                        v-bind:options="imageFolders"
                        track-by="id"
                        label="name"
                        v-model="folder"
                    />
                </td>
            </tr>

          <tr v-if="isHomepageImage">
            <th><label for="alternativeText" class="label">Alternativ-Text:</label></th>
            <td>
              <input type="text" class="input" v-model="form.alternativeText">
            </td>
          </tr>

<!--          <tr v-if="isHomepageImage">-->
<!--            <th><label for="backgroundText" class="label">Hintergrund-Text:</label></th>-->
<!--            <td>-->
<!--              <textarea class="textarea" rows="3" v-model="form.backgroundText"></textarea>-->
<!--            </td>-->
<!--          </tr>-->



            <template v-if="!multiple && !isHomepageImage">
                <tr>
                    <th><label class="label">Art:</label></th>
                    <td>
                        <toggle-switch v-model="form.isGoogleMaps">
                            <option v-bind:value="false">Datei</option>
                            <option v-bind:value="true">Bild von Google Maps</option>
                        </toggle-switch>
                    </td>
                </tr>

                <map-form
                    v-if="form.isGoogleMaps"
                    v-model="form.googleMap"
                    v-bind:options="options"
                />
            </template>
        </table>
    </modal-form>
</template>

<script>
import has from 'lodash/has';

import { ToggleSwitch } from '@components/form';
import { googleMapForm } from '@utilities/variables';

import MapForm from './MapForm';
import TabForm from '../form';

export default {
    mixins: [ TabForm ],

    props: {
        showType: {
          type: Boolean,
          default: true
        }
    },

    form: {
        title: '',
        type: '',
        folder: null,
        googleMap: JSON.parse(JSON.stringify(googleMapForm)),
        backgroundText: '',
        alternativeText: '',
        isGoogleMaps: false
    },

    property: 'images',

    computed: {
        imageFolders: function() {
            return this.$store.state.data.imageFolders
        },

        isHomepageImage: function () {
            return this.form.type && this.form.type.includes('homepage_');
        },

        folder: {
            get: function() { return this.form.folder },
            set: function(folder) { this.form.folder = folder }
        },

        type: {
            get: function () { return this.options.categories.find(type => type.key === this.form.type)},
            set: function (type) { this.form.type = !!type ? type.key : null}
        },

        multiple: function () {
            return (Array.isArray(this.form.id) && this.form.id.length > 1);
        }
    },

    methods: {
        openCallback: function ({googleMap}) {
            if (has(googleMap, 'id')) {
                this.form.isGoogleMaps = true;

                this.form.googleMap = JSON.parse(JSON.stringify({
                    ...googleMap,
                    ...!!googleMap.latitude2 && googleMap.latitude2 !== "0" && {
                        isRouteMap: true
                    }
                }))
            }
        },

        close: function () {
            this.visible = false;
            this.form = JSON.parse(JSON.stringify(this.$options.form))
        },

        handleFormSubmit: function () {
            this.$emit('submit', {
                ...this.form.id && {
                    id: this.form.id,
                },
                title: this.form.title,
                type: this.form.type,
                alternativeText: this.form.alternativeText,
                backgroundText: this.form.backgroundText,
                folder: this.form.folder && this.form.folder.id ? `/api/image_folders/${this.form.folder.id}` : this.form.folder,
                googleMap: this.form.googleMap
            })
        },
    },

    components: {
        ToggleSwitch,
        MapForm
    }
}
</script>
