import ImportId from './_importId';
import Address from './Address';
import Addresses from "./Addresses";
import AddressCountry from "./AddressCountry";
import Airports from './Airports';
import Board from './Board';
import BuspaketRating from './BuspaketRating';
import CabinType from './CabinType';
import Category from './Category';
import Check from './Check';
import CheckStatus from './CheckStatus';
import Client from './Client';
import Complexity from "./Complexity";
import Contingent from './Contingent';
import Country from './Country';
import DataCheckedAt from './DataCheckedAt';
import Difficulty from './Difficulty';
import Deadline from './Deadline';
import Days from './Days';
import DateCell from './Date';
import Email from './Email';
import Favicon from './Favicon';
import HotelType from './HotelType';
import IsDefaultFor from './IsDefaultFor';
import Importance from './Importance';
import Locale from './Locale';
import Location from './Location';
import ServiceProvider from './ServiceProvider';
import LastEmailContact from "./LastEmailContact";
import LocationCharges from './LocationCharges';
import MainLanguage from './MainLanguage';
import Order from './Order';
import Ports from './Ports';
import Price from './Price';
import Provider from './Provider';
import Providers from './Providers';
import Rentability from "./Rentability";
import RequestSource from "./RequestSource";
import RoomType from './RoomType';
import Stars from './Stars';
import Status from './Status';
import Tags from './Tags';
import TrainStations from './TrainStations';
import TraitTags from './TraitTags';
import Translations from './Translations';
import Type from './Type';
import Url from './Url';
import User from './User';
import ClientInvoice from './ClientInvoice';
import Users from './Users';
import WhiteTags from "./WhiteTags";
import Years from './Years';
import Zip from "./Zip";
import Activity from "./Activity";
import Boolean from "./Boolean";
import OrganisationFilter from "./OrganisationFilter.vue";

export default {
    Address,
    Addresses,
    AddressCountry,
    Airports,
    Activity,
    Board,
    ImportId,
    BuspaketRating,
    CabinType,
    Category,
    CheckedCatalogText: Check,
    CheckStatus,
    Client,
    Complexity,
    Contingent,
    Country,
    CurrentStatus: Status,
    DataCheckedAt,
    Difficulty,
    DueDate: Deadline,
    Deadline,
    Days,
    Email,
    EndAt: DateCell,
    Favicon,
    OrganisationFilter,
    HotelType,
    FerryContingent: Contingent,
    HotelContingent: Contingent,
    HomepageStatus: Boolean,
    IsDefaultFor,
    Importance,
    LastEmailContact,
    LastLogin: DateCell,
    Date: DateCell,
    Location,
    LocationCharges,
    Locale,
    MainLanguage,
    MainUser: User,
    MainUsers: Users,
    Order,
    Ports,
    Price,
    PricePerPerson: Price,
    SellPrice: Price,
    Provider,
    Providers,
    ReceivesCatalog: Check,
    Rentability,
    RequestSourceArray: RequestSource,
    RoomType,
    ServiceProvider,
    Stars,
    Status,
    StartAt: DateCell,
    Standard: Check,
    Tags,
    TrainStations,
    TraitTags,
    Translations,
    Type,
    Url,
    User,
    ClientInvoice,
    WhiteTags,
    Years,
    Zip
};

export {
    Type,
    Ports,
    Airports,
    Category,
    CheckStatus,
    Contingent,
    Status,
    Deadline,
    User,
    ClientInvoice,
    HotelType,
    Board,
    LocationCharges,
    Client,
    TrainStations,
};
