<template>
    <div class="is-light">
        <row  v-if="![0, '0.00'].includes(busBuyPrice)">
            <template slot="type">
                <i class="material-icons">subdirectory_arrow_right</i>
            </template>
            <template slot="date">-</template>
            <template slot="nights">-</template>
            <template slot="name">Buspreis für die Fähre</template>
            <template slot="provider">-</template>
            <template slot="currency"><span class="is-currency">{{placeholder.placeholderCurrency}}</span></template>

            <template slot="sellPrice">
                <input-price
                    v-model="busBuyPrice"
                    :linked="hasCalculationRequest"
                    :value-in-order-currency="busBuyPriceInOrderCurrency"
                    :provider-prices="getProviderPrices(this.groupPriceType)"
                    :price-currency="currency"
                />
            </template>
            <template slot="profit">
                <input-price
                    :value="busPrice"
                    readOnly
                    :linked="hasCalculationRequest"
                    :highlight="!!highlight.person"
                    :value-in-order-currency="busPriceInOrderCurrency"
                    :price-currency="currency"
                />
            </template>
            <template slot="margin">
                <span class="empty" v-if="supplementPrices">-</span>
                <input-price
                    v-else
                    :value="busPrice"
                    :readOnly="true"
                    :linked="hasCalculationRequest"
                    :highlight="!!highlight.single"
                    :value-in-order-currency="busPriceInOrderCurrency"
                    :price-currency="currency"
                />
            </template>
        </row>
        <row v-if="![0, '0.00'].includes(busTaxBuyPrice)">
            <template slot="type">
                <i class="material-icons">subdirectory_arrow_right</i>
            </template>
            <template slot="date">-</template>
            <template slot="nights">-</template>
            <template slot="name">Steuern - Bus</template>
            <template slot="provider">-</template>
            <template slot="currency"><span class="is-currency">{{placeholder.placeholderCurrency}}</span></template>

            <template slot="sellPrice">
                <input-price
                    v-model="busTaxBuyPrice"
                    :linked="hasCalculationRequest"
                    :value-in-order-currency="busTaxBuyPriceInOrderCurrency"
                    :provider-prices="getProviderPrices('busTaxBuyPrice')"
                    :price-currency="currency"
                />
            </template>
            <template slot="profit">
                <input-price
                    :value="busTaxPrice"
                    readOnly
                    :linked="hasCalculationRequest"
                    :highlight="!!highlight.person"
                    :value-in-order-currency="busTaxPriceInOrderCurrency"
                    :price-currency="currency"
                />
            </template>
            <template slot="margin">
                <span class="empty" v-if="supplementPrices">-</span>
                <input-price
                    v-else
                    :value="busTaxPrice"
                    :readOnly="true"
                    :linked="hasCalculationRequest"
                    :highlight="!!highlight.single"
                    :value-in-order-currency="busTaxPriceInOrderCurrency"
                    :price-currency="currency"
                />
            </template>
        </row>
       <row v-if="price.placeholder.type === 'night' && ![0, '0.00'].includes(dayTicketBuyPrice)">
            <template slot="type">
                <i class="material-icons">subdirectory_arrow_right</i>
            </template>
            <template slot="date">-</template>
            <template slot="nights">-</template>
            <template slot="name">Überfahrt</template>
            <template slot="provider">-</template>
            <template slot="currency"><span class="is-currency">{{placeholder.placeholderCurrency}}</span></template>

            <template slot="sellPrice">
                <span class="empty">-</span>
            </template>
            <template slot="profit">
                <input-price
                    v-model="dayTicketBuyPrice"
                    :provider-prices="getProviderPrices('dayTicketBuyPrice')"
                    readOnly
                    :linked="hasCalculationRequest"
                    :highlight="!!highlight.person"
                    :value-in-order-currency="dayTicketBuyPriceInOrderCurrency"
                    :price-currency="currency"
                />
            </template>
            <template slot="margin">
                <span class="empty" v-if="supplementPrices">-</span>
                <input-price
                    v-else
                    :value="dayTicketBuyPrice"
                    :readOnly="true"
                    :linked="hasCalculationRequest"
                    :highlight="!!highlight.single"
                    :provider-prices="getProviderPrices('dayTicketBuyPrice')"
                    :value-in-order-currency="dayTicketBuyPriceInOrderCurrency"
                    :price-currency="currency"
                />
            </template>
        </row>
        <row v-if="![0, '0.00'].includes(dayTicketTaxBuyPrice)">
            <template slot="type">
                <i class="material-icons">subdirectory_arrow_right</i>
            </template>
            <template slot="date">-</template>
            <template slot="nights">-</template>
            <template slot="name">Steuern - Überfahrt</template>
            <template slot="provider">-</template>
            <template slot="currency"><span class="is-currency">{{placeholder.placeholderCurrency}}</span></template>

            <template slot="sellPrice">
                <span class="empty">-</span>
            </template>
            <template slot="profit">
                <input-price
                    :value="dayTicketTaxBuyPrice"
                    :linked="hasCalculationRequest"
                    :highlight="!!highlight.person"
                    :provider-prices="getProviderPrices('dayTicketTaxBuyPrice')"
                    :value-in-order-currency="dayTicketTaxBuyPriceInOrderCurrency"
                    :price-currency="currency"
                />
            </template>
            <template slot="margin">
                <span class="empty" v-if="supplementPrices">-</span>
                <input-price
                    v-else
                    :value="dayTicketTaxBuyPrice"
                    :readOnly="true"
                    :linked="hasCalculationRequest"
                    :highlight="!!highlight.single"
                    :provider-prices="getProviderPrices('dayTicketTaxBuyPrice')"
                    :value-in-order-currency="dayTicketTaxBuyPriceInOrderCurrency"
                    :price-currency="currency"
                />
            </template>
        </row>
    </div>
</template>

<script>
import mixin from './mixin';
export default {
    mixins: [mixin],

    computed: {
        isNight: function () {
            return this.placeholder.type === 'night'
        },
        persons: function () {
            return this.$store.getters.persons
        },
        doublePersons: function () { return this.$store.getters.rooms.double },

        highlight: function () { return this.$store.state.highlight },


        busPrice: function () {
            return this.doublePersons ? this.busBuyPrice / this.doublePersons : 0;
        },

        busTaxPrice: function () {
            return this.doublePersons ? this.busTaxBuyPrice / this.doublePersons : 0;
        },

        currency: function() {
            return this.placeholder && this.placeholder.placeholderCurrency? this.placeholder.placeholderCurrency : null;
        },

        exchangeRate: function () {
            return this.$store.state.exchangeRates && this.$store.state.exchangeRates[this.currency] ? this.$store.state.exchangeRates[this.currency] : 0;
        },

        busPriceInOrderCurrency: function () { return this.busPrice * this.exchangeRate },
        busBuyPriceInOrderCurrency: function () { return this.busBuyPrice * this.exchangeRate },
        busTaxPriceInOrderCurrency () { return this.busTaxPrice * this.exchangeRate},
        busTaxBuyPriceInOrderCurrency () { return this.busTaxBuyPrice * this.exchangeRate },
        dayTicketBuyPriceInOrderCurrency: function () { return this.dayTicketBuyPrice * this.exchangeRate },
        dayTicketTaxBuyPriceInOrderCurrency () { return this.dayTicketTaxBuyPrice * this.exchangeRate},


        // personPriceInOrderCurrency: function () { return this.price._summary.person },
        // groupPriceInOrderCurrency: function () { return this.price._summary.group },
        // singlePriceInOrderCurrency: function () { return this.price._summary.single },

        busBuyPrice: {
            get: function () { return this.price.busBuyPrice.amount },
            set: function (value) { this.$store.commit('updatePrice', {
                ferryPrices: {
                    id: this.price.id,
                    busBuyPrice: {
                        amount: value,
                        currency: this.placeholder.placeholderCurrency
                    }
                }
            })}
        },
        busTaxBuyPrice: {
            get: function() {
                return this.price.busTaxPrice ? this.price.busTaxPrice.amount : 0;
            },
        },

        dayTicketBuyPrice: {
            get: function() {
                return this.price.dayTicketBuyPrice ? this.price.dayTicketBuyPrice.amount : 0;
            },
        },
        dayTicketTaxBuyPrice: {
            get: function () {
                return this.price.dayTicketTaxBuyPrice ? this.price.dayTicketTaxBuyPrice.amount : 0;
            },
        },

        groupPriceType: function () {
            return 'busBuyPrice';
        },

    }
}
</script>

