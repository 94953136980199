<template>
    <iframe
        width="100%"
        v-bind:height="email.attachments && nrOfAttachments > 0 ? '720' : customHeight"
        src="about:blank"
        ref="iframe"
    ></iframe>
</template>



<script>
import store from '../store';


const baseURL = window.location.origin;


export default {
    props: {
        email:        { type: Object, required: true },
        customHeight: { type: String, default: '950' }
    },


    computed: {
        nrOfAttachments: function () {
            if (this.email.attachments) {
                return this.email.attachments.filter(attachment => attachment.disposition !== 'inline' || attachment.type !== 'image').length;

            } else {
                return 0;
            }
        },


        textHtml: function () {
            let html = this.email.textHtml;

            if (this.email.attachments) {
                if (this.$store.getters.isLive(this.email)) {

                    const user = localStorage.getItem('user');
                    const token = user ? JSON.parse(user).token : '';

                    for (const attachment of this.email.attachments) {
                        if ((attachment.disposition === 'inline' || !attachment.disposition) && attachment.type === 'image') {
                            html = html.replace(
                                    new RegExp('cid:' + attachment.id, 'g'),
                                    baseURL + '/api/emails/live/download_attachment/' + this.email.uid + '/' + attachment.id + '?folder=' + this.email.folder + '&bearer=' + token
                                );
                        }
                    }

                } else {
                    for (const attachment of this.email.attachments) {
                        if ((attachment.disposition === 'inline' || !attachment.disposition) && attachment.type === 'image') {
                            html = html.replace(
                                    new RegExp('cid:' + attachment.id, 'g'),
                                    '/files/' + attachment.filePath
                                );
                        }
                    }
                }
            }

            return html;
        }
    },


    methods: {
        updateHtml: function () {
            this.$nextTick(() => {
                this.$refs.iframe.contentWindow.document.open('text/html', 'replace');
                this.$refs.iframe.contentWindow.document.write(this.textHtml ? this.textHtml : (this.email.textPlain ? this.email.textPlain.replace(/\n/g, "<br />") : 'Die Email enthält keinen Text'));
                this.$refs.iframe.contentWindow.document.close();
            });
        }
    },


    watch: {
        email: {
            handler: function () {
                this.updateHtml();
            },
            immediate: true
        }
    }
}
</script>
