import { profit, margin, applyProfit, applyMargin, priceView } from '@utilities/functions';
import priceMixin from '@calculations/priceMixin';
import InputPrice from '@calculations/components/InputPrice';
import { MarginTax } from '@calculations/components';

export default {
    mixins: [ priceMixin ],

    components: {
        InputPrice,
        MarginTax
    },

    data: function () {
        return {
            hide: false
        }
    },

    computed: {
        linked: function () { return !!this.price.placeholder && !!this.price.placeholder.calculationRequest },

        requestCurrencyIsDifferent: function () {
            return this.price.placeholder && this.placeholder.calculationRequest && this.placeholder.calculationRequest.requestCurrency !== this.orderCurrency;
        },

        showExchangeRates: function () {
            this.hide = true;
            this.$nextTick(() => {
                this.hide = false;
            });
            return (this.$store.state.showExchangeRates && this.orderCurrency !== this.calculationCurrency);
        },

        providerPrices: function () { return !!this.price.providerPrices ? this.price.providerPrices : []},

        hasTwoCurrencies: function () {
            this.hide = true;
            this.$nextTick(() => {
                this.hide = false;
            });
            return this.orderCurrency !== this.calculationCurrency;
        },

        orderCurrency: function () {
            return this.$store.state.order.orderCurrency;
        },

        calculationCurrency: function () {
            return this.$store.state.order.calculationCurrency;
        },

        requestsCurrencyExchange: function () {
            if(!this.requestCurrencyIsDifferent){
                return 1;
            }
            return this.$store.state.exchangeRates && this.$store.state.exchangeRates[this.placeholder.calculationRequest.requestCurrency] ? this.$store.state.exchangeRates[this.placeholder.calculationRequest.requestCurrency] * this.calculationToOrderExchangeRate : 0;
        },

        calculationToOrderExchangeRate: function () {
            return this.$store.state.calculationToOrderExchangeRate[this.calculationCurrency] ? this.$store.state.calculationToOrderExchangeRate[this.calculationCurrency] : 1;
        },

        multiplier() {
            return this.marginTaxEnabled ? 1.19 : 1;
        },

        buyPrice: {
            get: function () {
                return this.price.buyPrice ? this.price.buyPrice.amount * this.requestsCurrencyExchange : 0
            },
            set: function (buyPrice) { this.updatePrice(
                {
                    buyPrice: {
                        amount: buyPrice,
                        currency: this.orderCurrency
                    },
                }) }
        },

        buyPriceInCalculationCurrency: {
            get: function () {
                return this.calculationToOrderExchangeRate ? this.buyPrice / this.calculationToOrderExchangeRate : this.buyPrice;
            },
            set: function (buyPrice) {
                this.buyPrice = buyPrice * this.calculationToOrderExchangeRate;
            }
        },

        currency: function() {
            return this.placeholder && this.placeholder.placeholderCurrency ? this.placeholder.placeholderCurrency : null;
        },

        exchangeRate: function () {
            return this.$store.state.exchangeRates && this.$store.state.exchangeRates[this.currency] ? this.$store.state.exchangeRates[this.currency] : 0;
        },

        sellPriceInCalculationCurrency: {
            get: function () {
                return this.calculationToOrderExchangeRate ? this.sellPrice / this.calculationToOrderExchangeRate : this.sellPrice;
            },
            set: function (sellPrice) {
                this.sellPrice = sellPrice * this.calculationToOrderExchangeRate;
            }
        },

        sellPrice: {
            get: function () { return this.price.sellPrice ? this.price.sellPrice.amount : 0 },
            set: function (sellPrice) { this.updatePrice(
                {
                    sellPrice: {
                        amount: sellPrice,
                        currency: this.orderCurrency
                    },
                })}
        },

        profitInCalculationCurrency: {
            get: function () {
                return this.calculationToOrderExchangeRate ? this.profit / this.calculationToOrderExchangeRate : this.profit;
            },
            set: function (value) {
                this.profit = value * this.calculationToOrderExchangeRate;
            }
        },

        profit: {
            get: function () {
                return this.multiplier ? profit(this.sellPrice, this.buyPrice) / this.multiplier : 0;
            },
            set: function (value) {
                this.sellPrice = Number(this.buyPrice) + (Number(value) * this.multiplier);
                // this.sellPrice = applyProfit(this.buyPrice, value)
            }
        },

        margin: {
            get: function () {
                return this.buyPrice ? Number(this.profit) / Number(this.buyPrice) * 100 : 0;
                // return margin(this.sellPrice, this.buyPrice)
            },
            set: function (value) {
                this.sellPrice = Number(this.buyPrice) * (1 + Number(value) / 100 * this.multiplier);
                // this.sellPrice = applyMargin(this.buyPrice, value)
            }
        },

        marginTax: function () {
            return Number(this.profit) * 0.19;
        },

        marginTaxEnabled: {
            get: function () {
                return this.price.marginTax
            },
            set: function (value) {
                this.toggleMarginTax(value)
            }
        },

        grossMargin: function () {
            return this.buyPrice ? (this.sellPrice - this.buyPrice) / this.buyPrice : 0;
        }
    },

    methods: {
        getProviderPrices: function(type) {
            return this.providerPrices[type] ? this.providerPrices[type] : [];
        },



        updatePrice: function (obj = {}) {
            this.$store.commit('updatePrice', {
                [`${this.price._type}Prices`]: {
                    id: this.price.id,
                    ...obj
                }
            });
        },

        toggleMarginTax: function (newValue) {
            const sellPrice = newValue
                ? Number(this.sellPrice.amount) + this.marginTax
                : Number(this.sellPrice.amount) - this.marginTax;

            this.updatePrice({
                marginTax: newValue,
                sellPrice: {
                    amount: sellPrice,
                    currency: this.orderCurrency
                }
            });
            return !! newValue;
        }
    },
}
