var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { classes: "new-email", visible: _vm.isModalVisible },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("closePhoneModal")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        !_vm.action
          ? _c("div", { staticClass: "name" }, [
              _vm._v("NEUES TELEFONPROTOKOLL"),
            ])
          : _c("div", { staticClass: "name" }, [
              _vm._v("TELEFONPROTOKOLL BEARBEITEN"),
            ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "toggle-switch",
              {
                model: {
                  value: _vm.emailType,
                  callback: function ($$v) {
                    _vm.emailType = $$v
                  },
                  expression: "emailType",
                },
              },
              [
                _c("option", { attrs: { value: "client" } }, [
                  _vm._v("Telefonat mit Kunde"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "provider" } }, [
                  _vm._v("Telefonat mit Leistungsträger"),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content" },
        [
          _c("orders-select", {
            attrs: { params: { _groups: ["client_read"] } },
          }),
          _vm._v(" "),
          _c(
            "phone-form",
            _vm._g(
              {
                ref: "form",
                attrs: {
                  "preselected-order": _vm.preselectedOrder,
                  preselectedContact: _vm.preselectedContact,
                },
                on: {
                  closeModal: (refresh) =>
                    _vm.$emit("closePhoneModal", refresh),
                },
              },
              _vm.$listeners
            )
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }