<template>
    <directions
        @openForm="$emit('openForm')"
        v-bind:day="item"
        v-bind:options="options"
    />
</template>

<script>
import Mixin from './tab';
import directions from '@orders/concepts/days/Day';

export default {
    mixins: [ Mixin ],

    components: {
        directions
    },
}
</script>
