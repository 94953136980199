<template>
    <div>
        <div class="columns"><div class="column is-narrow" style="align-self: center; padding-left: 85px">Bildtitel: </div><div class="column" style="padding-right: 85px"><input type="text" id="title" class="input" v-model="title"></div></div>

        <cropper
            ref="cropper"
            style="height: 80vh"
            class="cropper"
            :src="image.src"
            :stencil-props="{
		        aspectRatio: component.props.cropRatio,
	}"

            @change="change"
        ></cropper>
    </div>

</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import jsonToFormData from "json-form-data";
import axios from "axios";

export default {
    data () {
        return {
            title: this.image.name,
        }
    },

    props: {
        image: {type: Object},
        component: {type: Object},
    },

    methods: {
        change({coordinates, canvas}) {
        },

        upload (original = false)
        {
            const { canvas } = this.$refs.cropper.getResult();
            if(original) {
                let formDataO = {
                    title: this.title,
                    orderConcept: window.location.pathname.includes('concepts') ? '/api'+ window.location.pathname : null,
                    destination: window.location.pathname.includes('destinations') ? '/api' + window.location.pathname : null,
                    folder : 'Kundenbilder',
                    type: 'photo',
                };
                formDataO = jsonToFormData(formDataO);
                formDataO.append('user_file_upload', this.image.original)
                axios.post(
                    '/api/images/upload', formDataO);
            }
            if (canvas) {
                let formData = {
                    title: this.title,
                    orderConcept: window.location.pathname.includes('concepts') ? '/api'+ window.location.pathname : null,
                    destination: window.location.pathname.includes('destinations') ? '/api' + window.location.pathname : null,
                    folder : 'Homepage',
                    type: this.component.props.filterParams.type,
                    ...this.component.props.maxFileSize && { maxFileSize: this.component.props.maxFileSize }
                };
                formData = jsonToFormData(formData);

                canvas.toBlob(blob => {

                    /*if(blob.size > 4000000) {
                        notifyError('Datei > 4 MB. Laurenz Bescheid geben ;)')
                    } else {*/
                        formData.append('user_file_upload', blob);
                        // You can use axios, superagent and other libraries instead here
                        this.$emit('startUpload', axios.post(
                            '/api/images/upload', formData))
                    //}
                    // Perhaps you should add the setting appropriate file format here
                }, 'image/jpeg')
            }
        },
    },
    components: {
        Cropper
    }
}
</script>

