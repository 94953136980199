<template>
    <button
        class="button is-large"
        v-on:click="deleteEmails"
    ><i class="material-icons">delete</i> Löschen</button>
</template>



<script>
import axios from 'axios';
import { notifySuccess, notifyError } from '@components/Notification';
import store from '../store';


export default {
    store: store,


    computed: {
        emails: function () { return this.$store.state.emailsSelected[this.$store.state.emailsSelected.direction]; }
    },


    methods: {
        deleteEmails: function () {
            if (confirm('Bist du sicher, dass du dies löschen möchtest?')) {
                let calls = this.emails.map(email => {
                    if (email.id) {
                        return axios.delete('/api/emails/' + email.id);

                    } else {
                        return axios.delete('/api/emails/live/' + email.uid + '?folder=' + email.folder);
                    }
                });

                this.$store.commit('updateLoading', true);

                Promise.all(calls)
                    .then(responses => {
                        this.$store.dispatch('deleteEmailsSelected');
                        notifySuccess('Emails wurden gelöscht!');

                    }, error => { notifyError('Bitte aktualisieren Sie die Seite! Konnte die E-Mail nicht löschen! Server Error!'); })
                    .then(() => {
                        this.$store.commit('updateLoading', false);
                        this.$store.commit('updateFolder', true);
                    });
            }
        }
    }
}
</script>
