var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "columns",
        {
          attrs: { property: "menus", "load-options": false },
          on: { add: _vm.openForm },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ item }) {
                return _c(
                  "column-item",
                  {
                    attrs: {
                      update: {
                        updatedBy: item.updatedBy,
                        updatedAt: item.updatedAt,
                      },
                      api: "/api/menus/" + item.id,
                    },
                    on: {
                      edit: function ($event) {
                        return _vm.openForm(item)
                      },
                      delete: function ($event) {
                        return _vm.deleteMenu("menus", item)
                      },
                    },
                  },
                  [
                    _c("div", [
                      _c("div", { staticClass: "content mb-0" }, [
                        _c("div", { staticClass: "level" }, [
                          _c("strong", { staticClass: "level-left" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "level-right" }, [
                            _c("span", {
                              staticClass: "has-text-right",
                              domProps: { innerHTML: _vm._s(_vm.price(item)) },
                            }),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content mb-0" }, [
                        _c("div", { staticClass: "level" }, [
                          _c(
                            "div",
                            { staticClass: "level-left is-small-text" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.dateLabel(item)) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "nl2br content mt-2",
                        domProps: { innerHTML: _vm._s(item.content) },
                      }),
                    ]),
                  ]
                )
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("menu-form", {
            ref: "form",
            attrs: {
              slot: "form",
              provider: _vm.item,
              "options-array": _vm.options,
            },
            on: {
              success: _vm.handleTranslations,
              updateMenus: _vm.updateMenus,
            },
            slot: "form",
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.deleteOtherService
        ? _c("delete-other-service", {
            attrs: {
              item: _vm.deleteOtherService,
              "filter-params": _vm.item
                ? {
                    "provider.id": _vm.item.id,
                    _groups: ["provider_read", "agency_read"],
                  }
                : {},
            },
            on: {
              close: function ($event) {
                _vm.deleteOtherService = null
              },
              deleted: _vm.updateMenus,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }