<template>
    <vue2-dropzone
        id="dropzone"
        ref="dropzone"
        :options="dropzoneOptions"
        useCustomSlot
        v-on:vdropzone-sending="sendingEvent"
        v-on:vdropzone-success="fileSuccess"
        v-on:vdropzone-complete="fileCompleted"
        v-on:vdropzone-files-added="filesAdded"
    >
        <div id="documentDropZone" class="dz-dropzone">Dokument zum hochladen hierhin ziehen</div>
    </vue2-dropzone>
</template>



<script>

import vue2Dropzone  from 'vue2-dropzone'
import {authHeader} from "@utilities/functions";


export default {

    props: {
        documentData: { type: Object,  default: () => {} },
        options: { type: Object}
    },

    computed: {
        template: function () {
            return `<div class="dz-preview dz-file-preview">
                        <div class="dz-details">
                            <span data-dz-name></span>
                            <div class="dz-error-message">
                                <span data-dz-errormessage></span>
                            </div>
                        </div>
                        <div class="dz-progress">
                            <span class="dz-upload" data-dz-uploadprogress></span>
                        </div>

                        <div class="dz-remove">
                            <i class="material-icons" data-dz-remove>delete</i>
                        </div>
                    </div>`;
        },
        dropzoneOptions: function () {
            let defaultOptions = {
                url: '/api/documents/upload',
                maxFilesize: 20,
                headers: authHeader(),
                previewTemplate: this.template,
                createImageThumbnails: false,
                clickable: '#documentDropZone',
                paramName: "user_file_upload"
            }
            return {...defaultOptions, ...this.options};
        }
    },

    data: function () {
        return {
            documents: [],
        }
    },

    methods: {
        sendingEvent: function (file, xhr, formData) {
            formData.append('name', file.name.split('.').slice(0, -1).join('.'));
            if (!!this.documentData && typeof this.documentData === 'object') {
                Object.keys(this.documentData).forEach(key => {
                    if (!!this.documentData[key]) {
                        if (key === 'links') {
                            this.documentData.links.forEach(link => {
                                formData.append('links[]', JSON.stringify(link))
                            })
                        } else {
                            formData.append(key, this.documentData[key])
                        }
                    }
                })
            }
        },

        fileSuccess: function (file, response) {
            this.documents = [...this.documents, response];
            this.$refs.dropzone.removeFile(file);
            this.$emit('documentSaved', response);
        },

        fileCompleted: function (file) {
            this.queueSize--;

            if (this.queueSize === 0) {
            }
        },

        filesAdded: function (files) {
            this.queueSize = files.length;
        },

    },

    components: {
        vue2Dropzone
    }
}
</script>
