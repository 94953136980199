var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.provider.id,
      staticClass: "placeholder-view has-requests is-request",
    },
    [
      _c("div", { staticStyle: { width: "auto" } }, [
        _vm.provider.agency
          ? _c(
              "a",
              {
                attrs: {
                  href: "/agencies/" + _vm.provider.agency.id,
                  target: "_blank",
                },
              },
              [_vm._v(_vm._s(_vm.provider.agency.name))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.provider.agency ? _c("span", [_vm._v(" - ")]) : _vm._e(),
        _vm._v(" "),
        _vm.provider.provider
          ? _c(
              "a",
              {
                attrs: {
                  href: _vm.providerUrl(_vm.provider.provider),
                  target: "_blank",
                },
              },
              [_vm._v(_vm._s(_vm.provider.provider.name))]
            )
          : _c("span", [_vm._v("ohne Anbieter")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "is-flex is-align-items-center",
          staticStyle: { width: "150px" },
        },
        [
          _c(
            "div",
            { staticClass: "is-flex is-align-items-center is-fullwidth" },
            [
              _vm.info && _vm.info.priceRequest
                ? [
                    _c("user-image", {
                      staticClass: "mr-2",
                      attrs: { user: _vm.info.priceRequest.user, small: "" },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm
                            .moment(
                              _vm.info.priceRequest.updatedAt,
                              "DD.MM.YYYY"
                            )
                            .format("DD.MM.YYYY")
                        )
                      ),
                    ]),
                  ]
                : [
                    _c(
                      "button",
                      {
                        staticClass: "button is-smaller is-gray is-fullwidth",
                        on: { click: _vm.openEmail },
                      },
                      [_vm._v("jetzt anfragen")]
                    ),
                  ],
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "is-flex is-align-items-center",
          staticStyle: { width: "150px" },
        },
        [
          _c(
            "div",
            { staticClass: "is-flex is-align-items-center is-fullwidth" },
            [
              _vm.info && _vm.info.price
                ? [
                    _c("user-image", {
                      staticClass: "mr-2",
                      attrs: { user: _vm.info.price.user, small: "" },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm
                            .moment(_vm.info.price.updatedAt, "DD.MM.YYYY")
                            .format("DD.MM.YYYY")
                        )
                      ),
                    ]),
                  ]
                : [
                    _c(
                      "button",
                      {
                        staticClass: "button is-smaller is-gray is-fullwidth",
                        on: { click: _vm.addPrice },
                      },
                      [_vm._v("jetzt erfassen")]
                    ),
                  ],
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "is-flex is-align-items-center",
          staticStyle: { width: "150px", padding: "0px" },
        },
        [
          _c("drop-zone-in-row", {
            staticClass: "is-flex is-align-items-center",
            staticStyle: { width: "100%" },
            attrs: {
              "doc-id": _vm.provider.id,
              documents: _vm.info ? _vm.info.documents : [],
              "upload-data": _vm.uploadData,
            },
            on: {
              documentSaved: function ($event) {
                return _vm.$emit("documentSaved")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "action-buttons", staticStyle: { width: "50px" } },
        [
          _c(
            "simple-menu",
            { attrs: { material: "", icon: "more_vert", title: "Menu" } },
            [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.openEmail.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons" }, [_vm._v("mail")]),
                  _vm._v(" Emailanfrage"),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.markAsRequested.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons" }, [_vm._v("check")]),
                  _vm._v(" als angefragt markieren"),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.addPrice.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                  _vm._v(" Preis hinzufügen"),
                ]
              ),
              _vm._v(" "),
              _vm.info && _vm.info.priceRequest
                ? _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.deletePriceRequest.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("delete"),
                      ]),
                      _vm._v(" Anfragedatum löschen"),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "nr-of-requests",
          class: _vm.info && _vm.info.price ? "is-ok" : "is-high",
          attrs: { title: "Anfragen" },
        },
        [_vm._v(_vm._s(_vm.provider.classification))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }