<template>

    <div class="has-margin-top">
        <div class="mb-2" v-if="component.props.headline"><b>{{component.props.headline}}</b></div>
        <tip-tap
            v-model="element[component.props.property]"
            @input="saveForm(component.props)"
            @reset="buttonAction"
            :default-size="component.props.defaultSize"
            :menu-items="component.props.menuItems"
            :warning="compareWarning"
        />
    </div>


</template>

<script>

import Mixin from '../../tab';
import { TipTap } from '@components/form';



export default {
    mixins: [ Mixin ],

    props: {
        component: {
            type: Object,
            required: true
        },
        newItem: {
            type: Object,
            required: false
        },
    },

    methods: {
        saveForm: function(field) {
            this.$emit('saveItemDebounce', field.property, this.element[field.property])
        },
        buttonAction: function() {
            this.$emit('buttonAction', {action: this.component.props.resetAction, element: this.element})
        }
    },

    computed: {
        element: function() {
            return this.newItem ? this.newItem : this.item;
        },
        compareWarning: function () {
            if(!this.component.props.compareWarning || !this.element[this.component.props.compareWarning.compareProperty] ) {
                return '';
            }
            return this.component.props.compareWarning.warning
        }
    },

    components: {
        TipTap,
    },
}
</script>
