var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    { staticClass: "button is-large", on: { click: _vm.deleteEmails } },
    [
      _c("i", { staticClass: "material-icons" }, [_vm._v("delete")]),
      _vm._v(" Löschen"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }