<template>
    <div class="emails-page">
        <!-- Used in orders/1/emails and /concepts/orders/1 tab Email-->
        <list-simple
            v-if            = "isSimpleList"
            ref="emailList"
            :is-concept="!!preselectedOrderConcept"
            v-bind:apiQuery = "filterQuery"
            v-bind:order    = "preselectedOrder ? preselectedOrder : preselectedOrderConcept"
            @addPhoneCall="addPhoneCall"
        ></list-simple>

        <!-- Used in emails -->
        <list
            v-else
            v-bind:preselectedOrder = "preselectedOrder"
            v-bind:apiQuery         = "preselectedOrder ? 'links.order.id=' + preselectedOrder.id : '' + '&' + apiQuery"
            v-bind:useLiveEmails    = "useLiveEmails"
        ></list>


        <main>
            <div class="action-buttons">
                <btn-assign v-if="!preselectedOrderConcept"></btn-assign>

                <button
                    v-if="email && email.type === 'phone'"
                    class="button is-large"
                    v-on:click="fromEmailAction('edit')"
                ><i class="material-icons">edit</i> Bearbeiten</button>

                <template v-else>
                    <button
                        class="button is-large"
                        v-on:click="fromEmailAction('reply')"
                    ><i class="material-icons">reply</i> Antworten</button>

                    <button
                        class="button is-large"
                        v-on:click="fromEmailAction('replyAll')"
                    ><i class="material-icons">reply_all</i> Allen Antworten</button>

                    <button
                        class="button is-large"
                        v-on:click="fromEmailAction('forward')"
                    ><i class="material-icons">arrow_forward</i> Weiterleiten</button>
                </template>

                <btn-delete></btn-delete>
            </div>


            <email-view></email-view>
        </main>


        <loading classNames="is-global" v-if="isLoading" />


        <new-email
            v-if = "isModalVisible && !preselectedOrderConcept"
            v-bind:isModalVisible   = "isModalVisible"
            v-bind:preselected-order = "preselectedOrder"
            v-on:closeModal = "closeModal"
        ></new-email>

        <concept-email
            v-if = "isModalVisible && preselectedOrderConcept"
            :is-modal-visible="isModalVisible"
            :preselected-order-concept="preselectedOrderConcept"
            v-bind:isModalVisible="isModalVisible"
            v-on:closeModal="closeModal"
        />

        <new-phone
            v-if = "isPhoneModalVisible"
            v-bind:isModalVisible   = "isPhoneModalVisible"
            v-bind:preselected-order = "preselectedOrder"
            :preselected-contact="preselectedContact"
            v-on:closePhoneModal = "closePhoneModal"
        ></new-phone>
    </div>
</template>



<script>
import Loading   from '@components/Loading';
import { notifyError } from '@components/Notification';
import store      from './store';
import NewEmail   from './modals/newEmail/index';
import NewPhone   from './modals/newEmail/newPhone';
import List       from './list/index';
import ListSimple from './list/Simple';
import EmailView  from './view/index';
import BtnAssign  from './actions/Assign';
import BtnDelete  from './actions/Delete';
import ConceptEmail from "@components/emails/modals/newEmail/ConceptEmail.vue";


export default {
    store: store,


    props: {
        titleText:        { type: String,  default: 'MAILBOX' },

        // List without categories and drag and drop
        isSimpleList:     { type: Boolean, default: false },

        preselectedOrder: { type: Object,  default: null },

        preselectedOrderConcept: { type: Object, default: null},

        apiQuery:         { type: String },

        // For /emails/archive we don't need live emails
        useLiveEmails: { type: Boolean, default: true }
    },


    computed: {
        isLoading: function () { return this.$store.state.isLoading; },
        email: function () { return this.$store.state.emailsSelected[this.$store.state.emailsSelected.direction][0]; },
        filterQuery () {
            if(this.preselectedOrder) {
                return 'links.order.id=' + this.preselectedOrder.id
            }
            if(this.preselectedOrderConcept) {
                return 'links.orderConcept.id=' + this.preselectedOrderConcept.id
            }
            return '';
        }
    },


    data: function () {
        return {
            isModalVisible: false,
            isPhoneModalVisible: false,
            preselectedContact: null
        };
    },


    methods: {
        addPhoneCall: function({preselectedContact}) {
            this.preselectedContact = preselectedContact;
            this.isPhoneModalVisible = true;
        },

        fromEmailAction: function (actionType) {
            if(actionType === 'edit') {
                if (this.$store.state.emailsSelected[this.$store.state.emailsSelected.direction].length > 0) {
                    this.$store.commit('updateAction', actionType);
                    this.isPhoneModalVisible = true;

                } else {
                    notifyError('Bitte wählen Sie eine Email!');
                }
            }
            else {
                if (this.$store.state.emailsSelected[this.$store.state.emailsSelected.direction].length > 0) {
                    this.$store.commit('updateAction', actionType);
                    this.isModalVisible = true;

                } else {
                    notifyError('Bitte wählen Sie eine Email!');
                }
            }
        },


        closeModal: function () {
            this.$store.commit('updateAction', null);
            this.isModalVisible = false;
        },
        closePhoneModal: function () {
            this.$refs.emailList.getEmails();
            this.$store.commit('updateAction', null);
            this.isPhoneModalVisible = false;
        }
    },


    components: {
        ConceptEmail,
        BtnAssign,
        BtnDelete,
        List,
        ListSimple,
        Loading,
        EmailView,
        NewEmail,
        NewPhone,
    }
}
</script>
