<template>
    <columns property="contacts" v-on:add="openForm" >
        <column-item
            v-bind:update="{updatedBy: item.updatedBy, updatedAt: item.updatedAt}"
            :api="'/api/contacts/' + item.id"
            slot-scope="{item}"
            v-bind:label="label(item)"
            v-on:edit="openForm(item)"
            v-on:delete="deleteItem('contacts', item)"
        >
            <ul>
                <template v-if="isGeneral(item)">
                    <li><strong>Allgemein</strong></li>
                    <li v-if="item.address"><em>{{ addressLabel(item.address) }}</em></li>
                    <li v-if="item.address && item.address.port">
                        <i class="material-icons">directions_boat</i>
                        {{ item.address.port.place.name }}
                    </li>
                    <li v-if="item.address && item.address.airport">
                        <i class="material-icons">airplanemode_active</i>
                        {{ item.address.airport.name }}
                    </li>
                </template>
                <template v-else>
                    <li>
                        <strong>{{ titleName(item.title) }} {{ item.firstName }} {{ item.lastName }}</strong>
                    </li>
                    <li><em>{{ roleName(item.role) }}</em></li>
                </template>

                <li
                    class="is-email-link"
                    v-if="!!item.email"
                    v-on:click="openNewEmail(item)"
                ><i class="material-icons">email</i>{{ item.email }}</li>

                <li v-if="!!item.phoneMobile">
                    <i class="material-icons">smartphone</i>
                    <a class="is-link" :href="telURL(item.phoneMobile)">{{ item.phoneMobile }}</a>
                </li>

                <li v-if="!!item.phoneFixed">
                    <i class="material-icons">phone</i>
                    <a class="is-link" :href="telURL(item.phoneFixed)">{{ item.phoneFixed }}</a>
                </li>

                <li v-if="!!item.fax">
                    <i class="material-icons">print</i>
                    <a class="is-link" :href="faxURL(item.fax)">{{ item.fax }}</a>
                </li>

                <li v-if="item.languages && item.languages.length > 0"><i class="material-icons">textsms</i>{{ item.languages.map(language => languageName(language)).join(', ') }}</li>

                <li v-if="item.place">
                    <i class="material-icons">location_on</i>{{ item.place.name }}
                </li>
            </ul>
        </column-item>

        <template slot="form">
            <contact-form ref="form" />

            <new-email
                v-if="isNewEmailVisible"
                v-bind:preselected-contact="newEmailContact"
                v-bind:isModalVisible="isNewEmailVisible"
                v-on:closeModal="isNewEmailVisible = false"
            ></new-email>
        </template>
    </columns>
</template>

<script>
import Mixin             from '../tab';
import ContactForm       from './Form';
import NewEmail          from '@components/emails/modals/newEmail/index';
import { languages }     from '@utilities/variables';
import { phone }         from '@utilities/functions';
import {getAxios} from "@api";

export default {
    mixins: [Mixin],
    options: false,
    data: function () {
        return  {
            newEmailContact: null,
            isNewEmailVisible: false,
        }
    },

    computed: {
        roles: function () { return this.options.contacts ? this.options.contacts.role : []},
        titles: function () { return this.options.contacts ? this.options.contacts.title : []}
    },


    methods: {
        languageName: function (label) {
            const language = languages.find(l => l.label === label);
            return language ? language.name : label;
        },

        roleName: function (key) {
            const role = this.roles.find(l => l.key === key);
            return role ? role.value : key;
        },

        isGeneral: function (item) {
            return item.role === 'general_contact';
        },

        titleName: function (key) {
            const title = this.titles.find(l => l.key === key);
            return title ? title.value : key;
        },

        addressLabel: function (address) {
            return `${address.streetAddress}, ${address.city}`;
        },

        label: function ({type}) {
            return type === 'primary' ? 'Primärkontakt' : ''
        },

        openNewEmail: function (contact) {
            this.newEmailContact = contact;
            this.isNewEmailVisible = true;
        },

        telURL: function(value) {
            return `tel:${phone(value)}`
        },

        faxURL: function(value) {
            return `fax:${phone(value)}`
        },
    },

    activated: function () {
        this.isLoading = true;
        let filter = null;
        if(this.api === 'clients') {
            filter = {'client.id': this.item.id};
        } else if(this.api === 'agencies') {
            filter = {'agency.id': this.item.id};
        } else {
            filter = {'provider.id': this.item.id};
        }
        getAxios('contacts', {
            _groups: ['address_read', 'contact_read_relations'],
            ...filter
        }).then(response => {
            this.$store.commit('updateData', {
                contacts: response.data
            })
        }).then(() => {
            this.isLoading = false;
        })
    },


    components: {
        ContactForm,
        NewEmail,
    }
}
</script>
