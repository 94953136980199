var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "column" },
    [
      _c(
        "draggable",
        {
          attrs: { options: { disabled: false } },
          model: {
            value: _vm.images,
            callback: function ($$v) {
              _vm.images = $$v
            },
            expression: "images",
          },
        },
        [
          _c(
            "transition-group",
            {
              staticClass: "columns is-multiline",
              attrs: { name: "column", tag: "div" },
            },
            [
              _vm._l(_vm.images, function (image, index) {
                return _c(
                  "div",
                  {
                    key: image.id,
                    staticClass: "column",
                    class: _vm.columnClasses,
                    style: _vm.columnStyle,
                  },
                  [
                    _c(
                      "column-item",
                      {
                        staticClass: "is-image",
                        style: {
                          "background-image": `url('${image.publicPath}')`,
                          "background-size": _vm.isCoverImage
                            ? "cover"
                            : "contain",
                          "background-position": "center",
                          padding: "0 0 0px",
                        },
                        attrs: {
                          update: {
                            updatedBy: image.updatedBy,
                            updatedAt: image.updatedAt,
                          },
                          api: "/api/images/" + image.id,
                          label: _vm.label(image),
                        },
                        on: {
                          edit: function ($event) {
                            return _vm.editImage(image)
                          },
                          delete: function ($event) {
                            return _vm.deleteImage(image)
                          },
                        },
                      },
                      [
                        image.title
                          ? _c(
                              "ul",
                              {
                                staticStyle: {
                                  "padding-top": "5px",
                                  "background-color": "rgb(247 249 250 / 75%)",
                                },
                              },
                              [
                                !_vm.warning(image)
                                  ? _c("li", { staticClass: "is-flex" }, [
                                      _c(
                                        "strong",
                                        {
                                          staticClass:
                                            "has-text-centered is-full-width",
                                        },
                                        [_vm._v(_vm._s(image.title))]
                                      ),
                                    ])
                                  : _c("li", { staticClass: "is-flex" }, [
                                      _c(
                                        "strong",
                                        {
                                          staticClass:
                                            "has-text-danger has-text-centered is-full-width",
                                        },
                                        [_vm._v(_vm._s(_vm.warning(image)))]
                                      ),
                                    ]),
                                _vm._v(" "),
                                _c("hr", { staticClass: "my-sm mb-sm" }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.enableZoom
                          ? _c(
                              "button",
                              {
                                attrs: { slot: "actions" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.$emit("zoom", index)
                                  },
                                },
                                slot: "actions",
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("zoom_in"),
                                ]),
                                _vm._v("Zoom\n                "),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm.multiImages || _vm.folderImages.length === 0
                ? _c(
                    "div",
                    {
                      key: "add",
                      staticClass: "column",
                      class: _vm.columnClasses,
                      style: _vm.columnStyle,
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "bp-panel is-add",
                          class: _vm.dropzoneId,
                        },
                        [
                          _c("div", [
                            !_vm.addTitle
                              ? _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("add"),
                                ])
                              : _c("span", [_vm._v(_vm._s(_vm.addTitle))]),
                          ]),
                          _vm._v(" "),
                          _vm.enableGoogleMaps
                            ? _c("div", { staticClass: "panel-actions" }, [
                                _c(
                                  "button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.addGoogleMapImage.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("map"),
                                    ]),
                                    _vm._v(
                                      "\n                            Google Maps (540 x 250)\n                        "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }