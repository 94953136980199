var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    ref: "iframe",
    attrs: {
      width: "100%",
      height:
        _vm.email.attachments && _vm.nrOfAttachments > 0
          ? "720"
          : _vm.customHeight,
      src: "about:blank",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }