<template>
    <section class="section">
        <div class="section-title">
            <span class="h2">
                <span>{{title}}</span>
                <i class="material-icons" v-on:click="showForm = true">add_circle</i>
            </span>
        </div>

        <div class="redesign-table">
            <div class="row is-head">
                <div class="cell">Überschrift</div>
                <div class="cell">Beschreibung</div>
                <div class="cell is-70px-cell"></div>
            </div>

            <draggable element="div" :value="typeProviderTripDetails" v-on:input="updateSequence">
                <item
                    v-for="(providerTripDetail, index) in typeProviderTripDetails"
                    v-bind:key="providerTripDetail.id"
                    v-bind:value="providerTripDetail"
                    v-on:submit="handleSubmit"
                    v-on:delete="handleDelete(providerTripDetail)"
                    :isLastIndex="index + 1 === typeProviderTripDetails.length"
                >{{index + 1}}</item>
            </draggable>

            <loading v-if="isLoading"></loading>

            <item
                v-if="showForm"
                v-on:submit="handleSubmit"
                v-on:delete="showForm = false"
                :value="{type}"
            >{{typeProviderTripDetails.length + 1}}</item>

            <div class="row" v-if="typeProviderTripDetails.length === 0 && !showForm">
                <div class="cell has-text-centered">There are no provider_info $providerTripDetail</div>
            </div>
        </div>
    </section>
</template>

<script>
import tripDetails from '../tripDetails'
import Item from './Item';
import Loading from "@components/Loading.vue";

export default {
    mixins: [ tripDetails ],

    components: {Loading, Item }
}
</script>
