var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bp-row-form" }, [
    _c("div", [
      _c("div", { staticClass: "bp-row-form__title" }, [_vm._v("Flugstrecke")]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("i", { staticClass: "material-icons", attrs: { title: "Flug" } }, [
            _vm._v("airplanemode_active"),
          ]),
          _vm._v(" "),
          _c("async-select", {
            ref: "startAirport",
            staticClass: "has-margin-right",
            attrs: {
              api: "airports",
              "custom-label": (airport) =>
                airport.name + " - " + airport.place.name,
              crudLinkTitle: "Fügen Sie einen neuen Flughafen hinzu",
              clearable: false,
              "allow-empty": false,
              placeholder: "Flughafen finden",
            },
            model: {
              value: _vm.copyPlaceholder.startAirport,
              callback: function ($$v) {
                _vm.$set(_vm.copyPlaceholder, "startAirport", $$v)
              },
              expression: "copyPlaceholder.startAirport",
            },
          }),
          _vm._v(" "),
          _c("async-select", {
            staticClass: "has-margin-right",
            attrs: {
              api: "airports",
              "custom-label": (airport) =>
                airport.name + " - " + airport.place.name,
              crudLinkTitle: "Fügen Sie einen neuen Flughafen hinzu",
              clearable: false,
              "allow-empty": false,
              placeholder: "Flughafen finden",
            },
            model: {
              value: _vm.copyPlaceholder.endAirport,
              callback: function ($$v) {
                _vm.$set(_vm.copyPlaceholder, "endAirport", $$v)
              },
              expression: "copyPlaceholder.endAirport",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "is-160 has-time" }, [
      _c("div", { staticClass: "bp-row-form__title" }, [_vm._v("Zeitangaben")]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "i",
            { staticClass: "material-icons", attrs: { title: "Uhrzeit" } },
            [_vm._v("access_time")]
          ),
          _vm._v(" "),
          _c("date-time", {
            attrs: { "time-only": "" },
            model: {
              value: _vm.copyPlaceholder.startTime,
              callback: function ($$v) {
                _vm.$set(_vm.copyPlaceholder, "startTime", $$v)
              },
              expression: "copyPlaceholder.startTime",
            },
          }),
          _vm._v(" -\n            "),
          _c("date-time", {
            attrs: { "time-only": "" },
            model: {
              value: _vm.copyPlaceholder.endTime,
              callback: function ($$v) {
                _vm.$set(_vm.copyPlaceholder, "endTime", $$v)
              },
              expression: "copyPlaceholder.endTime",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", [
      _c("div", { staticClass: "bp-row-form__title" }, [_vm._v("Abrechnung")]),
      _vm._v(" "),
      _c("div", [
        _c(
          "i",
          {
            staticClass: "material-icons",
            attrs: { title: "Set as Additional" },
          },
          [_vm._v("shopping_cart")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "select" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.copyPlaceholder.clientOffer.status,
                  expression: "copyPlaceholder.clientOffer.status",
                },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.copyPlaceholder.clientOffer,
                    "status",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "included" } }, [
                _vm._v("inkludiert"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "optional" } }, [
                _vm._v("optional"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "additional" } }, [
                _vm._v("gesondert"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "atLocation" } }, [
                _vm._v("vor Ort"),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "button is-close",
        attrs: { title: "Verwerfen" },
        on: {
          click: function ($event) {
            return _vm.$emit("cancel")
          },
        },
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "button is-save is-primary",
        class: { "is-loading": _vm.isLoading },
        attrs: { title: "Speichern" },
        on: { click: _vm.save },
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("check")])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }