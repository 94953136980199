var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hide
    ? _c(
        "row",
        [
          _c("template", { slot: "type" }, [
            _c("i", { staticClass: "material-icons" }, [_vm._v("local_hotel")]),
            _vm._v(" "),
            _vm.isDouble
              ? _c("i", { staticClass: "material-icons" }, [
                  _vm._v("local_hotel"),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("template", { slot: "name" }, [
            _c(
              "div",
              { staticClass: "level-left" },
              [
                _vm.isSingle
                  ? [
                      _c(
                        "span",
                        { staticClass: "level-item" },
                        [
                          _vm.hasNightFerries && _vm.supplementPrices
                            ? [
                                _vm._v(
                                  _vm._s(
                                    _vm.getAccommodationLabel(
                                      "standard",
                                      1,
                                      _vm.roomTypes
                                    )
                                  ) + " inkl. Einzelkabinenzuschlag"
                                ),
                              ]
                            : [
                                _vm._v(
                                  _vm._s(
                                    _vm.getAccommodationLabel(
                                      "standard",
                                      1,
                                      _vm.roomTypes
                                    )
                                  )
                                ),
                              ],
                          _vm._v(" "),
                          _vm.singleRoomLimit === "always" ||
                          (_vm.singleRoomLimit === "partial" &&
                            !_vm.isLastSinglePackagePrice)
                            ? [
                                _vm._v(
                                  "\n                        bis\n                    "
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.singleRoomLimit === "partial" &&
                          _vm.isLastSinglePackagePrice &&
                          _vm.packagePrices.single.length > 1
                            ? [
                                _vm._v(
                                  "\n                        ab " +
                                    _vm._s(_vm.lastSingleLimit) +
                                    " EZ\n                    "
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.singleRoomLimit === "always" ||
                      (_vm.singleRoomLimit === "partial" &&
                        !_vm.isLastSinglePackagePrice)
                        ? [
                            _c("div", { staticClass: "level-left" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.numberOfPersons,
                                    expression: "numberOfPersons",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass: "input is-tiny level-item",
                                attrs: { type: "text" },
                                domProps: { value: _vm.numberOfPersons },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.numberOfPersons = _vm._n(
                                      $event.target.value
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v(" EZ")]),
                            ]),
                          ]
                        : _vm._e(),
                    ]
                  : [
                      _c("span", { staticClass: "level-item" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.getAccommodationLabel(
                                "standard",
                                2,
                                _vm.roomTypes
                              )
                            ) +
                            " ab\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "level-left" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.numberOfPersons,
                              expression: "numberOfPersons",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "input is-tiny level-item",
                          attrs: { type: "text" },
                          domProps: { value: _vm.numberOfPersons },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.numberOfPersons = _vm._n($event.target.value)
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(" Pax")]),
                      ]),
                    ],
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "marginTax" },
            [
              _c("margin-tax", {
                attrs: { tax: _vm.marginTax, margin: _vm.grossMargin },
                model: {
                  value: _vm.marginTaxEnabled,
                  callback: function ($$v) {
                    _vm.marginTaxEnabled = $$v
                  },
                  expression: "marginTaxEnabled",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasTwoCurrencies
            ? _c(
                "template",
                { slot: "buyPriceCalculation" },
                [
                  _c("input-price", {
                    attrs: {
                      readOnly: "",
                      value: _vm.buyPriceInCalculationCurrency,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "template",
            { slot: "buyPrice" },
            [
              _c("input-price", {
                attrs: {
                  readOnly: "",
                  value: _vm.buyPrice,
                  "is-blue": _vm.hasTwoCurrencies,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasTwoCurrencies
            ? _c(
                "template",
                { slot: "sellPriceCalculation" },
                [
                  _c("input-price", {
                    model: {
                      value: _vm.sellPriceInCalculationCurrency,
                      callback: function ($$v) {
                        _vm.sellPriceInCalculationCurrency = $$v
                      },
                      expression: "sellPriceInCalculationCurrency",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "template",
            { slot: "sellPrice" },
            [
              _c("input-price", {
                attrs: { "is-blue": _vm.hasTwoCurrencies },
                model: {
                  value: _vm.sellPrice,
                  callback: function ($$v) {
                    _vm.sellPrice = $$v
                  },
                  expression: "sellPrice",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasTwoCurrencies
            ? _c(
                "template",
                { slot: "profitCalculation" },
                [
                  _c("input-price", {
                    model: {
                      value: _vm.profitInCalculationCurrency,
                      callback: function ($$v) {
                        _vm.profitInCalculationCurrency = $$v
                      },
                      expression: "profitInCalculationCurrency",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "template",
            { slot: "profit" },
            [
              _c("input-price", {
                attrs: { "is-blue": _vm.hasTwoCurrencies },
                model: {
                  value: _vm.profit,
                  callback: function ($$v) {
                    _vm.profit = $$v
                  },
                  expression: "profit",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "margin" },
            [
              _c("input-price", {
                model: {
                  value: _vm.margin,
                  callback: function ($$v) {
                    _vm.margin = $$v
                  },
                  expression: "margin",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("template", { slot: "actions" }, [
            _c(
              "i",
              {
                staticClass: "material-icons",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.deletePackage.apply(null, arguments)
                  },
                },
              },
              [_vm._v("delete")]
            ),
            _vm._v(" "),
            _c(
              "i",
              {
                staticClass: "material-icons",
                class: { "is-active": _vm.isHighlighted },
                on: { click: _vm.toggleHighlight },
              },
              [_vm._v("location_searching")]
            ),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }