var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("emails", {
    attrs: {
      "preselected-order-concept": _vm.item,
      titleText: "ZUGEORDNETE EMAILS",
      isSimpleList: "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }