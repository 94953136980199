<template>

    <div class="has-margin-top">
        <div class="bp-panel has-no-title" style="min-height: auto">
            <div class="panel-content">
                <div class="columns has-seperating-borders">
                    <div class="column" v-for="link in orderWebflowLinks">
                        <div>
<!--                            <div class="control">
                                <label class="checkbox">
                                    <input type="checkbox" v-bind:value="website.id" v-model="form.websites">
                                    {{website.url}}
                                </label>
                            </div>-->
                            <h4>{{link.webflowCollection.webflowHomepage.url}}</h4>
                            <p class="is-small-text"
                               :class="{
                                'is-text-red': getStatus(link) === 'notPublished',
                                'is-text-yellow' : getStatus(link) === 'staged',
                                'is-text-blue': getStatus(link) === 'published'
                            }"
                            >{{getStatusText(link)}}</p>
                        </div>
                        <div class="is-small-text">
                                <a class="is-text-link-color" target="_blanc" :href="getUrl(link, true)">Testseite öffnen</a>
                        </div>
                        <div class="is-small-text">
                            <a class="is-text-link-color" target="_blanc" :href="getUrl(link, false)">Hauptseite öffnen</a>
                        </div>
                        <div class="level level-left mt-4" >
                            <div class="control level-item" v-if="getStatus(link) === 'published'">
                                <button
                                    v-bind:class="{ 'is-loading' : isLoading }"
                                    @click="publish(link, 'draft')"
                                    class="button is-full-width">
                                    <i class="material-icons">delete</i> Von Homepage löschen
                                </button>
                            </div>
                            <div class="level-item">
                                <button
                                    v-bind:class="{ 'is-loading' : isLoading }"
                                    @click="publish(link, 'preview')"
                                    class="button is-full-width">
                                    <i class="material-icons">sync</i> Auf Testseite veröffentlichen
                                </button>
                            </div>
                            <div class="control level-item">
                                <button
                                    v-bind:class="{ 'is-loading' : isLoading }"
                                    @click="publish(link, 'publish')"
                                    class="button is-full-width is-primary">
                                    <i class="material-icons">public</i> Auf Hauptseite veröffentlichen
                                </button>
                            </div>
                            <div class="control level-item">
                                <button
                                    v-bind:class="{ 'is-loading' : isLoading }"
                                    @click="deleteFromWebsite(link)"
                                    v-if="getStatus(link) === 'publish' || getStatus(link) === 'staged'"
                                    class="button is-full-width is-danger">
                                    <i class="material-icons">delete</i> Von Website entfernen
                                </button>
                            </div>
                        </div>
                    </div>
<!--                    <div class="column">-->
<!--                        <div class="field">-->
<!--                            <div class="control">-->
<!--                                <button-->
<!--                                   @click="publish(false, true)"-->
<!--                                    class="button is-smaller">-->
<!--                                    <i class="material-icons">sync</i> Daten für Veröffentlichung vormerken-->
<!--                                </button>-->
<!--                                <p class="is-small-text">-->
<!--                                    <a class="is-text-link-color" href="">Testseite öffnen</a>-->
<!--                                    <span> | letzte</span>-->
<!--                                </p>-->

<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="column" v-if="checkIsTransferred">-->
<!--                        <div class="field">-->
<!--                            <div class="control">-->
<!--                                <button-->
<!--                                    @click="publish(true, false)"-->
<!--                                    class="button is-smaller">-->
<!--                                    <i class="material-icons">public</i> Daten aktualisieren und veröffentlichen-->
<!--                                </button>-->
<!--                            </div>-->
<!--                            <p class="is-small-text">Achtung: Betrifft auch vorgemerkte Daten anderer Reisen</p>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </div>


</template>

<script>

import moment from 'moment';
import {dateISOtoView, dateWithLocalTime} from "@utilities/functions";
import Mixin from '../../tab';
import {edit, getWebflowHomepages} from "@api";
import {omit} from "lodash";
import {notifyError, notifySuccess} from "@components/Notification";

//todo: check when system changes updatedAt


export default {
    mixins: [ Mixin ],

    props: {
        component: {
            type: Object,
            required: true,
        }
    },

    data: function() {
        return {
            websites: [],
            isLoading: false,
        }
    },

    methods: {
        getUrl(link, testUrl = false) {
            let base = testUrl ? 'http://' + link.webflowCollection.webflowHomepage.testUrl : 'https://' + link.webflowCollection.webflowHomepage.url;
            if((this.getStatus(link) === 'published' || (testUrl && this.getStatus(link) === 'staged')) && link.webflowItemSlug) {
                return base + '/orders/' + link.webflowItemSlug;
            }
            return base;
        },

        getStatusText(link) {
            let status = this.getStatus(link);
            if(status === 'published') {
                return 'Auf Hauptseite veröffentlicht | ' + this.getPublishedAt(link);
            }
            if(status === 'staged') {
                return 'Auf Testseite veröffentlicht | ' + this.getStagedAt(link);
            }
            return 'Reise nicht veröffentlicht';
        },

        getStatus(link) {
            return link ? link.status : 'notPublished';
        },

        getPublishedAt: function (link) {
            return link && link.publishedAt ? moment(link.publishedAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm:ss') : null
        },

        getStagedAt: function (link) {
            return link && link.stagedAt ? moment(link.stagedAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm:ss') : null
        },

        deleteFromWebsite: function (link) {
            this.isLoading = true;

            edit('webflow_links/' + link.id + '/delete_from_website', {
                publishedAt: null,
                stagedAt: null,
            }).then(response => {
                notifySuccess('Die Aktion wurde erfolgreich durchgeführt!')
                this.$emit('updateData', {
                    webflowLinks: this.item.webflowLinks.map(link => link.id === response.data.id ? response.data : link)
                });
            }, error => {
                notifyError("Die Aktion konnte nicht durchgeführt werden! Server Error!")
            }).then(() => this.isLoading = false );
        },

        publish: function (link, action) {
            if(
                action === 'preview' ||
                (action === 'publish' && confirm('Soll die Reise wirklich veröffentlicht werden?')) ||
                (action === 'draft' && confirm('Soll die Reise wirklich von der Homepage entfernt werden?'))
            ) {
                this.isLoading = true;
                edit('webflow_links/' + link.id + '/publish', {
                    ...action === 'publish' && {publishedAt: moment().format('DD.MM.YYYY HH:mm:ss')},
                    ...action === 'preview' && {stagedAt: moment().format('DD.MM.YYYY HH:mm:ss')},
                    ...action === 'draft' && {publishedAt: null, stagedAt: null},
                }, {
                    action: action
                }).then(response => {
                    notifySuccess('Die Aktion wurde erfolgreich durchgeführt!')
                    this.$emit('updateData', {
                        webflowLinks: this.item.webflowLinks.map(link => link.id === response.data.id ? response.data : link)
                    });
                }, error => {
                    notifyError("Die Aktion konnte nicht durchgeführt werden! Server Error!")
                }).then(() => this.isLoading = false );
            }
        }
    },

    computed: {
        orderWebflowLinks () {
            return this.item.orderWebflowLinks ? this.item.orderWebflowLinks.filter(item => !!item.webflowCollection).sort((a,b) => b.webflowCollection.webflowHomepage.id - a.webflowCollection.webflowHomepage.id) : [];
        },

        updatedAt: function () {
            return this.item.updatedAt ? dateISOtoView(this.item.updatedAt) : '-';
        },
    },

    mounted: function () {
        getWebflowHomepages({'_groups[]': 'webflow_homepage:webflow_collection'}).then(response => {
            this.websites = response.data;
            let websiteIds = this.orderWebflowLinks.map(a => a.webflowCollection.webflowHomepage.id);
            let links = this.orderWebflowLinks.map(a => '/api/webflow_links/' + a.id);
            let newData = false;
            this.websites.forEach(webflowHomepage => {
                if(!websiteIds.includes(webflowHomepage.id)) {
                    newData = true;
                    links.push(
                        {
                            orderConcept: '/api/' + this.api + '/' + this.item.id,
                            publishedAt: null,
                            homepageId: webflowHomepage.id,
                            webflowCollection: '/api/webflow_collections/' + webflowHomepage.orderCollection.id
                        }
                    )
                }
            })
            if(newData) {
                this.$emit('saveItem', 'orderWebflowLinks', links, true, false, false);
            }
        });
    },

}
</script>
