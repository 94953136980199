

export const infoSections = {
    trip_dashboard: 'Reisemaske',
    offer:          'Routenplanung in Dokumenten',
};

export const categoryIcons = {
    'activity': {icon: 'place'},
    'round': {icon: 'autorenew'},
    'culture': {icon: 'account_balance'},
    'active': {icon: 'directions_run'},
    'home': {text: 'WH'},
    'classics': {text: 'WC'},
    'experience': {text: 'WE'},
    'individual': {text: 'I'},
};

export const emailHosts = [
    'buspaket.de',
    'we-tours.de'
];

export const preSelections = {
    'order_concept': {
        'sellingPriceForm': {
            'periodType': 'dates'
        }
    }
}

export const homepageColorMap = {
    sardinia: '#60A3AB',
    corsica: '#A86968',
    sicily: '#D6B874',
    mediterranean_sea: '#87A873',
    combination: '#E78E7C',
    celebration: '#8B81A9'
}


