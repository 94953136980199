<template>

    <div class="has-margin-top">
        <div class="bp-panel is-form has-no-title" style="min-height: auto">
            <loading v-if="isLoading"></loading>
            <div v-else class="panel-content is-table-form">
                <table>
                    <template v-for="row in component.props.rows">
                        <tr>
                            <template v-for="field in row.fields">
                                <th v-if="field.type === 'label'">
                                    <label class="label">{{field.label}}:</label>
                                </th>
                                <th v-if="field.type === 'computedLabel'">
                                    <label class="label">{{item[field.property]}}:</label>
                                </th>
                                <td class=pl-4 v-if="field.type === 'multiselect'">
                                    <div class="control">
                                        <multiselect
                                            v-if="field.getValuesFromOptions"
                                            v-bind:value="getValuesFromOptions(field)"
                                            v-bind:options="field.options"
                                            :label="field.label"
                                            :track-by="field.trackBy"
                                            @input="value => setValuesFromOptions(value, field)"
                                            :multiple="field.multiple"
                                        />
                                        <multiselect
                                            v-else-if="field.getValueFromOptions"
                                            v-bind:value="getValueFromOptions(field)"
                                            v-bind:options="field.options"
                                            :label="field.label"
                                            :track-by="field.trackBy"
                                            @input="value => setValueFromOptions(value, field)"
                                            :multiple="field.multiple"
                                        />
                                        <multiselect
                                            v-else
                                            v-model="element[field.property]"
                                            v-bind:options="field.options"
                                            :label="field.label"
                                            :track-by="field.trackBy"
                                            @input="saveItem(field)"
                                            :multiple="field.multiple"
                                        />
                                    </div>
                                </td>
                                <td class=pl-4 v-if="field.type === 'input'">
                                    <div class="control">
                                        <input class="input" type="text" v-model="element[field.property]" @input="saveItem(field)">
                                    </div>
                                </td>
                                <td class=pl-4 v-if="field.type === 'numericalInput'">
                                    <div class="control">
                                        <input class="input" type="number" v-model.number="element[field.property]" @input="saveItem(field)">
                                    </div>
                                </td>
                                <td class=pl-4 v-if="field.type === 'buttons'">
                                    <div class="field is-grouped">
                                        <template v-for="button in field.buttons">
                                            <div class="control">
                                                <button class="button" @click="$emit('buttonAction', {action: button.buttonAction, element: element})">{{button.property}}</button>
                                            </div>
                                        </template>
                                    </div>
                                </td>
                                <td class="pl-4" v-if="field.type === 'toggleSwitch'">
                                    <div class="control">
                                        <toggle-switch
                                            v-model="element[field.property]"
                                            @input="saveItem(field)"
                                        >
                                            <option v-for="option in field.options" :value="option.value">
                                                {{option.label}}
                                            </option>
                                        </toggle-switch>
                                    </div>
                                </td>
                                <td class="pl-4" v-if="field.type === 'checklistItem'">
                                    <div class="field is-grouped is-grouped-right">
                                        <div class="control">
                                            <toggle :value="getChecklistItem(field.propertyType)" @input="input => setChecklistItem(input, field.propertyType)" height="18"></toggle>
                                        </div>
                                    </div>
                                </td>
                                <td class=pl-4 v-if="field.type === 'dropZone'">
<!--                                        <drop-zone
                                            v-if="!getDocument(field)"
                                            :document-data="field.documentData"
                                            :options="field.options"
                                            @documentSaved="document => saveDocument(document, field)"
                                        />-->
                                        <template>
                                            <documents
                                                :has-head="true"
                                                :has-form="false"
                                                :has-title="false"
                                                :has-filter="false"
                                                :dropzone="false"
                                                :has-border="false"
                                                title="ABGESPEICHERTE MENÜLISTEN ZUR REISE"
                                                v-bind="documentProps(field)"
                                                ref="documents"
                                            />

<!--                                                    {{getDocument(field).name}}{{fileType(getDocument(field))}}

                                                    <a
                                                        title="Download"
                                                        v-bind:href="downloadDocument(getDocument(field))"
                                                        target="_blank"
                                                        download
                                                    ><i class="material-icons" title="Downloaden">file_download</i></a>

                                                    <button v-if="fileType(getDocument(field)) === '.docx'" v-on:click.prevent="convertDocument(field)" title="in PDF umwandeln"><i class="material-icons">picture_as_pdf</i></button>

                                                    <button v-on:click.prevent="deleteDocument(field)" title="Löschen"><i class="material-icons">delete</i></button>-->
                                        </template>
                                </td>
                            </template>
                        </tr>
                    </template>
                </table>
            </div>
        </div>
    </div>


</template>

<script>

import Mixin from '../../tab';
import {Multiselect, Toggle, ToggleSwitch} from "@components/form";
import DropZone from "@components/form/DropZone";
import {axiosDelete, convertDocument, editImage, getAxios} from "@api";
import {getFilePath} from "@utilities/functions";
import moment from "moment";
import Buttons from "@components/CRUD/Table/Buttons";
import {notifyError, notifySuccess} from "@components/Notification";
import Loading from "@components/Loading";
import Documents from "@components/documents/List.vue";


export default {
    mixins: [ Mixin ],

    props: {
        component: {
            type: Object,
            required: true
        },
        newItem: {
            type: Object,
            required: false
        },
    },

    data: function () {
        return {
            documents: [],
            isLoading: false,
        }
    },

    methods: {
        fileType: function (document) {
            return document.path ? `.${document.path.split('.').pop()}` : '';
        },

        getValuesFromOptions(field) {
            return field.options.filter(item => this.element[field.property].includes(item[field.trackBy]))
        },

        setValuesFromOptions(value, field) {
            this.element[field.property] = value.map(item => item[field.trackBy]);
            this.saveItem(field);
        },

        getValueFromOptions(field) {
            return field.options.find(item => item[field.trackBy] === this.element[field.property]);
        },

        setValueFromOptions(value, field) {
            this.element[field.property] = value[field.trackBy];
            this.saveItem(field);
        },

        prepareData (data, property) {
            if(property === 'user' && typeof data === 'object') {
                data = '/api/users/' + data.id
            }
            return data;
        },

        saveItem: function(field) {
            this.$emit('saveItemDebounce', field.property, this.prepareData(this.element[field.property], field.property))
        },

        getChecklistItem(title) {
            if(!this.element.checklistItems) {
                return false;
            }
            let item = this.element.checklistItems.find(item => item.title === title);
            return item ? !!item.doneDate : false
        },

        setChecklistItem(value, title) {
            let element = this.element;
            let item = element.checklistItems.find(item => item.title === title);

            if(!item) {
                item = {
                    title: title,
                    doneDate: null
                }
                element.checklistItems.push(item)
            }
            item.doneDate = value ? moment().format('DD.MM.YYYY HH:mm:ss') : null
            this.$emit('saveItemDebounce', 'checklistItems', element.checklistItems)
        },

        /*getDocument(field) {
            return this.documents.find(doc => doc.identifier === field.identifier);
        },*/

        /*saveDocument(document, field) {
            this.documents.push({...document, identifier: field.identifier});
        },

        fetchDocument(field) {
            if(field.type === 'dropZone') {
                getAxios('documents', field.filterParams).then(response => {
                    if(response.data.length > 0) {
                        this.saveDocument(response.data[0], field);
                    }
                })
            }
        },

        downloadDocument: function(document) {
            if(!document) {
                return '';
            }
            if(document.cloudStorage){
                return document.path;
            }
            return getFilePath(document.id)
        },*/

        documentProps: function (field) {

            return {
                filterObject: field.filterParams,
                uploadData: field.documentData
            }
        },

    },

    computed: {


        element: function() {
            return this.newItem ? this.newItem : this.item;
        },
    },

    mounted: function () {
        /*this.component.props.rows.map(row => {
            row.fields.map(field => {
                this.fetchDocument(field);
            })});*/
    },

    components: {
        Documents,
        Loading,
        Buttons,
        DropZone,
        ToggleSwitch,
        Toggle,
        Multiselect,
    },
}
</script>
