<template>
    <modal
        classes="new-email"
        v-bind:visible="isModalVisible"
        v-on:closeModal="$emit('closeModal')"
    >
        <template slot="header">
            <div class="name" v-if="!action">NEUE EMAIL</div>
            <div class="name" v-else-if="action === 'reply'">Antworten</div>
            <div class="name" v-else-if="action === 'replyAll'">Allen Antworten</div>
            <div class="name" v-else-if="action === 'forward'">Weiterleiten</div>
        </template>


        <template slot="content">
            <email-form
                v-bind:preselected-order-concept="preselectedOrderConcept"
                v-bind:preselected-concept-provider="preselectedConceptProvider"
                v-bind:preselected-contact   = "isReply ? null : preselectedContact"
                v-bind:select-template    = "selectTemplate"
                v-bind:attach-document    = "attachDocument"
                v-on:closeModal = "refresh => $emit('closeModal', refresh)"
                ref="form"
            ></email-form>
        </template>
    </modal>
</template>



<script>
import Modal    from '@components/Modal';
import store        from '@components/emails/store';
import EmailForm    from './EmailForm';
import OrdersSelect from './OrdersSelect';
import ToggleSwitch from "@components/form/ToggleSwitch";
import {settings} from "@clientSpecific/utilities/settings";
import {currentUser} from "@utilities/functions";
export default {
    store: store,
    props: {
        isModalVisible:     { type: Boolean, required: true }, //controls if modal is visible
        preselectedOrderConcept:   { default: null }, //preselects Orders
        priceRequestYear: {default: null},
        preselectedConceptProvider: { default: null }, //preSelects requests
        preselectedContact: { default: null }, //preSelects Contact
        selectTemplate:     { default: null}, //preSelects a template
        attachDocument:     { type: Object, default: null} //attaches a document
    },
    data: function() {
        return {
            settings: settings
        }
    },
    computed: {


        action: function () { return this.$store.state.action; },

        isReply: function () {
            return this.$store.state.action === 'reply' ||
                this.$store.state.action === 'replyAll' ||
                this.$store.state.action === 'forward';
        }
    },

    mounted () {
        this.$store.state.emailType = 'provider';
        this.$store.state.newEmail.fromEmail = currentUser('userEmails')[0];
        if(this.settings.emails.isFormal['provider']) {
            this.$store.state.isFormal = settings.emails.selectedIsFormal.provider;
        }
        this.$store.state.priceRequestYear = this.priceRequestYear;
        //this.$store.dispatch('resetTemplates');

    },

    components: {
        ToggleSwitch,
        EmailForm,
        Modal,
        OrdersSelect
    }
}
</script>
