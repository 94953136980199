var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["email", _vm.classNames, !_vm.incoming ? "is-outgoing" : ""] },
    [
      _c("div", { staticClass: "column" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "user-image" },
            [_c("user-image", { attrs: { user: _vm.email.user } })],
            1
          ),
          _vm._v(" "),
          _vm.email.type === "phone"
            ? _c(
                "i",
                { staticClass: "material-icons phone is-turqoise-text" },
                [_vm._v("phone")]
              )
            : _vm.incoming
            ? _c(
                "i",
                {
                  staticClass: "material-icons incoming",
                  staticStyle: { color: "limegreen" },
                },
                [_vm._v("send")]
              )
            : _c(
                "i",
                {
                  staticClass: "material-icons",
                  staticStyle: { color: "darkblue" },
                },
                [_vm._v("send")]
              ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "column" }, [
        _c("div", [
          _vm.fromName
            ? _c(
                "div",
                {
                  staticClass: "title",
                  attrs: {
                    title: _vm.incoming
                      ? _vm.email.fromAddress
                      : _vm.email.toAddresses && _vm.email.toAddresses[0].email,
                  },
                },
                [_vm._v(_vm._s(_vm.fromName))]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.fromName
            ? _c("div", { staticClass: "title" }, [
                _vm._v(
                  _vm._s(
                    _vm.incoming
                      ? _vm.email.fromAddress
                      : _vm.email.toAddresses && _vm.email.toAddresses[0].email
                  )
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "date" }, [_vm._v(_vm._s(_vm.email.date))]),
        ]),
        _vm._v(" "),
        _vm.email.contact &&
        _vm.email.contact.emailInfos &&
        _vm.email.contact.emailInfos.length > 0
          ? _c("div", { staticClass: "description" }, [
              _c("a", { attrs: { href: _vm.email.contact.emailInfos[0] } }, [
                _vm._v(_vm._s(_vm.email.contact.emailInfos[1])),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.email.contact
          ? _c("div", { staticClass: "contact" }, [_vm._v("nicht zugeordnet")])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "description" }, [
          _vm._v(_vm._s(_vm.email.subject)),
        ]),
        _vm._v(" "),
        (_vm.email.tags && _vm.email.tags.length > 0) || _vm.nrOfAttachments > 0
          ? _c(
              "div",
              { staticClass: "buttons email-view" },
              [
                _vm.email.tags && _vm.email.tags.length > 0
                  ? _vm._l(_vm.email.tags, function (tag) {
                      return _c(
                        "span",
                        {
                          key: tag,
                          staticClass: "button is-small",
                          staticStyle: {
                            overflow: "hidden",
                            "justify-content": "left",
                          },
                        },
                        [_vm._v(_vm._s(tag))]
                      )
                    })
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "attachments" }, [
          _vm.nrOfAttachments > 0
            ? _c("i", { staticClass: "material-icons" }, [
                _vm._v("attach_file"),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }