<template>
    <div
        class="placeholder-view"
        v-bind:class="{
            'has-requests'             : '!isConcept',
            'has-no-route-description' : !placeholder.routeDescription && isRouteView,
            'is-optional'              : placeholder.clientOffer.status === 'optional' || placeholder.clientOffer.status === 'atLocation',
            'is-additional'              : placeholder.clientOffer.status === 'additional',
        }"
    >
        <div v-if="isRouteView" v-bind:title="placeholder.routeDescription ?? placeholder.serviceDescription">
            <other-service-icons v-if="placeholder.otherServiceType" v-bind:type="placeholder.otherServiceType.category"></other-service-icons>

            <template v-if="placeholder.routeDescription" v-bind:title="placeholder.routeDescription">
                <template v-if="placeholder.clientOffer.status === 'optional'">Möglichkeit: </template>
                <template v-if="placeholder.clientOffer.status === 'atLocation'">vor Ort: </template>
                {{ placeholder.routeDescription }}
            </template>
            <template v-else>ohne Routenbeschreibung ({{placeholder.serviceDescription}})</template>
        </div>

        <div v-else v-bind:title="placeholder.serviceDescription">
            <other-service-icons v-if="placeholder.otherServiceType" v-bind:type="placeholder.otherServiceType.category"></other-service-icons>
            <template v-if="placeholder.clientOffer.status === 'optional'">Möglichkeit: </template>
            <template v-if="placeholder.clientOffer.status === 'atLocation'">vor Ort: </template>
            {{ placeholder.serviceDescription }} <template v-if="placeholder.selectedRequest"> ({{ placeholder.selectedRequest.info.provider.name ? placeholder.selectedRequest.info.provider.name : 'No-Name' }})</template>
        </div>


        <div class="is-hidden-on-small">
            <template v-if="showDuration && !isConcept">
                <template v-if="placeholder.durationDays > 1">{{ placeholder.durationDays }} Tage</template>
                <template v-if="fullYear(placeholder.startAt) !== fullYear(placeholder.endAt)">
                    {{ placeholder.startAt | dayMonth }} - {{ placeholder.endAt | fullYear }}
                </template>
            </template>
        </div>


        <div class="is-60" v-if="!isConcept">
            <template v-if="fullYear(placeholder.startAt) !== fullYear(placeholder.endAt)">{{ timeUntil(placeholder.startAt, placeholder.endAt).days + 1}} T</template>
        </div>


        <div class="is-100 is-hidden-on-small">
            <time-of-day v-if="isConcept" v-bind:start="placeholder.startTime" v-bind:end="placeholder.endTime"></time-of-day>
            <time-of-day v-else v-bind:start="placeholder.startAt" v-bind:end="placeholder.endAt"></time-of-day>
        </div>

        <div class="contingent-section" v-if="!isConcept">
            <template v-if="placeholder.info && placeholder.info.contingent">
                {{ placeholder.info.contingent }} Pax
                <i class="fa fa-warning" title="Unterdeckung" v-if="hasContingentWarning"></i>
            </template>
        </div>


        <div v-if="!isConcept">
            <deadline v-bind:value="placeholder.info.deadline"></deadline>
        </div>


        <agency-and-provider
            v-if="!isConcept"
            v-bind:agency   = "placeholder.info.agency"
            v-bind:provider = "placeholder.info.provider"
        ></agency-and-provider>

        <concept-provider
            v-if="isConcept"
            v-bind:providers="placeholder.providers"
        >
        </concept-provider>


        <div class="is-130" v-if="!isConcept">
            <template v-if="placeholder.requests && placeholder.requests.length">
                {{ getValueWithKey({ key: placeholder.info.status, optionList: statusesList }) }}
            </template>
        </div>


        <div class="action-buttons"><slot></slot></div>

        <button
            v-if         = "placeholder.requests && placeholder.requests.length > 0"
            class        = "nr-of-requests"
            title        = "Anfragen"
            v-bind:class = "['is-' + placeholder.info.urgency]"
            v-on:click.stop = "$emit('toggleRequests')"
        >{{ placeholder.requests.length }}</button>

        <div
            v-if="placeholder.sortGroup"
            class="group-icon"
            v-bind:class="'is-' + placeholder.sortGroup"
        ></div>
    </div>
</template>



<script>
import OtherServiceIcons from '@components/OtherServiceIcons';
import mixins from './mixins';
import { timeUntil } from '@utilities/functions';


export default {
    mixins: [mixins],


    components: {
        OtherServiceIcons
    },

    methods: {
        timeUntil
    }
}
</script>
