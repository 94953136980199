var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isRouteView
    ? _c(
        "div",
        {
          staticClass: "placeholder-view has-requests",
          class: {
            "has-no-route-description":
              !_vm.placeholder.text && _vm.isRouteView,
          },
        },
        [
          _c(
            "div",
            { attrs: { title: _vm._f("stripHtml")(_vm.placeholder.text) } },
            [
              _c(
                "i",
                { staticClass: "material-icons", attrs: { title: "Freitext" } },
                [_vm._v("view_headline")]
              ),
              _vm._v(" "),
              _vm.placeholder.text
                ? [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm._f("stripHtml")(_vm.placeholder.text)) +
                        "\n        "
                    ),
                  ]
                : [_vm._v("Freitext ist leer")],
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { class: { "is-hidden-on-small": !_vm.isConcept } }),
          _vm._v(" "),
          !_vm.isConcept ? _c("div", { staticClass: "is-60" }) : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "is-100 is-hidden-on-small" },
            [
              _vm.isConcept
                ? _c("time-of-day", {
                    attrs: { start: _vm.placeholder.startTime, end: "00:00" },
                  })
                : _c("time-of-day", {
                    attrs: { start: _vm.placeholder.startAt, end: "00:00" },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isConcept
            ? _c("div", { staticClass: "contingent-section" })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isConcept ? _c("div") : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "is-truncated is-250" }),
          _vm._v(" "),
          !_vm.isConcept ? _c("div", { staticClass: "is-130" }) : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "action-buttons" }, [_vm._t("default")], 2),
          _vm._v(" "),
          _vm.placeholder.sortGroup
            ? _c("div", {
                staticClass: "group-icon",
                class: "is-" + _vm.placeholder.sortGroup,
              })
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }