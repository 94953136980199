<template>
    <div>
        <gb-flag
            :code="getFlag(value)"
            size="small"
            iconPath="/assets/img/flags/"
        />
    </div>
</template>

<script>
import Cell from './cell';
import {getFlag} from "@utilities/functions";

export default {
    mixins: [ Cell ],

    props: {
        value: {
            required: true
        },
    },

    methods: {
        getFlag
    }
}
</script>
