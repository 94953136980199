var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns has-margin-top" }, [
    _c("div", { staticClass: "column" }, [
      _c(
        "div",
        { staticClass: "bp-panel has-header is-grey-lightest is-settings" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "panel-content" }, [
            _c("div", { staticClass: "columns" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column is-one-third" },
                [
                  _c("multiselect", {
                    staticClass: "level-item is-blue",
                    attrs: {
                      options: _vm.options.clientCurrencies,
                      "allow-empty": false,
                      placeholder: "",
                    },
                    on: { input: _vm.updateOrderCurrency },
                    model: {
                      value: _vm.currencyForm.orderCurrency,
                      callback: function ($$v) {
                        _vm.$set(_vm.currencyForm, "orderCurrency", $$v)
                      },
                      expression: "currencyForm.orderCurrency",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column is-one-third" },
                [
                  _c("multiselect", {
                    staticClass: "level-item",
                    attrs: {
                      options: _vm.options.calculationCurrencies,
                      "allow-empty": false,
                      placeholder: "",
                    },
                    on: { input: _vm.updateCalculationCurrency },
                    model: {
                      value: _vm.currencyForm.calculationCurrency,
                      callback: function ($$v) {
                        _vm.$set(_vm.currencyForm, "calculationCurrency", $$v)
                      },
                      expression: "currencyForm.calculationCurrency",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "column" }, [
      _c(
        "div",
        { staticClass: "bp-panel has-header is-grey-lightest is-settings" },
        [
          _vm._m(3),
          _vm._v(" "),
          _c("div", { staticClass: "panel-content" }, [
            _c("div", { staticClass: "columns" }, [
              _vm._m(4),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column is-half" },
                [
                  _c("multiselect", {
                    attrs: {
                      "custom-label": (item) => item,
                      options: _vm.options.years,
                    },
                    on: { select: _vm.handleSearch },
                    model: {
                      value: _vm.form.year,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "year", $$v)
                      },
                      expression: "form.year",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _vm._m(5),
              _vm._v(" "),
              _c("div", { staticClass: "column is-half" }, [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.resetCalculationRequestPrices.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [_vm._v("zurücksetzen")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "column" }, [
      _c(
        "div",
        { staticClass: "bp-panel has-header is-grey-lightest is-settings" },
        [
          _vm._m(6),
          _vm._v(" "),
          _c("div", { staticClass: "panel-content" }, [
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c(
                    "toggle-switch",
                    {
                      attrs: { "btn-class": "is-fullwidth" },
                      on: { input: _vm.updateShowExchangeRates },
                      model: {
                        value: _vm.showExchangeRates,
                        callback: function ($$v) {
                          _vm.showExchangeRates = $$v
                        },
                        expression: "showExchangeRates",
                      },
                    },
                    [
                      _c("option", { domProps: { value: false } }, [
                        _vm._v("Preisvorschläge"),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: true } }, [
                        _vm._v("Umrechnungen"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "column" }, [
      Object.keys(_vm.defaultExchangeRates).length > 1 ||
      _vm.currencyForm.orderCurrency !== _vm.currencyForm.calculationCurrency
        ? _c(
            "div",
            { staticClass: "bp-panel has-header is-grey-lightest is-settings" },
            [
              _vm._m(7),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "panel-content" },
                [
                  _vm._l(_vm.defaultExchangeRates, function (rate, currency) {
                    return [
                      currency !== _vm.currencyForm.calculationCurrency
                        ? _c(
                            "div",
                            { staticClass: "columns is-variable is-2" },
                            [
                              _c("div", { staticClass: "column is-5" }, [
                                _c("div", { staticClass: "level" }, [
                                  _c("label", { staticClass: "label" }, [
                                    _vm._v(
                                      "1 " +
                                        _vm._s(
                                          _vm.ratesAreReversed
                                            ? _vm.currencyPreference
                                            : currency
                                        ) +
                                        " entspricht:"
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "column is-7" },
                                [
                                  _c("input-price", {
                                    staticClass: "is-medium-small level-item",
                                    attrs: {
                                      value:
                                        _vm.ratesAreReversed &&
                                        _vm.exchangeRates[currency] > 0
                                          ? 1 / _vm.exchangeRates[currency]
                                          : _vm.exchangeRates[currency],
                                      placeholder: "0,00",
                                      digits: "4",
                                      defaultExchangeRate:
                                        _vm.ratesAreReversed && rate > 0
                                          ? 1 / rate
                                          : rate,
                                      "force-show-exchange-rates": true,
                                      suffix: _vm.ratesAreReversed
                                        ? currency
                                        : _vm.currencyForm.calculationCurrency,
                                    },
                                    on: {
                                      input: (value) =>
                                        _vm.updateExchangeRate(
                                          _vm.ratesAreReversed && value > 0
                                            ? 1 / value
                                            : value,
                                          currency
                                        ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(" "),
                  _vm.currencyForm.orderCurrency !==
                    _vm.currencyForm.calculationCurrency &&
                  !_vm.hasOverlappingCurrencies
                    ? _c("div", { staticClass: "columns is-variable is-2" }, [
                        _c("div", { staticClass: "column is-5" }, [
                          _c("div", { staticClass: "level" }, [
                            _c("label", { staticClass: "label" }, [
                              _vm._v(
                                "1 " +
                                  _vm._s(_vm.currencyForm.calculationCurrency) +
                                  " entspricht:"
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "column is-7" },
                          [
                            _c("input-price", {
                              staticClass: "is-medium-small level-item",
                              attrs: {
                                placeholder: "0,00",
                                digits: "4",
                                defaultExchangeRate:
                                  _vm.defaultCalculationToOrderExchangeRate,
                                "force-show-exchange-rates": true,
                                suffix: _vm.currencyForm.orderCurrency,
                              },
                              model: {
                                value: _vm.calculationToClientExchangeRate,
                                callback: function ($$v) {
                                  _vm.calculationToClientExchangeRate = $$v
                                },
                                expression: "calculationToClientExchangeRate",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-header is-grey-lightest" }, [
      _c("h1", [_vm._v("Kalkulationseinstellungen")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-two-thirds" }, [
      _c("div", { staticClass: "level" }, [
        _c("label", { staticClass: "label" }, [_vm._v("Kundenwährung:")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-two-thirds" }, [
      _c("div", { staticClass: "level" }, [
        _c("label", { staticClass: "label" }, [
          _vm._v("Währung zum Kalkulieren:"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-header is-grey-lightest" }, [
      _c("h1", [_vm._v("Preisvorschläge aus den Stammdaten")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-half" }, [
      _c("div", { staticClass: "level" }, [
        _c("label", { staticClass: "label" }, [_vm._v("Preissuche bis Jahr:")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-half" }, [
      _c("div", { staticClass: "level" }, [
        _c("label", { staticClass: "label" }, [_vm._v("Verlinkte Preise :")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-header is-grey-lightest" }, [
      _c("h1", [_vm._v("Anzeigeoptionen")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-header is-grey-lightest" }, [
      _c("h1", [_vm._v("Währungskurse")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }