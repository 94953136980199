<template>
    <div>
        <modal-form
            editTitle="Hinweis bearbeiten"
            createTitle="Neuen Hinweis hinzufügen"
            v-on:imageUploaded="handleImageDrop"
        >
            <table>
                <template v-if="!editMode">
                    <tr>
                        <th>
                            <label for="type" class="label">Art:</label>
                        </th>
                        <td>
                            <toggle-switch v-model="form.tripDetailType">
                                <option :value="null">nur Hinweis</option>
                                <option v-for="(name, value) in tripDetailTypesFiltered" v-bind:key="value" v-bind:value="value">{{name}}</option>
                            </toggle-switch>
                        </td>
                    </tr>
                </template>

                <tr>
                    <th class="is-top">
                        <label for="images" class="label">Bilder (540 x 250):</label>
                    </th>
                    <td>
                        <image-upload v-model="form.images" multiple label="Bild hochladen">
                            <template slot="buttons">
                                <button class="button" v-on:click.prevent.stop="visibleImageMap = true">
                                    <i class="material-icons">map</i>
                                    Google Map Karte
                                </button>
                                <button class="button" v-on:click.prevent.stop="$refs.imageSelect.open()">
                                    <i class="material-icons">photo</i>
                                    Bild aus Stammdaten
                                </button>
                            </template>
                        </image-upload>
                    </td>
                </tr>

                <tr v-if="isNotDestination">
                    <th>
                        <label for="sections" class="label">Anzeigen:</label>
                    </th>
                    <td>
                        <toggle-switch v-model="form.sections">
                            <option v-for="(name, value) in infoSections" v-bind:key="value" v-bind:value="value">{{name}}</option>
                        </toggle-switch>
                    </td>
                </tr>

                <tr>
                    <th><label for="importance" class="label">Wichtigkeit:</label></th>
                    <td>
                        <multiselect
                            v-bind:options="importances"
                            v-model="importance"
                            track-by="key"
                            label="value"
                            class="is-medium"
                        />
                    </td>
                </tr>


                <tr v-if="!item.providerType && isNotDestination">
                    <th><label for="form.showInConnectedProviders" class="label">Gültigkeit:</label></th>
                    <td>
                        <toggle-switch v-model="form.showInConnectedProviders">
                            <option :value=false>Nur für Agentur</option>
                            <option :value=true>Auch für verbundene Anbieter</option>
                        </toggle-switch>
                    </td>
                </tr>

                <tr>
                    <td colspan="2">
                        <div v-if="form.tripDetailType === 'provider_info'" class="redesign-table">
                            <div class="row is-head">
                                <div class="cell">Titel</div>
                                <div class="cell">Hinweis</div>
                            </div>
                            <div class="row is-h-auto">
                                <div class="cell is-gapless self-stretch">
                                    <div class="input-wrapper">
                                        <multiselect
                                            ref="title"
                                            v-bind:options="titles"
                                            v-bind:value="form.title"
                                            v-bind:taggable="true"
                                            v-on:tag="addTitle"
                                            v-on:close="closeTitle"
                                            v-on:search-change="title => searchTitle = title"
                                            v-on:open="$refs.title.updateSearch(form.title)"
                                            v-on:input="title => addTitle(title)"
                                        />
                                    </div>
                                </div>
                                <div class="cell is-gapless">
                                    <resizable>
                                        <textarea
                                            rows="1"
                                            class="textarea"
                                            v-model="form.content"
                                        ></textarea>
                                    </resizable>
                                </div>
                            </div>
                        </div>
                        <div v-else class="bp-panel">
                            <div class="panel-content">
                                <div class="field">
                                    <multiselect
                                        ref="title"
                                        v-bind:options="titles"
                                        v-bind:value="form.title"
                                        v-bind:taggable="true"
                                        v-on:tag="addTitle"
                                        v-on:close="closeTitle"
                                        v-on:search-change="title => searchTitle = title"
                                        v-on:open="$refs.title.updateSearch(form.title)"
                                        v-on:input="title => addTitle(title)"
                                    />
                                </div>
                                <div class="field">
                                    <tip-tap
                                        v-model="form.content"
                                        class="is-fullwidth"
                                        isNotAlone
                                        :menuItems="['fullscreen', 'bold', 'italic', 'underline', 'h1', 'h2', 'bullet_list', 'ordered_list', 'link', 'color', 'undo']"
                                    />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </modal-form>

        <image-select
            ref="imageSelect"
            :params="{'provider.id': providerID}"
            v-model="form.images"
            multiple
        />

        <image-map
            v-if="visibleImageMap"
            v-model="form.images"
            v-on:close="visibleImageMap = false"
        />
    </div>
</template>

<script>
import { ToggleSwitch, ImageUpload, ImageMap, ImageSelect, TipTap, Resizable } from '@components/form';
import { tripDetailTypes } from '@utilities/variables'
import { infoSections } from '@clientSpecific/utilities/variables';

import TabForm from '../form'

export default {
    mixins: [ TabForm ],

    form: {
        title: '',
        content: '',
        type: 'note',
        sections: [],
        showInConnectedProviders: false,
        tripDetailType: null,
        importance: 'low',
        images: []
    },

    property: 'infos',

    data: function () {
        return {
            infoSections,
            tripDetailTypes,
            searchTitle: '',
            visibleImageMap: false,
        }
    },

    computed: {
        tripDetailTypesFiltered: function() {
            if(this.hasTripDetails){
                return tripDetailTypes;
            } else {
                return {}
            }
        },

        providerID: function() {
            return this.$store.state.id
        },

        options: function () {
            const options = this.$store.state.options;
            return {
                ...options.infos,
            }
        },

        titles: function () {
            return this.options && this.options.infoTitle
                ? this.options.infoTitle
                : []
        },

        isNotDestination: function () {
            return !['destinations', 'clients'].includes(window.location.pathname.split( '/' )[1]);
        },

        hasTripDetails: function() {
            //todo: find better solution
            const api = window.location.pathname.split( '/' )[1];
            return api === 'hotels' || api === 'ferries' || api === 'others' ;
        },

        importances: function () {
            return this.options.importance ? this.options.importance : []
        },

        importance: {
            get: function () {
                return this.importances.find(importance => importance.key === this.form.importance)
            },
            set: function (value) {
                this.form.importance = !!value ? value.key : null
            }
        },
    },

    components: {
        ToggleSwitch,
        ImageUpload,
        ImageSelect,
        Resizable,
        ImageMap,
        TipTap
    },

    methods: {
        addTitle(newTitle) {
            if (newTitle !== this.form.title) {
                this.form.title = newTitle;
            }
        },

        closeTitle() {
            if (this.searchTitle !== "") {
                this.addTitle(this.searchTitle)
            }
        },

        handleImageDrop: function(image) {
            this.form.images = [...this.form.images, image]
        }
    }
}
</script>
