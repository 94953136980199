import axios           from 'axios';
import Accordion       from '@components/Accordion';
import { notifyError } from '@components/Notification';
import Loading         from '@components/Loading';
import store           from '../store';
import ListItem        from './Item';


export default {
    store: store,


    props: {
        apiQuery: { type: String,  required: true }
    },


    computed: {
        types:          function () { return this.$store.state.types; },
        emails:         function () { return this.$store.getters.emailsListByType; },
        emailsSelected: function () { return this.$store.state.emailsSelected; }
    },


    data: function () {
        return {
            areServerEmailsReady: false,
            areLiveEmailsReady: false
        };
    },


    methods: {
        getKey: function (email) {
            return email.id ? email.id : email.folder + '-' + email.uid;
        },


        emailClass: function (email) {
            const emails = this.emailsSelected[this.emailsSelected.direction];

            for (let i = 0, len = emails.length; i < len; i++) {
                if (emails[i].folder === email.folder &&
                    email.id ? emails[i].id === email.id :
                    emails[i].uid === email.uid) {
                    return 'is-selected';
                }
            }

            return '';
        },


        getServerEmails: function (extraQuery) {
            let query = '/api/emails';
            query += '?_order[date]=DESC&_groups[]=order_list_read&_groups[]=email_link_read';
            query += this.apiQuery ? ('&' + this.apiQuery) : '';
            query += extraQuery    ? ('&_search=' + extraQuery)    : '';

            axios.get(query)
                .then(emails => {
                    this.$store.dispatch('updateEmailsServer', emails.data);

                }, error => { notifyError('Konnte die emails nicht bekommen! Server error!'); })
                .then(() => { this.areServerEmailsReady = true; });
        },


        getLiveEmails: function () {
            Promise.all([
                axios.get('api/emails/live/get?folder=inbox'),
                axios.get('api/emails/live/get?folder=sent&maxItems=50')
            ])
                .then(([inbox, sent]) => {
                    this.$store.dispatch('updateEmailsLive', [...inbox.data, ...sent.data]);

                }, error => { notifyError('Konnte die emails nicht bekommen! Server error!'); })
                .then(() => { this.areLiveEmailsReady = true; });
        }
    },


    components: {
        Accordion,
        ListItem,
        Loading
    }
};
