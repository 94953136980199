import directions from './Directions';
import dayDirections from './DayDirections';
import linkedOrders from './LinkedOrders';
import linkedDayConcepts from './LinkedDayConcepts';

import procurement from "./Procurement";
import addresses from './Addresses';
import accounts from './Accounts';
import users from './Users/Users';
import accountPlan from './AccountPlan';
import bankAccounts from './BankAccounts';
import contacts from './Contacts';
import airlineRoutes from './AirlineRoutes';
import infos from './Infos';
import todos from './Todos';
import menus from './Menus';
import menus2 from './Menus2';
import clientDates from './ClientDates';
import sales from './Sales';
import conceptCatalogText from './ConceptCatalogText';


import documents from './Documents';
import itemPrices from './ItemPrices';
import entrances from './Entrances';
import images from './Images';
import serviceNonamePrices from './ProviderPrices/OtherAgency';
import hotelNonamePrices from './ProviderPrices/HotelAgency';
import ferryNonamePrices from './ProviderPrices/FerryAgency';
import trainNonamePrices from './ProviderPrices/TrainAgency';
import otherPresetPrices from './ProviderPrices/OtherCalculation';
import sellingPrices from './SellingPrices';
import hotelPrices from './ProviderPrices/Hotel';
import otherServices from './ProviderPrices/Other';
import ferryRoutes from './ProviderPrices/Ferry';
import trainRoutes from './ProviderPrices/Train';

import freeplaces from './FreePlaces';

import services from './Services';
import orders from './Orders';
import orderConcepts from './OrderConcepts';
import conditions from './Conditions';
import tripDetails from './TripDetails';
import catalogText  from './CatalogText';

import websiteExport from './WebsiteExport';
import webflow from './Webflow';
import tripDetailDocuments from './tripDetailDocuments';
import Email from './Email';

export default {
    // Columns
    addresses,
    accounts,
    bankAccounts,
    contacts,
    users,
    accountPlan,

    airlineRoutes,
    infos,
    todos,
    menus,
    menus2,
    clientDates,
    sales,
    conceptCatalogText,

    // API columns
    images,

    // Table
    sellingPrices,
    itemPrices,
    entrances,
    hotelPrices,
    ferryRoutes,
    otherServices,
    linkedOrders,
    linkedDayConcepts,

    // custom view
    conditions,
    tripDetails,
    catalogText,

    // API table
    serviceNonamePrices,
    hotelNonamePrices,
    ferryNonamePrices,
    trainNonamePrices,
    otherPresetPrices,
    freeplaces,
    documents,
    services,
    trainRoutes,
    orders,
    orderConcepts,

    //website
    websiteExport,
    webflow,
    tripDetailDocuments,

    dayDirections,
    directions,
    procurement,
    Email,
};
