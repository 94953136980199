var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bp-row-form" }, [
    _c("div", [
      _c("div", { staticClass: "bp-row-form__title" }, [
        _vm._v("Leistungskategorie und -ort"),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("i", { staticClass: "material-icons" }, [_vm._v("explore")]),
        _vm._v(" "),
        _c("div", { staticClass: "select" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.categorySelected,
                  expression: "categorySelected",
                },
              ],
              ref: "category",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.categorySelected = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c(
                "option",
                {
                  attrs: { disabled: "", selected: "" },
                  domProps: { value: null },
                },
                [_vm._v("Kategorie")]
              ),
              _vm._v(" "),
              _vm._l(_vm.categories, function (category) {
                return _c("option", { domProps: { value: category.key } }, [
                  _vm._v(_vm._s(category.value)),
                ])
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "button",
            {
              class: ["btn-single-toggle", _vm.onlyFromDay ? "" : "is-active"],
              attrs: {
                title: _vm.onlyFromDay
                  ? "Ortsangaben werden nur in der Reisedestination gesucht (klicken zum Deaktivieren)"
                  : "Alle Orte werden gesucht (zum Filtern nach Reise-Destinationen klicken)",
              },
              on: {
                click: function ($event) {
                  _vm.onlyFromDay = !_vm.onlyFromDay
                },
              },
            },
            [_c("i", { staticClass: "material-icons" }, [_vm._v("flag")])]
          ),
          _vm._v(" "),
          _c("location-select", {
            attrs: {
              value: _vm.copyPlaceholder,
              limitedDestinations: _vm.destinationsForLocation,
              isNullable: "",
              hasDestination: "",
            },
            on: {
              input: (value) => {
                _vm.copyPlaceholder.area = value.area
                _vm.copyPlaceholder.place = value.place
                _vm.copyPlaceholder.destination = value.destination
              },
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "is-flex-grow" },
      [
        _c("div", { staticClass: "bp-row-form__title" }, [
          _vm._v("Zusatzleistung"),
        ]),
        _vm._v(" "),
        _c("other-service-type-select", {
          attrs: {
            placeholderInfo: _vm.placeholderInfo,
            "show-service-day-times": true,
          },
          model: {
            value: _vm.copyPlaceholder.otherServiceType,
            callback: function ($$v) {
              _vm.$set(_vm.copyPlaceholder, "otherServiceType", $$v)
            },
            expression: "copyPlaceholder.otherServiceType",
          },
        }),
        _vm._v(" "),
        _c("div", [
          _c(
            "i",
            { staticClass: "material-icons", attrs: { title: "Treffpunkt" } },
            [_vm._v("my_location")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.copyPlaceholder.meetingPoint,
                expression: "copyPlaceholder.meetingPoint",
              },
            ],
            staticClass: "input",
            attrs: { type: "text", placeholder: "Treffpunkt" },
            domProps: { value: _vm.copyPlaceholder.meetingPoint },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.copyPlaceholder,
                  "meetingPoint",
                  $event.target.value
                )
              },
            },
          }),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "is-160 has-time" }, [
      _c("div", { staticClass: "bp-row-form__title" }, [_vm._v("Zeitangaben")]),
      _vm._v(" "),
      _c("div", [
        _c("i", { staticClass: "material-icons" }, [_vm._v("date_range")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.copyPlaceholder.durationDays,
              expression: "copyPlaceholder.durationDays",
              modifiers: { number: true },
            },
          ],
          staticClass: "input has-margin-right is-30",
          attrs: { type: "text" },
          domProps: { value: _vm.copyPlaceholder.durationDays },
          on: {
            focus: function ($event) {
              return $event.target.select()
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.copyPlaceholder,
                "durationDays",
                _vm._n($event.target.value)
              )
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _vm._v("\n            Tage\n        "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.serviceDayTimes.length > 0
            ? _c(
                "button",
                {
                  staticClass: "btn-single-toggle has-margin-right",
                  class: [_vm.onlyTime ? "" : "is-active"],
                  attrs: { title: "Uhrzeit" },
                  on: {
                    click: function ($event) {
                      _vm.onlyTime = !_vm.onlyTime
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("access_time"),
                  ]),
                ]
              )
            : _c("i", { staticClass: "material-icons" }, [
                _vm._v("access_time"),
              ]),
          _vm._v(" "),
          _vm.onlyTime
            ? [
                _c("date-time", {
                  staticClass: "has-margin-right",
                  attrs: { "time-only": "" },
                  model: {
                    value: _vm.copyPlaceholder.startTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.copyPlaceholder, "startTime", $$v)
                    },
                    expression: "copyPlaceholder.startTime",
                  },
                }),
                _vm._v(" -\n                "),
                _c("date-time", {
                  staticClass: "has-margin-left",
                  attrs: { "time-only": "" },
                  model: {
                    value: _vm.copyPlaceholder.endTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.copyPlaceholder, "endTime", $$v)
                    },
                    expression: "copyPlaceholder.endTime",
                  },
                }),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "select" },
                  [
                    _c("multiselect", {
                      attrs: {
                        options: _vm.serviceDayTimes,
                        "track-by": "id",
                        "custom-label": _vm.serviceDayTimeLabel,
                      },
                      on: { select: _vm.selectServiceTime },
                      model: {
                        value: _vm.serviceDayTimeSelected,
                        callback: function ($$v) {
                          _vm.serviceDayTimeSelected = $$v
                        },
                        expression: "serviceDayTimeSelected",
                      },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _vm.onlyTime && _vm.hasCheckInTime
        ? _c(
            "div",
            [
              _c("i", { staticClass: "material-icons" }, [_vm._v("av_timer")]),
              _vm._v(" "),
              _c("date-time", {
                staticClass: "has-margin-right",
                attrs: { "time-only": "" },
                model: {
                  value: _vm.copyPlaceholder.checkInTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.copyPlaceholder, "checkInTime", $$v)
                  },
                  expression: "copyPlaceholder.checkInTime",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", [
      _c("div", { staticClass: "bp-row-form__title" }, [_vm._v("Abrechnung")]),
      _vm._v(" "),
      _c("div", [
        _c(
          "i",
          {
            staticClass: "material-icons",
            attrs: { title: "Set as Additional" },
          },
          [_vm._v("shopping_cart")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "select" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.copyPlaceholder.clientOffer.status,
                  expression: "copyPlaceholder.clientOffer.status",
                },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.copyPlaceholder.clientOffer,
                    "status",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "included" } }, [
                _vm._v("inkludiert"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "optional" } }, [
                _vm._v("optional"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "additional" } }, [
                _vm._v("gesondert"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "atLocation" } }, [
                _vm._v("vor Ort"),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("i", { staticClass: "material-icons" }, [_vm._v("people")]),
        _vm._v(" "),
        _c("div", { staticClass: "select" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.copyPlaceholder.clientOffer.priceType,
                  expression: "copyPlaceholder.clientOffer.priceType",
                },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.copyPlaceholder.clientOffer,
                    "priceType",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "per_person" } }, [
                _vm._v("Pro Person"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "per_group" } }, [
                _vm._v("Pro Gruppe"),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "button is-close",
        attrs: { title: "Verwerfen" },
        on: {
          click: function ($event) {
            return _vm.$emit("cancel")
          },
        },
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "button is-save is-primary",
        class: { "is-loading": _vm.isLoading },
        attrs: { title: "Speichern" },
        on: { click: _vm.save },
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("check")])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }