<template>
    <div>
        <template v-if="startView === 'day' && isFirstPackage">
            <div class="row is-headline mt-5" >
                Pakete
            </div>
        </template>
        <row>
            <template slot="type">
                <i class="material-icons">folder</i>
            </template>
            <template slot="date">-</template>
            <template slot="nights">-</template>
            <template slot="name">Paket # {{treatPackage.id}} von {{name}}</template>
            <template slot="provider">{{name}}</template>
            <template slot="currency"><span class="is-currency">{{currency}}</span></template>


            <template slot="sellPrice">
                <span class="empty">-</span>
            </template>
            <template slot="profit">
                <input-price v-if="personPrice" :value="personPrice" :highlight="!!highlight.person" :value-in-order-currency="personPriceInOrderCurrency" linked/>
                <span class="empty" v-else>-</span>
            </template>
            <template slot="margin">
                <input-price v-if="singlePrice" :value="singlePrice" :highlight="!!highlight.single" :value-in-order-currency="singlePriceInOrderCurrency" linked/>
                <span class="empty" v-else>-</span>
            </template>
        </row>
        <div class="is-light">
            <row
                v-for="request in treatPackage.requests"
                v-bind:key="`request-${request.id}`"
                v-on:dblclick.native="openModal(request)"
            >
                <template slot="type">
                    <i class="material-icons">subdirectory_arrow_right</i>
                </template>
                <template slot="date">
                    {{request.placeholder.startAt | fullYear}}
                    <template v-if="request.endAt && !equalDates(request.placeholder.startAt, request.placeholder.endAt)">
                        - {{request.placeholder.endAt | fullYear}}
                    </template>
                </template>
                <template slot="nights">
                    <template v-if="requestNights(request)">
                        {{requestNights(request)}} N
                    </template>
                    <template v-else>-</template>
                </template>
                <template slot="name">{{request.serviceDescription}}</template>
                <template slot="provider">-</template>
                <template slot="currency"><span class="is-currency">{{currency}}</span></template>

                <template slot="sellPrice">
                    <span class="empty">-</span>
                </template>
                <template slot="profit">
                    <span class="empty">-</span>
                </template>
                <template slot="margin">
                    <span class="empty">-</span>
                </template>
            </row>

            <item-price
                v-for="itemPrice in itemPrices"
                v-bind:key="`itemPrice-${itemPrice.id}`"
                v-bind:price="itemPrice"
            />
        </div>
    </div>
</template>

<script>
import { fullYear, nights } from '@utilities/functions'
import store from '@calculations/store';

import Row from './Row';
import InputPrice from './InputPrice';
import ItemPrice from './ItemPrice';
import Template from "@orders/subpages/TripDetails/General/Template";

export default {
    store,

    props: {
        treatPackage: {
            type: Object,
            required: true
        },
        isFirstPackage: {
            type: Boolean
        }
    },

    components: {
        Template,
        Row,
        InputPrice,
        ItemPrice
    },

    computed: {
        startView: {
            get () {
                return this.$store.state.startView;
            },
        },

        currency: function () {
            return this.treatPackage.basePrices[0] && this.treatPackage.basePrices[0].price ? this.treatPackage.basePrices[0].price.currency : 'EUR';
        },

        highlight: function () { return this.$store.state.highlight },

        name: function () {
            return `${this.treatPackage.agency ? this.treatPackage.agency.name : this.treatPackage.provider.name}`
        },

        exchangeRate: function () {
            return this.$store.state.exchangeRates && this.$store.state.exchangeRates[this.currency] ? this.$store.state.exchangeRates[this.currency] : 0;
        },

        personPriceInOrderCurrency: function () { return this.personPrice * this.exchangeRate },
        singlePriceInOrderCurrency: function () { return this.singlePrice * this.exchangeRate},

        personPrice: function () {
            return this.treatPackage.personPrice;
        },

        singlePrice: function () {
            return this.treatPackage.singlePrice;
        },

        itemPrices: function () {
            return this.treatPackage.itemPrices
                ? this.treatPackage.itemPrices
                    .filter(itemPrice => itemPrice.inCalculation)
                : []
        },
    },

    methods: {
        openModal: function(request) {
            this.$store.commit('updateModalPlaceholder', request);
        },
        requestNights: function (request) {
            return nights(request.placeholder.startAt, request.placeholder.endAt)
        },

        equalDates: function (a, b) {
            return fullYear(a) === fullYear(b)
        }
    },

    filters: {
        fullYear
    }
}
</script>
