<template>
    <div
        class="placeholder-view"
        v-bind:class="{
            'has-requests'             : '!isConcept',
            'has-no-route-description' : !placeholder.routeDescription && isRouteView,
            'is-optional'              : placeholder.clientOffer.status === 'optional' || placeholder.clientOffer.status === 'atLocation',
            'is-additional'              : placeholder.clientOffer.status === 'additional',
        }"
    >
        <div v-if="isRouteView" v-bind:title="placeholder.routeDescription">
            <i class="material-icons" title="Hotel">home</i>

            <template v-if="placeholder.routeDescription">
                <template v-if="placeholder.clientOffer.status === 'optional'">Möglichkeit: </template>
                <template v-if="placeholder.clientOffer.status === 'atLocation'">vor Ort: </template>{{ placeholder.routeDescription }}
            </template>
            <template v-else>ohne Routenbeschreibung ({{placeholder.serviceDescription}})</template>
        </div>

        <div v-else v-bind:title="placeholder.serviceDescription">
            <i class="material-icons" title="Hotel">home</i>
            <template v-if="placeholder.clientOffer.status === 'optional'">Möglichkeit: </template>
            <template v-else-if="placeholder.clientOffer.status === 'atLocation'">vor Ort: </template>
            {{ placeholder.serviceDescription }} <template v-if="placeholder.selectedRequest">{{ placeholder.selectedRequest.info.provider.name ? '' : ' (No-Name)' }}</template>
        </div>


        <div :class="{ 'is-hidden-on-small' : !isConcept }">
            <template v-if="showDuration">
                <template v-if="isConcept">
                    {{ placeholder.nights }}
                    {{ placeholder.nights > 1 ? ' Nächte' : ' Nacht'}}
                </template>

                <template v-else>{{ placeholder.startAt | dayMonth }} - {{ placeholder.endAt | fullYear }}</template>
            </template>
        </div>


        <div class="is-60" v-if="!isConcept">{{ timeUntil(placeholder.startAt, placeholder.endAt).days }} N</div>

<!--        time slot-->
       <div class="is-100 is-hidden-on-small"></div>



        <div class="contingent-section" v-if="!isConcept">
            <template v-if="placeholder.info && placeholder.info.contingent">
                {{ placeholder.info.contingent }} Zimmer
                <i class="fa fa-warning" title="Unterdeckung" v-if="hasContingentWarning"></i>
            </template>
        </div>


        <div v-if="!isConcept">
            <deadline v-bind:value="placeholder.info.deadline"></deadline>
        </div>


        <agency-and-provider
            v-if="!isConcept"
            v-bind:agency   = "placeholder.info.agency"
            v-bind:provider = "placeholder.info.provider"
        ></agency-and-provider>

        <concept-provider
            v-if="isConcept"
            v-bind:providers="placeholder.providers"
        >
        </concept-provider>


        <div class="is-130" v-if="!isConcept">
            <template v-if="placeholder.requests && placeholder.requests.length">
                {{ getValueWithKey({ key: placeholder.info.status, optionList: statusesList }) }}
            </template>
        </div>


        <div class="action-buttons"><slot></slot></div>

        <button
            v-if         = "placeholder.requests && placeholder.requests.length > 0"
            class        = "nr-of-requests"
            title        = "Anfragen"
            v-bind:class = "['is-' + placeholder.info.urgency]"
            v-on:click.stop = "$emit('toggleRequests')"
        >{{ placeholder.requests.length }}</button>

        <div
            v-if="placeholder.sortGroup"
            class="group-icon"
            v-bind:class="'is-' + placeholder.sortGroup"
        ></div>
    </div>
</template>



<script>
import { timeUntil } from '@utilities/functions';
import mixins from './mixins';


export default {
    mixins: [mixins],

    methods: {
        timeUntil
    }
}
</script>
