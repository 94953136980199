var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is-light" },
    [
      ![0, "0.00"].includes(_vm.busBuyPrice)
        ? _c(
            "row",
            [
              _c("template", { slot: "type" }, [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("subdirectory_arrow_right"),
                ]),
              ]),
              _vm._v(" "),
              _c("template", { slot: "date" }, [_vm._v("-")]),
              _vm._v(" "),
              _c("template", { slot: "nights" }, [_vm._v("-")]),
              _vm._v(" "),
              _c("template", { slot: "name" }, [
                _vm._v("Buspreis für die Fähre"),
              ]),
              _vm._v(" "),
              _c("template", { slot: "provider" }, [_vm._v("-")]),
              _vm._v(" "),
              _c("template", { slot: "currency" }, [
                _c("span", { staticClass: "is-currency" }, [
                  _vm._v(_vm._s(_vm.placeholder.placeholderCurrency)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "template",
                { slot: "sellPrice" },
                [
                  _c("input-price", {
                    attrs: {
                      linked: _vm.hasCalculationRequest,
                      "value-in-order-currency": _vm.busBuyPriceInOrderCurrency,
                      "provider-prices": _vm.getProviderPrices(
                        this.groupPriceType
                      ),
                      "price-currency": _vm.currency,
                    },
                    model: {
                      value: _vm.busBuyPrice,
                      callback: function ($$v) {
                        _vm.busBuyPrice = $$v
                      },
                      expression: "busBuyPrice",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "profit" },
                [
                  _c("input-price", {
                    attrs: {
                      value: _vm.busPrice,
                      readOnly: "",
                      linked: _vm.hasCalculationRequest,
                      highlight: !!_vm.highlight.person,
                      "value-in-order-currency": _vm.busPriceInOrderCurrency,
                      "price-currency": _vm.currency,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "margin" },
                [
                  _vm.supplementPrices
                    ? _c("span", { staticClass: "empty" }, [_vm._v("-")])
                    : _c("input-price", {
                        attrs: {
                          value: _vm.busPrice,
                          readOnly: true,
                          linked: _vm.hasCalculationRequest,
                          highlight: !!_vm.highlight.single,
                          "value-in-order-currency":
                            _vm.busPriceInOrderCurrency,
                          "price-currency": _vm.currency,
                        },
                      }),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      ![0, "0.00"].includes(_vm.busTaxBuyPrice)
        ? _c(
            "row",
            [
              _c("template", { slot: "type" }, [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("subdirectory_arrow_right"),
                ]),
              ]),
              _vm._v(" "),
              _c("template", { slot: "date" }, [_vm._v("-")]),
              _vm._v(" "),
              _c("template", { slot: "nights" }, [_vm._v("-")]),
              _vm._v(" "),
              _c("template", { slot: "name" }, [_vm._v("Steuern - Bus")]),
              _vm._v(" "),
              _c("template", { slot: "provider" }, [_vm._v("-")]),
              _vm._v(" "),
              _c("template", { slot: "currency" }, [
                _c("span", { staticClass: "is-currency" }, [
                  _vm._v(_vm._s(_vm.placeholder.placeholderCurrency)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "template",
                { slot: "sellPrice" },
                [
                  _c("input-price", {
                    attrs: {
                      linked: _vm.hasCalculationRequest,
                      "value-in-order-currency":
                        _vm.busTaxBuyPriceInOrderCurrency,
                      "provider-prices":
                        _vm.getProviderPrices("busTaxBuyPrice"),
                      "price-currency": _vm.currency,
                    },
                    model: {
                      value: _vm.busTaxBuyPrice,
                      callback: function ($$v) {
                        _vm.busTaxBuyPrice = $$v
                      },
                      expression: "busTaxBuyPrice",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "profit" },
                [
                  _c("input-price", {
                    attrs: {
                      value: _vm.busTaxPrice,
                      readOnly: "",
                      linked: _vm.hasCalculationRequest,
                      highlight: !!_vm.highlight.person,
                      "value-in-order-currency": _vm.busTaxPriceInOrderCurrency,
                      "price-currency": _vm.currency,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "margin" },
                [
                  _vm.supplementPrices
                    ? _c("span", { staticClass: "empty" }, [_vm._v("-")])
                    : _c("input-price", {
                        attrs: {
                          value: _vm.busTaxPrice,
                          readOnly: true,
                          linked: _vm.hasCalculationRequest,
                          highlight: !!_vm.highlight.single,
                          "value-in-order-currency":
                            _vm.busTaxPriceInOrderCurrency,
                          "price-currency": _vm.currency,
                        },
                      }),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.price.placeholder.type === "night" &&
      ![0, "0.00"].includes(_vm.dayTicketBuyPrice)
        ? _c(
            "row",
            [
              _c("template", { slot: "type" }, [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("subdirectory_arrow_right"),
                ]),
              ]),
              _vm._v(" "),
              _c("template", { slot: "date" }, [_vm._v("-")]),
              _vm._v(" "),
              _c("template", { slot: "nights" }, [_vm._v("-")]),
              _vm._v(" "),
              _c("template", { slot: "name" }, [_vm._v("Überfahrt")]),
              _vm._v(" "),
              _c("template", { slot: "provider" }, [_vm._v("-")]),
              _vm._v(" "),
              _c("template", { slot: "currency" }, [
                _c("span", { staticClass: "is-currency" }, [
                  _vm._v(_vm._s(_vm.placeholder.placeholderCurrency)),
                ]),
              ]),
              _vm._v(" "),
              _c("template", { slot: "sellPrice" }, [
                _c("span", { staticClass: "empty" }, [_vm._v("-")]),
              ]),
              _vm._v(" "),
              _c(
                "template",
                { slot: "profit" },
                [
                  _c("input-price", {
                    attrs: {
                      "provider-prices":
                        _vm.getProviderPrices("dayTicketBuyPrice"),
                      readOnly: "",
                      linked: _vm.hasCalculationRequest,
                      highlight: !!_vm.highlight.person,
                      "value-in-order-currency":
                        _vm.dayTicketBuyPriceInOrderCurrency,
                      "price-currency": _vm.currency,
                    },
                    model: {
                      value: _vm.dayTicketBuyPrice,
                      callback: function ($$v) {
                        _vm.dayTicketBuyPrice = $$v
                      },
                      expression: "dayTicketBuyPrice",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "margin" },
                [
                  _vm.supplementPrices
                    ? _c("span", { staticClass: "empty" }, [_vm._v("-")])
                    : _c("input-price", {
                        attrs: {
                          value: _vm.dayTicketBuyPrice,
                          readOnly: true,
                          linked: _vm.hasCalculationRequest,
                          highlight: !!_vm.highlight.single,
                          "provider-prices":
                            _vm.getProviderPrices("dayTicketBuyPrice"),
                          "value-in-order-currency":
                            _vm.dayTicketBuyPriceInOrderCurrency,
                          "price-currency": _vm.currency,
                        },
                      }),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      ![0, "0.00"].includes(_vm.dayTicketTaxBuyPrice)
        ? _c(
            "row",
            [
              _c("template", { slot: "type" }, [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("subdirectory_arrow_right"),
                ]),
              ]),
              _vm._v(" "),
              _c("template", { slot: "date" }, [_vm._v("-")]),
              _vm._v(" "),
              _c("template", { slot: "nights" }, [_vm._v("-")]),
              _vm._v(" "),
              _c("template", { slot: "name" }, [_vm._v("Steuern - Überfahrt")]),
              _vm._v(" "),
              _c("template", { slot: "provider" }, [_vm._v("-")]),
              _vm._v(" "),
              _c("template", { slot: "currency" }, [
                _c("span", { staticClass: "is-currency" }, [
                  _vm._v(_vm._s(_vm.placeholder.placeholderCurrency)),
                ]),
              ]),
              _vm._v(" "),
              _c("template", { slot: "sellPrice" }, [
                _c("span", { staticClass: "empty" }, [_vm._v("-")]),
              ]),
              _vm._v(" "),
              _c(
                "template",
                { slot: "profit" },
                [
                  _c("input-price", {
                    attrs: {
                      value: _vm.dayTicketTaxBuyPrice,
                      linked: _vm.hasCalculationRequest,
                      highlight: !!_vm.highlight.person,
                      "provider-prices": _vm.getProviderPrices(
                        "dayTicketTaxBuyPrice"
                      ),
                      "value-in-order-currency":
                        _vm.dayTicketTaxBuyPriceInOrderCurrency,
                      "price-currency": _vm.currency,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "margin" },
                [
                  _vm.supplementPrices
                    ? _c("span", { staticClass: "empty" }, [_vm._v("-")])
                    : _c("input-price", {
                        attrs: {
                          value: _vm.dayTicketTaxBuyPrice,
                          readOnly: true,
                          linked: _vm.hasCalculationRequest,
                          highlight: !!_vm.highlight.single,
                          "provider-prices": _vm.getProviderPrices(
                            "dayTicketTaxBuyPrice"
                          ),
                          "value-in-order-currency":
                            _vm.dayTicketTaxBuyPriceInOrderCurrency,
                          "price-currency": _vm.currency,
                        },
                      }),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }