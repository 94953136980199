import axios from 'axios';

export default {
    data: function () {
        return {
            cancelSource: null,
            oldIdentifier: ''

        };
    },

    methods: {
        cancelSearch : function (identifier) {
            if (this && this.cancelSource && identifier === this.oldIdentifier) {
                this.cancelSource.cancel('Axios canceled');
            }
            this.oldIdentifier = identifier;

        },
        callCancelRequest : function (identifier) {
            this.cancelSearch(identifier);
            this.cancelSource = axios.CancelToken.source();
        }
    },
    };
