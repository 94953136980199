<!--
    Used in orders/{id}/emails
-->

<template>
    <aside class="emails-list is-simple">
        <div class="emails-list_items" :class="isConcept ? 'is-concept' : ''">
            <header class="level">
                <div class="level-left">
                    <h2>Emails</h2>
                    <p class="text-muted">Doppelklick um Email in Outlook zu öffnen</p>
                </div>
            </header>

            <div class="new-email-button" v-if="!isConcept">
                <button
                    class="button is-success is-large"
                    v-on:click="openEmailForm"
                ><i aria-hidden="true" class="fa fa-envelope"></i> Neue Email</button>
                <button
                    class="button is-success is-large"
                    v-on:click="$emit('addPhoneCall', {preselectedContact: contact})"
                ><i aria-hidden="true" class="fa fa-phone"></i> Neues Telefonprotokoll</button>
            </div>
            <div class="emails-list-filters level">
                <toggle-switch :value="type" v-on:input="changeType" btn-class="is-larger">
                    <option value="person">
                        <i class="material-icons" title="Emails mit Kunden">person</i>
                    </option>
                    <option value="hotel">
                        <i class="material-icons" title="Emails mit Hotels">hotel</i>
                    </option>
                    <option value="other">
                        <i class="material-icons" title="Emails mit Leistungsträgern (ohne Hotels)">more_horiz</i>
                    </option>
                    <option value="agency">
                        <i class="material-icons" title="Emails mit Agenturen">face</i>
                    </option>
                </toggle-switch>
                <button
                    class="button is-larger"
                    :class="[{'is-primary' : isActive}]"
                    style="left: 5%;"
                    @click="toggleButton">
                    <i class="material-icons" style="padding: 0; margin:0" title="Gesamten Inhalt der Emails durchsuchen">zoom_in</i>
                </button>

                        <input
                            style="margin-left: 21px;"
                            class       = "input is_search"
                            v-model     = "search"
                            v-on:input  = "getEmails"
                            type        = "text"
                            placeholder = "Suche Emails"
                        />

            </div>

        </div>

        <div
            v-if="dataReady"
            class="list"
        >
            <div class="list-scrollable">
                <template v-if="emails.length > 0">
                    <ListItem
                        v-for="email in emails"
                        v-bind:key="email.id"

                        v-bind:email="email"
                        v-bind:classNames="emailClass(email)"

                        v-on:click.native="event => $store.commit('updateEmailsSelected', { event, email })"
                        v-on:dblclick.native="handleEmailExport(email.id)"
                    ></ListItem>
                    <div class="is-empty">
                        <pagination v-model="pagination" v-on:input="getEmails" :simple="true"/>
                    </div>
                </template>

                <div v-else class="is-empty">Keine Emails vorhanden</div>

            </div>
        </div>


        <loading v-else></loading>

        <new-email
            v-if = "isModalVisible"
            v-bind:isModalVisible   = "isModalVisible"
            v-bind:preselected-order = "order"
            v-bind:preselected-contact = "contact"
            v-on:closeModal = "closeModal"
        ></new-email>

    </aside>
</template>




<script>
import axios        from 'axios';
import { saveAs }   from 'file-saver';
import { debounce } from 'lodash';
import Loading             from '@components/Loading';
import {extractFilename} from '@utilities/functions'
import { exportEmail }     from '@api';
import { notifyError, notifySuccess } from '@components/Notification';
import ListItem            from './Item';
import store               from '../store';
import NewEmail            from '../modals/newEmail/index';
import { ToggleSwitch } from "@components/form";
import Pagination from '@components/CRUD/Pagination';


export default {
    store: store,


    props: {
        apiQuery: { type: String,  required: true },
        order:    { type: Object,  required: true },
        isConcept: {type: Boolean, default: false},
    },


    computed: {
        type: function () { return this.selectedItem },
        emailsSelected: function () { return this.$store.state.emailsSelected; },
        updateFolder:   function () { return this.$store.state.updateFolder; },
        contact:        function () { return !this.isConcept && this.order.orderContacts.length > 0 ? this.order.orderContacts[0].contact : null },
    },


    data: function () {
        return {
            emails: [],
            dataReady: false,
           // itemsPerPage: 100,
            pagination: {
                current: 1,
                perPage: 25,
                items: 0
            },

            selectedItem: null,
            search: '',
            daysAgo: 7,
            isModalVisible: false,
            isConceptEmailModalVisible: false,
            isActive: false,
        };
    },
    methods: {
        openEmailForm () {
            if(this.isConcept) {
                this.isConceptEmailModalVisible = true
            } else {
                this.isModalVisible = true
            }
        },

        changeType: function (type) {
            this.selectedItem = type;
            this.getEmails();
        },
        toggleButton() {
            this.isActive = !this.isActive;
            this.getEmails();

        },
        getEmails: debounce(function () {
            let query = '/api/emails';

            query += '?_order[date]=DESC&_groups[]=order_list_read&_groups[]=email_link_read&_itemsPerPage=' + this.pagination.perPage +'&_page=' +this.pagination.current;
            query += this.apiQuery ? ('&' + this.apiQuery) : '';

            if(this.isActive === true) {
                query += this.search    ? ('&_extended=' + this.search)    : '';
            }
            if (this.isActive === false) {
                query += this.search    ? ('&_search=' + this.search)    : '';
            }
            if(this.selectedItem === 'hotel') {
                    query += '&contact.provider[discriminator]=hotel';
            }
            if(this.selectedItem === 'person') {
                query += '&contact.client[exists]=true';
            }
            if(this.selectedItem === 'other') {
                query += '&contact.provider[discriminator][]=other&contact.provider[discriminator][]=airline&contact.provider[discriminator][]=ferry';
            }
            if(this.selectedItem === 'agency') {
                query += '&contact.agency[exists]=true';
            }

            this.dataReady = false

            axios.get(query)
                .then(emails => {
                    this.emails = emails.data;
                    this.pagination = {
                        ...this.pagination,
                        items: Number(emails.headers['x-trav-total-items']),
                    };
                    if (emails.data.length > 0) {
                        this.$store.commit('updateEmailsSelected', { event: null, email: emails.data[0] })
                    }

                }, error => { notifyError('Konnte die emails nicht bekommen! Server error!'); })
                .then(() => {
                    this.dataReady = true;
                    this.$store.commit('updateFolder', false);
                });
        }, 900),
        emailClass: function (email) {
            const emails = this.emailsSelected[this.emailsSelected.direction];

            for (let i = 0, len = emails.length; i < len; i++) {
                if (emails[i].id === email.id) {
                    return 'is-selected';
                }
            }

            return '';
        },

        handleEmailExport: function(id) {
            this.$store.commit('updateLoading', true)

            exportEmail(id)
                .then(response => {
                    notifySuccess('Das Email wurde generiert!');
                    const blob = new Blob([response.data], {type: response.headers['content-type']});
                    const filename = extractFilename(response.headers)
                    saveAs(blob, filename)
                }, error => {
                    notifyError('Couldn\'t generate the Email! Server Error!');
                })
                .then(() => {
                    this.$store.commit('updateLoading', false)
                })
        },

        closeModal: function () {
            this.$store.commit('updateAction', null);
            this.isModalVisible = false;
            this.isConceptEmailModalVisible = false;
        },
    },


    created: function () {
        this.getEmails();
    },


    components: {
        ToggleSwitch,
        Loading,
        ListItem,
        NewEmail,
        Pagination
    }
}


</script>
<style>
.level:not(:last-child) {
    margin-bottom: 0;
}
</style>
