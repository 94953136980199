var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "min-height": "60vh" } },
    [
      _vm.isLoading
        ? _c("loading")
        : _vm._l(_vm.rows, function (row) {
            return _c(
              "div",
              { staticClass: "columns" },
              _vm._l(row.cols, function (col) {
                return _c(
                  "div",
                  {
                    staticClass: "column",
                    class: col.columnClass,
                    style: col.columnStyle,
                  },
                  [
                    _c("div", { staticClass: "level is-headline" }, [
                      _c("div", { staticClass: "level-left" }, [
                        _c("h2", { staticClass: "level-item mb-0" }, [
                          _vm._v(_vm._s(col.label)),
                        ]),
                        _vm._v(" "),
                        row.addAction
                          ? _c(
                              "button",
                              {
                                staticClass: "level-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.buttonAction({
                                      action: row.addAction,
                                      element: _vm.element,
                                    })
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("add_circle"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.resetAction
                          ? _c(
                              "button",
                              {
                                staticClass: "level-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.buttonAction({
                                      action: row.resetAction,
                                      element: _vm.element,
                                    })
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("refresh"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        col.component.ref &&
                        _vm.componentIsLoading[col.component.ref]
                          ? _c("loading")
                          : _c(col.component.type, {
                              ref: col.component.ref,
                              refInFor: true,
                              tag: "component",
                              attrs: {
                                component: col.component,
                                element: _vm.element,
                              },
                              on: {
                                saveItem: _vm.saveItem,
                                saveItemDebounce: _vm.saveItemDebounce,
                                updateData: _vm.updateData,
                                buttonAction: _vm.buttonAction,
                              },
                            }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }