var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is-fullwidth" },
    [
      _vm._l(_vm.documents, function (document) {
        return [
          _c("div", [
            _c(
              "a",
              {
                staticClass: "has-text-centered",
                staticStyle: { width: "100%" },
                attrs: {
                  title: "Herunterladen",
                  href: _vm.downloadDocument(document),
                  download: "",
                  target: "_blank",
                },
              },
              [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("attach_file"),
                ]),
              ]
            ),
          ]),
        ]
      }),
      _vm._v(" "),
      _c("loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        staticClass: "in-row is-small",
        staticStyle: {
          height: "30px",
          margin: "0 0 0 5px",
          padding: "0",
          width: "unset",
        },
        attrs: { "text-in-front": "", "text-after": "" },
      }),
      _vm._v(" "),
      [
        _c(
          "vue-dropzone",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isLoading,
                expression: "!isLoading",
              },
            ],
            ref: "dropzone",
            attrs: {
              id: _vm.toString(_vm.docId),
              "resize-height": 500,
              options: _vm.dropzoneOptions,
              useCustomSlot: "",
            },
            on: {
              "vdropzone-sending": _vm.sendingEvent,
              "vdropzone-success": _vm.fileSuccess,
              "vdropzone-complete": _vm.fileCompleted,
              "vdropzone-files-added": _vm.filesAdded,
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLoading,
                    expression: "!isLoading",
                  },
                ],
                staticClass: "dz-dropzone is-in-row",
                attrs: { id: "documentDropZone" },
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons mr-0",
                    staticStyle: { "font-size": "14px" },
                  },
                  [_vm._v("upload")]
                ),
              ]
            ),
          ]
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }