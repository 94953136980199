var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vue-dropzone",
        {
          ref: "dropzone",
          attrs: {
            id: "dropzone",
            options: _vm.dropzoneOptions,
            useCustomSlot: "",
          },
          on: {
            "vdropzone-sending": _vm.sendingEvent,
            "vdropzone-success": _vm.fileSuccess,
            "vdropzone-complete": _vm.fileCompleted,
            "vdropzone-files-added": _vm.filesAdded,
          },
        },
        [
          _c(
            "div",
            { staticClass: "columns has-margin-top" },
            [
              _vm.isLoading ? _c("loading") : _vm._e(),
              _vm._v(" "),
              !_vm.isLoading
                ? _c("gallery", {
                    ref: "gallery",
                    attrs: {
                      "folder-images": _vm.folderImages,
                      "enable-google-maps": false,
                      "add-title": _vm.component.props.addTitle,
                      "enable-zoom": _vm.component.props.enableZoom,
                      "column-classes": _vm.component.props.columnClasses,
                      "column-style": _vm.component.props.columnStyle,
                      "multi-images": _vm.component.props.multiImages,
                      "has-label": false,
                      "is-cover-image": _vm.component.props.isCoverImage,
                      "dropzone-id": _vm.component.props.dropzoneID,
                      maxFileSize: _vm.component.props.maxFileSize,
                    },
                    on: {
                      openForm: _vm.openForm,
                      deleteImage: _vm.deleteImage,
                      zoom: _vm.zoom,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "dz-area" }, [
                _c("span", [_vm._v("Zum Hochladen Bild hier hineinziehen")]),
              ]),
              _vm._v(" "),
              _c("image-form", {
                ref: "form",
                attrs: { "show-type": !this.component.props.filterParams.type },
                on: { submit: _vm.handleImageSubmit },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.imageEditorVisible
        ? [
            _c(
              "modal-form",
              {
                ref: "form",
                staticClass: "is-large is-gray is-block",
                attrs: {
                  createTitle: "Ausschnitt wählen",
                  "has-second-submit-button": true,
                  "submit-button": "Nur Ausschnitt speichern",
                  "second-submit-button": "Originalbild & Ausschnitt speichern",
                },
                on: {
                  closeModal: function ($event) {
                    _vm.imageEditorVisible = false
                  },
                  submit: function ($event) {
                    return _vm.upload(false)
                  },
                  submit2: function ($event) {
                    return _vm.upload(true)
                  },
                },
              },
              [
                _vm.image
                  ? _c("image-cropper", {
                      ref: "imageCropper",
                      attrs: { component: _vm.component, image: _vm.image },
                      on: {
                        startUpload: _vm.startUpload,
                        uploaded: _vm.uploaded,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }