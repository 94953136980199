var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    { staticClass: "emails-list" },
    [
      _c(
        "div",
        { staticClass: "emails-list_categories" },
        [
          _c(
            "div",
            { staticClass: "organisation-select" },
            [
              _c("email-select", {
                on: { input: _vm.changeMailbox },
                model: {
                  value: _vm.emailSelected,
                  callback: function ($$v) {
                    _vm.emailSelected = $$v
                  },
                  expression: "emailSelected",
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button is-larger",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.getFolder(true)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons" }, [_vm._v("loop")]),
                  _vm._v(" Aktualisieren\n            "),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "new-email-button" }, [
            _c(
              "button",
              {
                staticClass: "button is-success is-large",
                on: {
                  click: function ($event) {
                    _vm.isModalVisible = true
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fa fa-plus-circle",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Neue Email"),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.dataReady
            ? _c(
                "div",
                { staticClass: "content" },
                _vm._l(_vm.folders, function (folderObject, folderName) {
                  return _c("folder-button", {
                    key: folderName,
                    staticClass: "is-0",
                    attrs: {
                      folderName: folderName,
                      folder: _vm.getFolderByPath(folderObject.folder),
                      folderSelected: _vm.folderSelected,
                    },
                    on: { selectFolder: (value) => _vm.selectFolder(value) },
                  })
                }),
                1
              )
            : _c("loading", { attrs: { "is-vertical": "" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "emails-list_items" }, [
        _c("header", [
          _c("h2", [_vm._v("Emails")]),
          _vm._v(" "),
          _vm.list ? _c("span", [_vm._v(_vm._s(_vm.list.length))]) : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "emails-list-filters" }, [
          _c("i", {
            staticClass: "fa fa-filter",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "select" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.daysAgo,
                    expression: "daysAgo",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.daysAgo = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "7" } }, [_vm._v("7 Tage")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "14" } }, [_vm._v("14 Tage")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "30" } }, [_vm._v("30 Tage")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "90" } }, [_vm._v("90 Tage")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "-1" } }, [
                  _vm._v("ohne Limit"),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _vm.isLoading ? _c("loading") : _vm._e(),
            _vm._v(" "),
            _vm.list
              ? _vm._l(_vm.list, function (email) {
                  return _c("ListItem", {
                    key: email.uid,
                    attrs: { email: email, classNames: _vm.emailClass(email) },
                    nativeOn: {
                      click: function ($event) {
                        return ((event) =>
                          _vm.$store.commit("updateEmailsSelected", {
                            event,
                            email,
                          })).apply(null, arguments)
                      },
                    },
                  })
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.list !== null && _vm.list.length === 0
              ? _c("div", { staticClass: "is-empty" }, [
                  _vm._v("In diesem Ordner befinden sich keine E-Mails."),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.isModalVisible
        ? _c("new-email", {
            attrs: {
              isModalVisible: _vm.isModalVisible,
              "preselected-order": _vm.preselectedOrder,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }