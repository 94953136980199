var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.concepts.length > 0
    ? _c("div", { staticClass: "search-day-concept" }, [
        _c("div", { staticClass: "column is-one-third" }, [
          _c("div", { staticClass: "panel" }, [
            _c("div", { staticClass: "panel-heading" }, [
              _vm._v("Suchergebnisse: Bestehende Tageskonzepte"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "panel-block is-spaceless" }, [
              _c(
                "div",
                { staticClass: "day-concepts" },
                _vm._l(_vm.uniqConcepts, function (concept) {
                  return _c(
                    "div",
                    {
                      key: concept.id,
                      class: { "is-selected": _vm.conceptSelected === concept },
                      on: {
                        click: function ($event) {
                          return _vm.handleConceptSelect(concept)
                        },
                      },
                    },
                    [
                      concept.category
                        ? _c("div", { staticClass: "tag" }, [
                            _c("span", { staticClass: "is-truncated" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.conceptCategories.filter(
                                    (x) => x.key === concept.category
                                  )[0].value
                                )
                              ),
                            ]),
                          ])
                        : _c("div", { staticClass: "tag is-placeholder" }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: concept.name,
                                delay: { show: 1000 },
                              },
                              expression:
                                "{content: concept.name, delay: {show: 1000}}",
                            },
                          ],
                          staticClass: "is-truncated",
                        },
                        [_vm._v(_vm._s(concept.name))]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "column is-one-third" }, [
          _c("div", { staticClass: "panel" }, [
            _c("div", { staticClass: "panel-heading" }, [
              _vm._v("Varaianten des tageskonzepts"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "panel-block is-spaceless" }, [
              _vm.conceptSelected
                ? _c(
                    "div",
                    { staticClass: "day-concepts" },
                    _vm._l(_vm.conceptVariations, function (concept) {
                      return _c(
                        "div",
                        {
                          key: concept.id,
                          staticClass: "is-variation",
                          class: {
                            "is-selected": _vm.variationSelected === concept,
                          },
                          on: {
                            click: function ($event) {
                              _vm.variationSelected = concept
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "index" }, [
                            _vm._v(_vm._s(concept.days.length)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "level-left is-truncated" },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    concept.subtitle
                                      ? concept.subtitle
                                      : "Standard (ohne Zusatztitel)"
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "column is-one-third" }, [
          _c("div", { staticClass: "panel" }, [
            _c("div", { staticClass: "panel-heading" }, [
              _c("span", [_vm._v("Vorschau")]),
              _vm._v(" "),
              !_vm.hasVariationSlot
                ? _c("div", { staticClass: "buttons has-addons" }, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: "Leistungen",
                              delay: { show: 1000 },
                            },
                            expression:
                              "{content: 'Leistungen', delay: {show: 1000}}",
                          },
                        ],
                        staticClass: "button is-icon",
                        class: {
                          "is-primary":
                            this.descriptionType === "serviceDescription",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            _vm.descriptionType = "serviceDescription"
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("assignment"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: { content: "Route", delay: { show: 1000 } },
                            expression:
                              "{content: 'Route', delay: {show: 1000}}",
                          },
                        ],
                        staticClass: "button is-icon",
                        class: {
                          "is-primary":
                            this.descriptionType === "routeDescription",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            _vm.descriptionType = "routeDescription"
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("place"),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "panel-block is-spaceless" },
              [
                _vm._t(
                  "variation",
                  function () {
                    return [
                      _vm.variationSelected
                        ? _c(
                            "div",
                            { staticClass: "day-concepts" },
                            _vm._l(
                              _vm.sortedPlaceholders,
                              function (placeholder) {
                                return placeholder.placeholderType !==
                                  "route" ||
                                  _vm.descriptionType === "routeDescription"
                                  ? _c(
                                      "div",
                                      {
                                        key:
                                          placeholder.placeholderType +
                                          "-" +
                                          placeholder.id,
                                        staticClass: "is-placeholder",
                                        class: {
                                          "is-optional":
                                            _vm.isOptional(placeholder) ||
                                            _vm.isAtLocation(placeholder),
                                          "is-additional":
                                            _vm.isAdditional(placeholder),
                                          "has-more-info":
                                            placeholder.placeholderType ===
                                            "route",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "level-left is-truncated",
                                          },
                                          [
                                            placeholder.placeholderType ===
                                            "ferry"
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons",
                                                  },
                                                  [_vm._v("directions_boat")]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            placeholder.placeholderType ===
                                            "train"
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons",
                                                  },
                                                  [_vm._v("directions_train")]
                                                )
                                              : placeholder.placeholderType ===
                                                "airline"
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "airplanemode_active"
                                                    ),
                                                  ]
                                                )
                                              : placeholder.placeholderType ===
                                                "other"
                                              ? _c("other-service-icons", {
                                                  attrs: {
                                                    type: placeholder
                                                      .otherServiceType
                                                      .category,
                                                  },
                                                })
                                              : placeholder.placeholderType ===
                                                "hotel"
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons",
                                                  },
                                                  [_vm._v("home")]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            placeholder.placeholderType ===
                                            "route"
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons",
                                                  },
                                                  [_vm._v("filter_hdr")]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            placeholder.placeholderType ===
                                            "free"
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons",
                                                  },
                                                  [_vm._v("view_headline")]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: {
                                                      content:
                                                        placeholder[
                                                          _vm.descriptionType
                                                        ],
                                                      delay: { show: 1000 },
                                                    },
                                                    expression:
                                                      "{content: placeholder[descriptionType], delay: {show: 1000}}",
                                                  },
                                                ],
                                                staticClass: "is-truncated",
                                                class: {
                                                  "is-optional":
                                                    _vm.isOptional(
                                                      placeholder
                                                    ) ||
                                                    _vm.isAtLocation(
                                                      placeholder
                                                    ),
                                                  "is-additional":
                                                    _vm.isAdditional(
                                                      placeholder
                                                    ),
                                                },
                                              },
                                              [
                                                _vm.isOptional(placeholder)
                                                  ? _c("span", [
                                                      _vm._v("Möglichkeit:"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.isAtLocation(placeholder)
                                                  ? _c("span", [
                                                      _vm._v("vor Ort:"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      placeholder[
                                                        _vm.descriptionType
                                                      ]
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.editablePlaceholders
                                          ? [
                                              placeholder.placeholderType ===
                                              "hotel"
                                                ? _c(
                                                    "div",
                                                    { staticClass: "form" },
                                                    [
                                                      _c("input", {
                                                        staticClass:
                                                          "input is-tiny",
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          value:
                                                            placeholder.nights,
                                                        },
                                                        on: {
                                                          input: (e) => {
                                                            _vm.updatePlaceholder(
                                                              placeholder,
                                                              e.target.value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                                        Nächte\n                                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              placeholder.placeholderType ===
                                              "other"
                                                ? _c(
                                                    "div",
                                                    { staticClass: "form" },
                                                    [
                                                      _c("input", {
                                                        staticClass:
                                                          "input is-tiny",
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          value:
                                                            placeholder.durationDays,
                                                        },
                                                        on: {
                                                          input: (e) => {
                                                            _vm.updatePlaceholder(
                                                              placeholder,
                                                              e.target.value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                                        Tage\n                                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]
                  },
                  { concept: _vm.variationSelected }
                ),
              ],
              2
            ),
          ]),
        ]),
      ])
    : _c("div", { staticClass: "is-empty" }, [_vm._v(_vm._s(_vm.emptyText))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }