var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "has-margin-top" }, [
    _c(
      "div",
      {
        staticClass: "bp-panel has-no-title",
        staticStyle: { "min-height": "auto" },
      },
      [
        _c(
          "div",
          { staticClass: "panel-content" },
          _vm._l(_vm.objects, function (object, index) {
            return _c("div", [
              _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "control" }, [
                  _c("div", { staticClass: "level" }, [
                    _c("div", { staticClass: "level-left" }, [
                      _c(
                        "label",
                        {
                          staticClass: "checkbox",
                          staticStyle: {
                            "white-space": "nowrap",
                            overflow: "hidden",
                            width: "100%",
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "is-link",
                              attrs: {
                                target: "_blank",
                                href: _vm.getLink(object),
                              },
                            },
                            [_vm._v(_vm._s(_vm.getLabel(object, index + 1)))]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "level-right" },
                      [
                        _vm.isHotel
                          ? _c(
                              "toggle-switch",
                              {
                                attrs: {
                                  value: _vm.getHotelStatus(object),
                                  btnStyle: "padding:5px",
                                },
                                on: {
                                  input: (value) =>
                                    _vm.setHotelStatus(value, object),
                                },
                              },
                              [
                                _c("option", { attrs: { value: "disabled" } }, [
                                  _c(
                                    "i",
                                    {
                                      staticClass: "material-icons",
                                      staticStyle: { "font-size": "14px" },
                                      attrs: {
                                        title: "nicht auf Homepage anzeigen",
                                      },
                                    },
                                    [_vm._v("visibility_off")]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "example" } }, [
                                  _c(
                                    "i",
                                    {
                                      staticClass: "material-icons",
                                      staticStyle: { "font-size": "14px" },
                                      attrs: {
                                        title: "als Beispiel für Homepage",
                                      },
                                    },
                                    [_vm._v("location_off")]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "selected" } }, [
                                  _c(
                                    "i",
                                    {
                                      staticClass: "material-icons",
                                      staticStyle: { "font-size": "14px" },
                                      attrs: {
                                        title:
                                          "festgelegtes Hotel für Homepage",
                                      },
                                    },
                                    [_vm._v("location_on")]
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "control" }),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "is-small-text" },
                  [
                    _vm.isHotel && !object.selected
                      ? _c("span", { staticClass: "is-text-red" }, [
                          _vm._v(
                            "\n                            nicht für Homepage ausgewählt\n                        "
                          ),
                        ])
                      : _vm._l(
                          _vm.component.props.checkItems,
                          function (checkItem, i) {
                            return [
                              i > 0 ? _c("span", [_vm._v(" | ")]) : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                { class: _vm.getClass(object, checkItem) },
                                [_vm._v(_vm._s(checkItem))]
                              ),
                              _vm._v(" "),
                              _vm.getItem(object, checkItem) &&
                              _vm.getItem(object, checkItem).doneDate
                                ? _c(
                                    "i",
                                    {
                                      staticClass: "material-icons",
                                      class: _vm.getClass(object, checkItem),
                                      staticStyle: { "font-size": "10px" },
                                    },
                                    [_vm._v("check")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.getItem(object, checkItem) ||
                              !_vm.getItem(object, checkItem).doneDate
                                ? _c(
                                    "i",
                                    {
                                      staticClass: "material-icons",
                                      class: _vm.getClass(object, checkItem),
                                      staticStyle: { "font-size": "10px" },
                                    },
                                    [_vm._v("close")]
                                  )
                                : _vm._e(),
                            ]
                          }
                        ),
                  ],
                  2
                ),
              ]),
            ])
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }