import { getValueWithKey, dayMonth, fullYear } from '@utilities/functions';
import Deadline  from '@components/Deadline';
import { store } from '@orders/store';
import TimeOfDay from './TimeOfDay';
import AgencyAndProvider from './modules/AgencyAndProvider';
import ConceptProvider from "@orders/placeholders/components/views/modules/ConceptProvider";



export default {
    store: store,


    props: {
        placeholder: { type: Object,  required: true },
        isRouteView: { type: Boolean},
        isConcept:   { type: Boolean, default: false },
        showDuration:  { type: Boolean, default: true }
    },


    computed: {
        totalPersonsAsked:    function () { return this.$store.state.hotelContingent.info.personsAsked; },
        drivers () {
            return this.$store.state.hotelContingent.info.drivers;
        },
        hasContingentWarning: function () {
            let clientContingent = this.totalPersonsAsked;
            if(this.placeholder.driverNotParticipating) {
                clientContingent -= this.drivers;
            }
            return this.placeholder.info.personsReserved < clientContingent;
        },
        statusesList:         function () { return this.$store.state.options.requestStatuses; }
    },


    methods: {
        getValueWithKey,
        fullYear
    },


    filters: {
        dayMonth,
        fullYear
    },


    components: {
        AgencyAndProvider,
        Deadline,
        TimeOfDay,
        ConceptProvider
    }
};
