var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "crud-table",
        {
          ref: "table",
          attrs: {
            name: { singular: "Aufgabe", plural: "Aufgaben" },
            columns: [
              {
                key: "name",
                filter: "order.id",
                label: "Aufgabe",
                class: "is-larger-cell",
              },
              {
                key: "daysBeforeStartAt",
                filter: "invoiceNumber",
                label: "Deadline",
                class: "is-larger-cell",
              },
              {
                key: "validForRequestStatuses",
                label: "Gültig für",
                sortable: false,
              },
            ],
            api: "todo_templates",
            filter: {
              ...(_vm.api === "agencies" && { "agency.id": _vm.item.id }),
              ...(_vm.api !== "agencies" && { "provider.id": _vm.item.id }),
              "order[exists]": false,
            },
            order: { "_order[daysBeforeStartAt]": "ASC" },
          },
          on: {
            loaded: _vm.saveOptions,
            dataLoaded: _vm.store,
            add: _vm.openForm,
            edit: _vm.editForm,
            delete: _vm.removeItem,
          },
          scopedSlots: _vm._u([
            {
              key: "validForRequestStatuses",
              fn: function (slotProps) {
                return _vm._l(
                  slotProps.row.validForRequestStatuses,
                  function (tag) {
                    return _c(
                      "span",
                      { staticClass: "tag is-white px-1 mr-1" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.getTag(tag)) +
                            "\n            "
                        ),
                      ]
                    )
                  }
                )
              },
            },
            {
              key: "daysBeforeStartAt",
              fn: function (slotProps) {
                return [
                  _vm._v(
                    "\n            " +
                      _vm._s(slotProps.row.daysBeforeStartAt) +
                      " Tage vor Leistungsbeginn\n        "
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h2", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Automatische Aufgaben für Reisen"),
          ]),
          _vm._v(" "),
          _c("todo-form", {
            ref: "form",
            attrs: { slot: "form" },
            on: {
              success: function ($event) {
                return _vm.$refs.table.refresh()
              },
            },
            slot: "form",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }