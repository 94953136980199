import {fullYear, getPlaceholderType} from "@utilities/functions";
import {edit} from "@api";
import {placeholderNights} from "@calculations/functions";


export default {
    methods: {
        togglePriceType: function () {
            this.placeholder.clientOffer.priceType = this.isPerPerson ? 'per_group' : 'per_person';

            edit(this.api, {
                clientOffer: this.placeholder.clientOffer
            }, {
                '_groups[]': 'order_placeholder_read'
            });
        },

        toggleStatus: function () {
            const status = this.isIncluded ? 'optional' : 'included';
            const currency = this.currency;

            this.placeholder.clientOffer.status = status;

            if(status === 'optional'){
                if(currency !== this.$store.state.order.orderCurrency && !this.placeholder.calculationRequest){
                    let factor = this.$store.state.calculationToOrderExchangeRate[this.$store.state.order.calculationCurrency] ? this.exchangeRate * this.$store.state.calculationToOrderExchangeRate[this.$store.state.order.calculationCurrency] : 1;
                    if(this.price[this.personPriceType]){
                        this.price[this.personPriceType].amount = this.price[this.personPriceType].amount * factor;
                        this.price[this.personPriceType].currency = this.$store.state.order.orderCurrency;
                    }
                    if(this.price[this.singlePriceType]){
                        this.price[this.singlePriceType].amount = this.price[this.singlePriceType].amount * factor;
                        this.price[this.singlePriceType].currency = this.$store.state.order.orderCurrency;
                    }
                    if(this.price[this.groupPriceType]){
                        this.price[this.groupPriceType].amount = this.price[this.groupPriceType].amount * factor;
                        this.price[this.groupPriceType].currency = this.$store.state.order.orderCurrency;
                    }
                }
            }

            edit(this.api, {
                clientOffer: this.placeholder.clientOffer,
                placeholderCurrency: this.$store.state.order.orderCurrency
            }, {
                '_groups': ['order_placeholder_read', 'order_placeholder:order_calculation_price', 'order_calculation_read', 'money_read']
            }).then(response => {
                let placeholder = {
                    ...this.price.placeholder,
                    placeholderCurrency: response.data.placeholderCurrency
                }

                //Update prices (due to supplements)
                let newPrices = {
                    ...response.data.calculationPrice.doubleRoomBuyPrice && {
                        doubleRoomBuyPrice: response.data.calculationPrice.doubleRoomBuyPrice
                    },
                    ...response.data.calculationPrice.singleRoomPremiumBuyPrice && {
                        singleRoomPremiumBuyPrice: response.data.calculationPrice.singleRoomPremiumBuyPrice
                    },
                    ...response.data.calculationPrice.insideDoubleCabinBuyPrice && {
                        insideDoubleCabinBuyPrice: response.data.calculationPrice.insideDoubleCabinBuyPrice
                    },
                    ...response.data.calculationPrice.insideSingleCabinPremiumBuyPrice && {
                        insideSingleCabinPremiumBuyPrice: response.data.calculationPrice.insideSingleCabinPremiumBuyPrice
                    },
                    ...response.data.calculationPrice.secondDoubleCabinBuyPrice && {
                        secondDoubleCabinBuyPrice: response.data.calculationPrice.secondDoubleCabinBuyPrice
                    },
                    ...response.data.calculationPrice.secondSingleCabinPremiumBuyPrice && {
                        secondSingleCabinPremiumBuyPrice: response.data.calculationPrice.secondSingleCabinPremiumBuyPrice
                    },
                    ...response.data.calculationPrice.firstDoubleCabinBuyPrice && {
                        firstDoubleCabinBuyPrice: response.data.calculationPrice.firstDoubleCabinBuyPrice
                    },
                    ...response.data.calculationPrice.firstSingleCabinPremiumBuyPrice && {
                        firstSingleCabinPremiumBuyPrice: response.data.calculationPrice.firstSingleCabinPremiumBuyPrice
                    },
                    ...response.data.calculationPrice.buyPrice && {
                        buyPrice: response.data.calculationPrice.buyPrice
                    },
                }




                this.$store.commit('updatePrice', {[this.price._type + 'Prices']: {...this.price, placeholder: placeholder, ...newPrices, currencyChange: true}})
            });
        }
    },

    computed: {
        api: function () { return `${getPlaceholderType(this.placeholder)}_placeholders/${this.placeholder.id}`; },
        placeholder: function () { return this.price.placeholder; },
        name: function () { return this.placeholder.serviceDescription; },
        icon: function () { return this.$options.icon || 'help'; },
        currency: function() { return this.placeholder.placeholderCurrency},
        isPerPerson: function () { return this.placeholder.clientOffer.priceType === 'per_person'; },
        isIncluded: function () { return this.placeholder.clientOffer.status === 'included'; },
        isOther: function () { return this.price._type === 'other'; },
        isAirline: function () { return this.price._type === 'airline'; },

        date: function () {
            if (this.placeholder) {
                let { startAt, endAt } = this.placeholder;
                startAt = fullYear(startAt);
                endAt = fullYear(endAt);

                if (startAt !== endAt) {
                    return `${startAt.substring(0,6)} - ${endAt}`;
                } else {
                    return startAt;
                }
            }

            return false;
        },

        nights: function () {
            return placeholderNights(this.placeholder);
        },

        days: function () {
            return this.nights + 1;
        },
    }
};
