<template>
    <div class="users">
        <div class="user is-favicon is-small">
            <div class="user__avatar" v-bind:style="faviconStyle(row.organisationFilter)"></div>
        </div>
    </div>
</template>


<script>
import Cell from './cell';
import {getIDFromString} from "@utilities/functions";

export default {
    mixins: [ Cell ],

    methods: {
        faviconStyle: function (organisation) {
            if(!organisation){
                return {
                    'background-image' : `url(/clientSpecific/favicon/default.ico)`
                }
            }
            return {
                'background-image' : `url(/clientSpecific/favicon/${getIDFromString(organisation)}.ico)`,
            }
        }
    },
}
</script>
