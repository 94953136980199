<template>
    <div class="is-truncated is-250">
        <a v-if="agency" v-bind:href="'/agencies/' + agency.id" v-bind:title="agency.name" target="_blank">{{ truncatedAgency }}</a>

        <template v-if="agency && agency.id && provider && provider.id || !agency && !provider">-</template>

        <a v-if="provider && provider.id" v-bind:href="provider.api" class="is-contents" v-bind:title="provider.name" target="_blank" style="display: contents;">{{ provider.name }}</a>
    </div>
</template>



<script>
export default {
    props: {
        agency:      { required: true },
        provider:    { required: true }
    },


    computed: {
        truncatedAgency: function() {
            if(!this.agency) {
                return '';
            }
            if(this.provider) {
                let fullName = this.agency.name + ' - ' + this.provider.name;
                if(fullName.length > 22) {
                    return this.agency.name.substring(0,20) + '...'
                }

            }
            return this.agency.name;
        }
    }
}
</script>
