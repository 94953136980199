var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        class: { "is-selected": _vm.folderSelected === _vm.folder.folder },
        on: { click: _vm.selectFolder },
      },
      [
        _vm.folder.subfolders
          ? _c(
              "i",
              {
                staticClass: "material-icons is-arrow",
                class: { "is-down": _vm.areSubfoldersVisible },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toggleSubfolders.apply(null, arguments)
                  },
                },
              },
              [_vm._v("play_arrow")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.folder.level === 0 || !_vm.folder.level
          ? _c("i", { staticClass: "material-icons" }, [
              _vm._v(_vm._s(_vm.folder.icon)),
            ])
          : _vm._e(),
        _vm._v(
          "\n            " +
            _vm._s(_vm.folder.name ? _vm.folder.name : _vm.folderName) +
            "\n\n            "
        ),
        _c(
          "span",
          [
            _vm.folder.list
              ? [
                  _vm._v(
                    _vm._s(_vm.folder.list.length ? _vm.folder.list.length : "")
                  ),
                ]
              : _vm.isLoading && _vm.folderSelected === _vm.folder.folder
              ? _c("i", { staticClass: "material-icons icon-spin" }, [
                  _vm._v("refresh"),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }