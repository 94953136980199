var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "has-margin-top" }, [
    _c(
      "div",
      {
        staticClass: "bp-panel is-form has-no-title",
        staticStyle: { "min-height": "auto" },
      },
      [
        _c("div", { staticClass: "panel-content is-table-form" }, [
          _c(
            "table",
            [
              _c("tr", [
                _vm._m(0),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "control" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.periods,
                          expression: "periods",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "input",
                      attrs: { type: "text" },
                      domProps: { value: _vm.periods },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.periods = _vm._n($event.target.value)
                          },
                          _vm.setPeriods,
                        ],
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _vm._m(1),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "control" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.columns,
                          expression: "columns",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "input",
                      attrs: { type: "text" },
                      domProps: { value: _vm.columns },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.columns = _vm._n($event.target.value)
                          },
                          _vm.setPeriods,
                        ],
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { attrs: { colspan: _vm.columns + 1 } }, [_c("h2")]),
              ]),
              _vm._v(" "),
              _c(
                "tr",
                [
                  _c("td"),
                  _vm._v(" "),
                  _vm._l(_vm.columns, function (col) {
                    return _c("td", [
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          staticClass: "input",
                          attrs: { type: "text", placeholder: "3*" },
                          domProps: { value: _vm.getTableElement(0, 0, col) },
                          on: {
                            input: (input) =>
                              _vm.setTableElement(0, 0, col, input),
                          },
                        }),
                      ]),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm._l(_vm.periods, function (period) {
                return [
                  _c("tr", [
                    _c("td", { attrs: { colspan: _vm.columns + 1 } }, [
                      _c("h2", [_vm._v(_vm._s(period) + ". Preisblock")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tr",
                    [
                      _c("th", [
                        _c("div", { staticClass: "control" }, [
                          _c("input", {
                            staticClass: "input is-large",
                            attrs: { type: "text", placeholder: "April 2023" },
                            domProps: {
                              value: _vm.getTableElement(period, 0, 0),
                            },
                            on: {
                              input: (input) =>
                                _vm.setTableElement(period, 0, 0, input),
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.columns, function (col) {
                        return _c("td", [
                          _c("div", { staticClass: "control" }, [
                            period === 0
                              ? _c("input", {
                                  staticClass: "input",
                                  attrs: { type: "text", placeholder: "3*" },
                                  domProps: {
                                    value: _vm.getTableElement(period, 0, col),
                                  },
                                  on: {
                                    input: (input) =>
                                      _vm.setTableElement(
                                        period,
                                        0,
                                        col,
                                        input
                                      ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "tr",
                    [
                      _vm._m(2, true),
                      _vm._v(" "),
                      _vm._l(_vm.columns, function (col) {
                        return _c("td", [
                          _c("div", { staticClass: "control" }, [
                            _c("input", {
                              staticClass: "input",
                              attrs: { type: "text", placeholder: "1.234 €" },
                              domProps: {
                                value: _vm.getTableElement(period, 1, col),
                              },
                              on: {
                                input: (input) =>
                                  _vm.setTableElement(period, 1, col, input),
                              },
                            }),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "tr",
                    [
                      _vm._m(3, true),
                      _vm._v(" "),
                      _vm._l(_vm.columns, function (col) {
                        return _c("td", [
                          _c("div", { staticClass: "control" }, [
                            _c("input", {
                              staticClass: "input",
                              attrs: { type: "text", placeholder: "1.234 €" },
                              domProps: {
                                value: _vm.getTableElement(period, 2, col),
                              },
                              on: {
                                input: (input) =>
                                  _vm.setTableElement(period, 2, col, input),
                              },
                            }),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _c("label", { staticClass: "label" }, [_vm._v("Zeiträume (Zeilen):")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _c("label", { staticClass: "label" }, [_vm._v("Kategorien (Spalten):")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _c("label", { staticClass: "label" }, [
        _vm._v("Preise pro Person im DZ:"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _c("label", { staticClass: "label" }, [_vm._v("Einzelzimmer-Zuschlag:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }