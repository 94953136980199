var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "has-margin-top" }, [
    _c(
      "div",
      {
        staticClass: "bp-panel has-no-title",
        staticStyle: { "min-height": "auto" },
      },
      [
        _c("div", { staticClass: "panel-content" }, [
          _c(
            "div",
            { staticClass: "columns has-seperating-borders" },
            _vm._l(_vm.orderWebflowLinks, function (link) {
              return _c("div", { staticClass: "column" }, [
                _c("div", [
                  _c("h4", [
                    _vm._v(_vm._s(link.webflowCollection.webflowHomepage.url)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "is-small-text",
                      class: {
                        "is-text-red": _vm.getStatus(link) === "notPublished",
                        "is-text-yellow": _vm.getStatus(link) === "staged",
                        "is-text-blue": _vm.getStatus(link) === "published",
                      },
                    },
                    [_vm._v(_vm._s(_vm.getStatusText(link)))]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "is-small-text" }, [
                  _c(
                    "a",
                    {
                      staticClass: "is-text-link-color",
                      attrs: { target: "_blanc", href: _vm.getUrl(link, true) },
                    },
                    [_vm._v("Testseite öffnen")]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "is-small-text" }, [
                  _c(
                    "a",
                    {
                      staticClass: "is-text-link-color",
                      attrs: {
                        target: "_blanc",
                        href: _vm.getUrl(link, false),
                      },
                    },
                    [_vm._v("Hauptseite öffnen")]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "level level-left mt-4" }, [
                  _vm.getStatus(link) === "published"
                    ? _c("div", { staticClass: "control level-item" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button is-full-width",
                            class: { "is-loading": _vm.isLoading },
                            on: {
                              click: function ($event) {
                                return _vm.publish(link, "draft")
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("delete"),
                            ]),
                            _vm._v(
                              " Von Homepage löschen\n                                "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "level-item" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button is-full-width",
                        class: { "is-loading": _vm.isLoading },
                        on: {
                          click: function ($event) {
                            return _vm.publish(link, "preview")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("sync"),
                        ]),
                        _vm._v(
                          " Auf Testseite veröffentlichen\n                                "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "control level-item" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button is-full-width is-primary",
                        class: { "is-loading": _vm.isLoading },
                        on: {
                          click: function ($event) {
                            return _vm.publish(link, "publish")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("public"),
                        ]),
                        _vm._v(
                          " Auf Hauptseite veröffentlichen\n                                "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "control level-item" }, [
                    _vm.getStatus(link) === "publish" ||
                    _vm.getStatus(link) === "staged"
                      ? _c(
                          "button",
                          {
                            staticClass: "button is-full-width is-danger",
                            class: { "is-loading": _vm.isLoading },
                            on: {
                              click: function ($event) {
                                return _vm.deleteFromWebsite(link)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("delete"),
                            ]),
                            _vm._v(
                              " Von Website entfernen\n                                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }