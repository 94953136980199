var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "columns",
    {
      attrs: { property: "contacts" },
      on: { add: _vm.openForm },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function ({ item }) {
            return _c(
              "column-item",
              {
                attrs: {
                  update: {
                    updatedBy: item.updatedBy,
                    updatedAt: item.updatedAt,
                  },
                  api: "/api/contacts/" + item.id,
                  label: _vm.label(item),
                },
                on: {
                  edit: function ($event) {
                    return _vm.openForm(item)
                  },
                  delete: function ($event) {
                    return _vm.deleteItem("contacts", item)
                  },
                },
              },
              [
                _c(
                  "ul",
                  [
                    _vm.isGeneral(item)
                      ? [
                          _c("li", [_c("strong", [_vm._v("Allgemein")])]),
                          _vm._v(" "),
                          item.address
                            ? _c("li", [
                                _c("em", [
                                  _vm._v(
                                    _vm._s(_vm.addressLabel(item.address))
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          item.address && item.address.port
                            ? _c("li", [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("directions_boat"),
                                ]),
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(item.address.port.place.name) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          item.address && item.address.airport
                            ? _c("li", [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("airplanemode_active"),
                                ]),
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(item.address.airport.name) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      : [
                          _c("li", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.titleName(item.title)) +
                                  " " +
                                  _vm._s(item.firstName) +
                                  " " +
                                  _vm._s(item.lastName)
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("em", [_vm._v(_vm._s(_vm.roleName(item.role)))]),
                          ]),
                        ],
                    _vm._v(" "),
                    !!item.email
                      ? _c(
                          "li",
                          {
                            staticClass: "is-email-link",
                            on: {
                              click: function ($event) {
                                return _vm.openNewEmail(item)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("email"),
                            ]),
                            _vm._v(_vm._s(item.email)),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !!item.phoneMobile
                      ? _c("li", [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("smartphone"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "is-link",
                              attrs: { href: _vm.telURL(item.phoneMobile) },
                            },
                            [_vm._v(_vm._s(item.phoneMobile))]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !!item.phoneFixed
                      ? _c("li", [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("phone"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "is-link",
                              attrs: { href: _vm.telURL(item.phoneFixed) },
                            },
                            [_vm._v(_vm._s(item.phoneFixed))]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !!item.fax
                      ? _c("li", [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("print"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "is-link",
                              attrs: { href: _vm.faxURL(item.fax) },
                            },
                            [_vm._v(_vm._s(item.fax))]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.languages && item.languages.length > 0
                      ? _c("li", [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("textsms"),
                          ]),
                          _vm._v(
                            _vm._s(
                              item.languages
                                .map((language) => _vm.languageName(language))
                                .join(", ")
                            )
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.place
                      ? _c("li", [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("location_on"),
                          ]),
                          _vm._v(_vm._s(item.place.name) + "\n            "),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            )
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "template",
        { slot: "form" },
        [
          _c("contact-form", { ref: "form" }),
          _vm._v(" "),
          _vm.isNewEmailVisible
            ? _c("new-email", {
                attrs: {
                  "preselected-contact": _vm.newEmailContact,
                  isModalVisible: _vm.isNewEmailVisible,
                },
                on: {
                  closeModal: function ($event) {
                    _vm.isNewEmailVisible = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }