var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bp-row-form" }, [
    _c("div", [
      _c("div", { staticClass: "bp-row-form__title" }, [
        _vm._v("Zugverbindung"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("i", { staticClass: "material-icons", attrs: { title: "Zug" } }, [
            _vm._v("directions_train"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "buttons has-addons toggle-switch has-margin-right",
            },
            [
              _c(
                "button",
                {
                  staticClass: "button is-large",
                  class: { "is-primary": _vm.copyPlaceholder.type === "night" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.copyPlaceholder.type = "night"
                    },
                  },
                },
                [_vm._v("Nacht")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button is-large",
                  class: { "is-primary": _vm.copyPlaceholder.type === "day" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.copyPlaceholder.type = "day"
                    },
                  },
                },
                [_vm._v("Tag")]
              ),
            ]
          ),
          _vm._v(" "),
          _c("async-select", {
            ref: "startTrainStation",
            staticClass: "has-margin-right",
            staticStyle: { width: "150px" },
            attrs: {
              api: "train_stations",
              "custom-label": (item) => item.name,
              crudLinkTitle: "Fügen Sie einen neuen Bahnhof hinzu",
              clearable: false,
              "allow-empty": false,
              placeholder: "Abfahrtsbahnhof",
            },
            model: {
              value: _vm.copyPlaceholder.startTrainStation,
              callback: function ($$v) {
                _vm.$set(_vm.copyPlaceholder, "startTrainStation", $$v)
              },
              expression: "copyPlaceholder.startTrainStation",
            },
          }),
          _vm._v(" "),
          _c("div", [
            _c(
              "button",
              {
                staticClass: "btn-single-toggle has-margin-right",
                class: [_vm.endTrainStationRestriction ? "" : "is-active"],
                attrs: { title: "Nur bestehende Zugverbindungen suchen" },
                on: {
                  click: function ($event) {
                    _vm.endTrainStationRestriction =
                      !_vm.endTrainStationRestriction
                  },
                },
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    staticStyle: { "vertical-align": "-2px" },
                  },
                  [_vm._v("timeline")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("async-select", {
            staticStyle: { width: "150px" },
            attrs: {
              api: "train_stations",
              "custom-label": (item) => item.name,
              crudLinkTitle: "Fügen Sie einen neuen Bahnhof hinzu",
              clearable: false,
              "allow-empty": false,
              params:
                _vm.endTrainStationRestriction &&
                _vm.copyPlaceholder.startTrainStation
                  ? {
                      _groups: [
                        "provider_train_route",
                        "provider_read",
                        "location_train_station:provider_train_route",
                      ],
                      "endRoutes.startTrainStation.id":
                        _vm.copyPlaceholder.startTrainStation.id,
                    }
                  : {
                      _groups: [
                        "provider_train_route",
                        "provider_read",
                        "location_train_station:provider_train_route",
                      ],
                    },
              placeholder: "Ankunftsbahnhof",
            },
            model: {
              value: _vm.copyPlaceholder.endTrainStation,
              callback: function ($$v) {
                _vm.$set(_vm.copyPlaceholder, "endTrainStation", $$v)
              },
              expression: "copyPlaceholder.endTrainStation",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "is-160 has-time" }, [
      _c("div", { staticClass: "bp-row-form__title" }, [_vm._v("Zeitangaben")]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.serviceDayTimes.length > 0
            ? _c(
                "button",
                {
                  staticClass: "btn-single-toggle has-margin-right",
                  class: [_vm.onlyTime ? "" : "is-active"],
                  attrs: { title: "Uhrzeit" },
                  on: {
                    click: function ($event) {
                      _vm.onlyTime = !_vm.onlyTime
                    },
                  },
                },
                [
                  _c(
                    "i",
                    {
                      staticClass: "material-icons",
                      staticStyle: { "vertical-align": "-1px" },
                    },
                    [_vm._v("access_time")]
                  ),
                ]
              )
            : _c(
                "i",
                { staticClass: "material-icons", attrs: { title: "Uhrzeit" } },
                [_vm._v("access_time")]
              ),
          _vm._v(" "),
          _vm.onlyTime
            ? [
                _c("date-time", {
                  staticClass: "has-margin-right",
                  attrs: { "time-only": "" },
                  model: {
                    value: _vm.copyPlaceholder.startTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.copyPlaceholder, "startTime", $$v)
                    },
                    expression: "copyPlaceholder.startTime",
                  },
                }),
                _vm._v(" -\n                "),
                _c("date-time", {
                  staticClass: "has-margin-left",
                  attrs: { "time-only": "" },
                  model: {
                    value: _vm.copyPlaceholder.endTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.copyPlaceholder, "endTime", $$v)
                    },
                    expression: "copyPlaceholder.endTime",
                  },
                }),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "select" },
                  [
                    _c("multiselect", {
                      attrs: {
                        options: _vm.serviceDayTimes,
                        "track-by": "id",
                        "custom-label": _vm.serviceDayTimeLabel,
                      },
                      on: { select: _vm.selectServiceTime },
                      model: {
                        value: _vm.serviceDayTimeSelected,
                        callback: function ($$v) {
                          _vm.serviceDayTimeSelected = $$v
                        },
                        expression: "serviceDayTimeSelected",
                      },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _vm.onlyTime
        ? _c(
            "div",
            [
              _c(
                "i",
                {
                  staticClass: "material-icons",
                  attrs: { title: "Einkunft am Bahnhof" },
                },
                [_vm._v("av_timer")]
              ),
              _vm._v(" "),
              _c("date-time", {
                attrs: { "time-only": "" },
                model: {
                  value: _vm.copyPlaceholder.checkInTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.copyPlaceholder, "checkInTime", $$v)
                  },
                  expression: "copyPlaceholder.checkInTime",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", [
      _c("div", { staticClass: "bp-row-form__title" }, [
        _vm._v("Besondere Zugedetails"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "button",
            {
              staticClass: "btn-single-toggle has-margin-right",
              class: [_vm.copyPlaceholder.hideClass ? "" : "is-active"],
              attrs: { title: "Klasse auswählen" },
              on: {
                click: function ($event) {
                  _vm.copyPlaceholder.hideClass = !_vm.copyPlaceholder.hideClass
                },
              },
            },
            [
              _c(
                "i",
                {
                  staticClass: "material-icons",
                  staticStyle: { "vertical-align": "-2px" },
                },
                [_vm._v("receipt")]
              ),
            ]
          ),
          _vm._v(" "),
          !_vm.copyPlaceholder.hideClass &&
          _vm.options.trainLabels.categorySelect
            ? _c("multiselect", {
                staticStyle: { width: "180px" },
                attrs: {
                  "track-by": "key",
                  label: "value",
                  placeholder: "Klasse",
                  options: _vm.objectToKeyValueArray(
                    _vm.options.trainLabels.categorySelect
                  ),
                },
                model: {
                  value: _vm.category,
                  callback: function ($$v) {
                    _vm.category = $$v
                  },
                  expression: "category",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.copyPlaceholder.hideClass
            ? _c("multiselect", {
                staticStyle: { width: "180px" },
                attrs: {
                  options: [
                    { key: true, value: "ohne Klasse" },
                    { key: false, value: "Klasse festlegen" },
                  ],
                  "track-by": "key",
                  label: "value",
                  placeholder: "Klasse",
                },
                model: {
                  value: _vm.hideClass,
                  callback: function ($$v) {
                    _vm.hideClass = $$v
                  },
                  expression: "hideClass",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "i",
            { staticClass: "material-icons", attrs: { title: "Klasse" } },
            [_vm._v("receipt")]
          ),
          _vm._v(" "),
          _vm.trainTypes
            ? _c("multiselect", {
                staticStyle: { width: "180px" },
                attrs: {
                  "track-by": "key",
                  label: "value",
                  placeholder: "Besonderer Zug",
                  options: _vm.objectToKeyValueArray(_vm.trainTypes),
                },
                model: {
                  value: _vm.trainType,
                  callback: function ($$v) {
                    _vm.trainType = $$v
                  },
                  expression: "trainType",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.copyPlaceholder.trainType !== "standard" &&
      _vm.copyPlaceholder.type === "day"
        ? _c(
            "div",
            [
              _c(
                "i",
                { staticClass: "material-icons", attrs: { title: "Klasse" } },
                [_vm._v("receipt")]
              ),
              _vm._v(" "),
              _vm.wagonTypes
                ? _c("multiselect", {
                    staticStyle: { width: "180px" },
                    attrs: {
                      "track-by": "key",
                      label: "value",
                      placeholder: "Wagen",
                      options: _vm.objectToKeyValueArray(_vm.wagonTypes),
                    },
                    model: {
                      value: _vm.wagonType,
                      callback: function ($$v) {
                        _vm.wagonType = $$v
                      },
                      expression: "wagonType",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", [
      _c("div", { staticClass: "bp-row-form__title" }, [_vm._v("Abrechnung")]),
      _vm._v(" "),
      _c("div", [
        _c(
          "i",
          {
            staticClass: "material-icons",
            attrs: { title: "Set as Additional" },
          },
          [_vm._v("shopping_cart")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "select" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.copyPlaceholder.clientOffer.status,
                  expression: "copyPlaceholder.clientOffer.status",
                },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.copyPlaceholder.clientOffer,
                    "status",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "included" } }, [
                _vm._v("inkludiert"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "optional" } }, [
                _vm._v("optional"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "additional" } }, [
                _vm._v("gesondert"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "atLocation" } }, [
                _vm._v("vor Ort"),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.hasOwnRouteDescription
      ? _c("div", [
          _c("div", { staticClass: "bp-row-form__title" }, [
            _vm._v("Routenbeschreibung"),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.copyPlaceholder.routeDescription,
                  expression: "copyPlaceholder.routeDescription",
                },
              ],
              staticClass: "textarea",
              attrs: { id: "description", rows: "4" },
              domProps: { value: _vm.copyPlaceholder.routeDescription },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.copyPlaceholder,
                    "routeDescription",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "button is-close",
        attrs: { title: "Verwerfen" },
        on: {
          click: function ($event) {
            return _vm.$emit("cancel")
          },
        },
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "button is-save is-primary",
        class: { "is-loading": _vm.isLoading },
        attrs: { title: "Speichern" },
        on: { click: _vm.save },
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("check")])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }