<template>
    <div class="row is-h-auto">
        <div class="cell is-gapless self-stretch" v-on:click="$refs.input.focus()">
            <div class="input-wrapper">
                <input
                    type="text"
                    ref="input"
                    class="input"
                    v-model="form.title"
                    :readonly="readonly"
                    @blur="handleBlur('title')"
                >
            </div>
        </div>
        <div class="cell is-gapless">
            <resizable v-if="!readonly">
                <textarea ref="textArea" rows="1" v-model="form.text" class="textarea" @blur="handleBlur('text')"></textarea>
            </resizable>
            <input
                v-else
                type="text"
                class="input is-readonly"
                readonly
                value="wird in Reise festgelegt"
            />
        </div>
        <div class="cell is-70px-cell level-right">
            <div class="sort-number level-item">
                <slot />
            </div>
            <i class="material-icons" v-on:click="$emit('delete')">delete</i>
        </div>
    </div>
</template>

<script>
import tripDetail from '../tripDetail'
import { Resizable } from '@components/form'

export default {
    mixins: [ tripDetail ],

    computed: {
        isForm: function () { return false }
    },

    components: {
        Resizable
    },

    mounted () {
          if(this.$refs && this.$refs.textArea && this.form.title && !this.form.text && this.isLastIndex) {
              this.$refs.textArea.focus();
          }
    }
}
</script>
