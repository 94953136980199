import priceMixin from '@calculations/priceMixin';
import InputPrice from '@calculations/components/InputPrice';

export default {
    mixins: [priceMixin],

    components: {
        InputPrice
    },

    computed: {
        placeholder: function () {
            return this.price.placeholder;
        },

        providerPrices: function () { return !!this.price.providerPrices ? this.price.providerPrices : []},


        hasCalculationRequest: function () {
            return !!this.placeholder.calculationRequest;
        }
    },

    methods: {
        getProviderPrices: function(type) {
            return this.providerPrices[type] ? this.providerPrices[type] : [];
        },
    }

}
