var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("gb-flag", {
        attrs: {
          code: _vm.getFlag(_vm.value),
          size: "small",
          iconPath: "/assets/img/flags/",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }