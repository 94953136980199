var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.elements, function (element) {
        return _vm._l(_vm.rows, function (row) {
          return _c(
            "div",
            { staticClass: "columns" },
            _vm._l(row.cols, function (col) {
              return _c(
                "div",
                { staticClass: "column" },
                [
                  _c(col.component.type, {
                    tag: "component",
                    attrs: { component: col.component, "new-item": element },
                    on: {
                      buttonAction: (payload) =>
                        _vm.$emit("buttonAction", payload),
                      saveItem: (prop, value) =>
                        _vm.saveItem(prop, value, element),
                      saveItemDebounce: (prop, value) =>
                        _vm.saveItemDebounce(prop, value, element),
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }