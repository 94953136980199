<template>
    <i class="material-icons" v-if=getIcon :style="{color : getIcon.color, fontsize: '25px', 'font-weight': 900}" :title="'Rechnungs-Nr: ' + row.lastClientInvoiceId + ' | ' + getIcon.title">{{getIcon.icon}}</i>
</template>

<script>
import Cell from './cell';

export default {
    mixins: [Cell],

    computed: {
        getIcon: function () {
            if (this.row.clientInvoicesStatus === 'notIssued') {
                return null
            }
            if  (this.row.clientInvoicesStatus === 'issued') {
                return {
                    icon : 'radio_button_checked',
                    color : '#E68C87',
                    title: 'ohne Zahlungseingang'
                }
            }
            if (this.row.clientInvoicesStatus === 'partiallyPaid') {
                return {
                    icon : 'check',
                    color : '#FFB606',
                    title: 'Zahlungseingang < Errechnete Rechnungsbetrag'
                }
            }
            if (this.row.clientInvoicesStatus === 'paid') {
                return {
                    icon : 'check',
                    color : '#41b883',
                    title: 'Zahlungseingang = Errechnete Rechnungsbetrag'
                }
            }
        }
    }
}
</script>
