<template>
    <div class="search-day-concept" v-if="concepts.length > 0">
        <div class="column is-one-third">

            <div class="panel">
                <div class="panel-heading">Suchergebnisse: Bestehende Tageskonzepte</div>

                <div class="panel-block is-spaceless">
                    <div class="day-concepts">
                        <div
                            v-for        = "concept in uniqConcepts"
                            v-bind:key   = "concept.id"
                            v-bind:class = "{ 'is-selected' : conceptSelected === concept }"
                            v-on:click   = "handleConceptSelect(concept)"
                        >
                            <div v-if="concept.category" class="tag">
                                <span class="is-truncated">{{ conceptCategories.filter(x => x.key === concept.category)[0].value }}</span>
                            </div>
                            <div v-else class="tag is-placeholder"></div>

                            <span class="is-truncated" v-tooltip="{content: concept.name, delay: {show: 1000}}">{{ concept.name }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="column is-one-third">
            <div class="panel">
                <div class="panel-heading">Varaianten des tageskonzepts</div>

                <div class="panel-block is-spaceless">
                    <div class="day-concepts" v-if="conceptSelected">
                        <div
                            v-for        = "concept in conceptVariations"
                            v-bind:key   = "concept.id"
                            v-bind:class = "{ 'is-selected' : variationSelected === concept }"
                            v-on:click   = "variationSelected = concept"
                            class        = "is-variation"
                        >
                            <div class="index">{{concept.days.length}}</div>
                            <div class="level-left is-truncated">
                                {{concept.subtitle ? concept.subtitle : 'Standard (ohne Zusatztitel)'}}
                            </div>

<!--                            <div class="level-left is-truncated" v-if="hasOthers(concept)">
                                <div v-if="included(concept)" class='level-item'>
                                    inkl: <span class="is-truncated" v-tooltip="{content: included(concept), delay: {show: 1000}}">{{ included(concept) }}</span>
                                </div>

                                <div v-if="optional(concept)" class="level-item">
                                    Optional: <span class="is-truncated" v-tooltip="{content: optional(concept), delay: {show: 1000}}">{{ optional(concept) }}</span>
                                </div>
                            </div>
                            <div  v-else class="level-left is-truncated">ohne Zusatzleistungen</div>

                            <div class="icons">
                                <i class="material-icons" v-tooltip="{content: concept.subtitle, delay: {show: 1000}}" v-if="concept.subtitle">insert_photo</i>
                                <a v-bind:href="`/concepts/days/${concept.id}`" target="_blank">
                                    <i class="fa fa-external-link-square"></i>
                                </a>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="column is-one-third">
            <div class="panel">
                <div class="panel-heading">
                    <span>Vorschau</span>
                    <div class="buttons has-addons" v-if="!hasVariationSlot">
                        <button
                            class="button is-icon"
                            :class="{'is-primary': this.descriptionType === 'serviceDescription'}"
                            v-on:click.prevent.stop="descriptionType = 'serviceDescription'"
                            v-tooltip="{content: 'Leistungen', delay: {show: 1000}}"
                        >
                            <i class="material-icons">assignment</i>
                        </button>
                        <button
                            class="button is-icon"
                            :class="{'is-primary': this.descriptionType === 'routeDescription'}"
                            v-on:click.prevent.stop="descriptionType = 'routeDescription'"
                            v-tooltip="{content: 'Route', delay: {show: 1000}}"
                        >
                            <i class="material-icons">place</i>
                        </button>
                    </div>
                </div>
                <div class="panel-block is-spaceless">
                    <slot name="variation" v-bind:concept="variationSelected">
                        <div class="day-concepts" v-if="variationSelected">
                            <div
                                v-if         = "placeholder.placeholderType !== 'route' || descriptionType === 'routeDescription'"
                                v-for        = "placeholder in sortedPlaceholders"
                                v-bind:key   = "placeholder.placeholderType + '-' + placeholder.id"
                                v-bind:class = "{
                                    'is-optional'   : isOptional(placeholder) || isAtLocation(placeholder),
                                    'is-additional' : isAdditional(placeholder),
                                    'has-more-info' : placeholder.placeholderType === 'route'
                                }"
                                class="is-placeholder"
                            >

                                <div class="level-left is-truncated">
                                    <i class="material-icons" v-if="placeholder.placeholderType === 'ferry'">directions_boat</i>
                                    <i class="material-icons" v-if="placeholder.placeholderType === 'train'">directions_train</i>
                                    <i class="material-icons" v-else-if="placeholder.placeholderType === 'airline'">airplanemode_active</i>
                                    <other-service-icons v-else-if="placeholder.placeholderType === 'other'" v-bind:type="placeholder.otherServiceType.category" />
                                    <i class="material-icons" v-else-if="placeholder.placeholderType === 'hotel'">home</i>
                                    <i v-if="placeholder.placeholderType === 'route'" class="material-icons">filter_hdr</i>
                                    <i v-if="placeholder.placeholderType === 'free'" class="material-icons">view_headline</i>

                                    <span
                                        :class="{'is-optional' : isOptional(placeholder) || isAtLocation(placeholder), 'is-additional' : isAdditional(placeholder),}"
                                        class="is-truncated"
                                        v-tooltip="{content: placeholder[descriptionType], delay: {show: 1000}}"
                                    >
                                        <span v-if="isOptional(placeholder)">Möglichkeit:</span>
                                        <span v-if="isAtLocation(placeholder)">vor Ort:</span>
                                        <span>{{placeholder[descriptionType]}}</span>
                                    </span>
                                </div>

                                <template v-if="editablePlaceholders">
                                    <div v-if="placeholder.placeholderType === 'hotel'" class="form">
                                        <input
                                            type="text"
                                            class="input is-tiny"
                                            :value="placeholder.nights"
                                            v-on:input="e => { updatePlaceholder(placeholder, e.target.value) }"
                                        >
                                        Nächte
                                    </div>

                                    <div v-if="placeholder.placeholderType === 'other'" class="form">
                                        <input
                                            type="text"
                                            class="input is-tiny"
                                            :value="placeholder.durationDays"
                                            v-on:input="e => { updatePlaceholder(placeholder, e.target.value) }"
                                        >
                                        Tage
                                    </div>
                                </template>
                            </div>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>

    <div class="is-empty" v-else>{{ emptyText }}</div>
</template>



<script>
import debounce          from 'lodash/debounce';
import uniqBy            from 'lodash/uniqBy';
import toNumber          from 'lodash/toNumber';
import has               from 'lodash/has';

import OtherServiceIcons from '@components/OtherServiceIcons';
import { getDayConcepts, optionsDayConcept } from '@api';
import {stripHtml} from "@utilities/functions";
import mixins from "@orders/mixins";


export default {
    mixins: [mixins],

    props: {
        searchTerm:    { required: true },
        destinations:  { required: true },
        statuses:      { default: '' },
        dayConceptIDs: { type: Array, default: () => [] },
        editablePlaceholders: { type: Boolean, default: false }
    },


    computed: {
        hasVariationSlot: function () {
            return !!this.$scopedSlots.variation;
        },

        sortedPlaceholders: function () {
            return [
                ...this.variationSelected.airlinePlaceholders.map(item => Object.assign({}, item, { placeholderType: `airline` })),
                ...this.variationSelected.hotelPlaceholders.map(  item => Object.assign({}, item, { placeholderType: `hotel` })),
                ...this.variationSelected.ferryPlaceholders.map(  item => Object.assign({}, item, { placeholderType: `ferry` })),
                ...this.variationSelected.trainPlaceholders.map(  item => Object.assign({}, item, { placeholderType: `train` })),
                ...this.variationSelected.otherPlaceholders.map(  item => Object.assign({}, item, { placeholderType: `other` })),
                ...this.variationSelected.routeDetails.map(       item => Object.assign({}, item, { placeholderType: `route` })),
                ...this.variationSelected.freeTextItems.map(       item => Object.assign({}, item, { placeholderType: `free`, routeDescription: stripHtml(item.text) }))
            ].sort((a,b) => {
                return this.variationSelected.sortHelper.indexOf(a.placeholderType + '-' + a.id) < this.variationSelected.sortHelper.indexOf(b.placeholderType + '-' + b.id) ? -1 : 1;
            });
        },

        uniqConcepts: function () {
            return uniqBy(this.concepts, (concept => concept.category + concept.name.trim()))
        },

        conceptVariations: function () {
            return [...this.concepts
                .filter(concept => this.conceptSelected.category === concept.category && this.conceptSelected.name.trim() === concept.name.trim())]
                .sort((a,b) => b.days.length - a.days.length)
        },

        destinationsString: function () {
            return this.destinations.length > 0 ?
                {'destinations.id': this.destinations.map((destination) => { return destination.id; })} : {};
        },

        statusString: function () {
            return this.statuses.length > 0 ?
                {checkStatus: this.statuses.map((status) => { return status.key; })} :
                {};
        }
    },


    data: function () {
        return {
            conceptSelected:   null,
            variationSelected: null,
            concepts:          [],
            conceptCategories: [],
            emptyText:         'Versuche ein Konzept zu finden',
            isLoading:         false,
            descriptionType:   'routeDescription'
        }
    },


    methods: {
        handleConceptSelect: function (concept) {
            this.conceptSelected = concept
            this.variationSelected = null
        },

        getConceptList: function () {
            this.isLoading = true;

            this.callCancelRequest();

            getDayConcepts({name : this.searchTerm, ...this.destinationsString, ...this.statusString}, {
                cancelToken: this.cancelSource.token
            }).then(response => {
                this.concepts = response.data.filter(day => (this.dayConceptIDs.indexOf(day.id) === -1));

                this.emptyText = 'Es wurden keine Tageskonzepte zu diesem Suchwort gefunden';

            }, error => { })
            .then(() => { this.isLoading = false; });
        },

        included: function (concept) {
            return concept.otherPlaceholders
                .filter(placeholder => placeholder.clientOffer && placeholder.clientOffer.status !== 'optional')
                .filter(placeholder => placeholder.otherServiceType)
                .map(placeholder => placeholder.otherServiceType.name)
                .join(', ')
        },

        optional: function (concept) {
            return concept.otherPlaceholders
                .filter(placeholder => placeholder.clientOffer && placeholder.clientOffer.status === 'optional')
                .filter(placeholder => placeholder.otherServiceType)
                .map(placeholder => placeholder.otherServiceType.name)
                .join(', ')
        },

        hasOthers: function (concept) {
            return concept.otherPlaceholders.length > 0;
        },

        isOptional: function (placeholder) {
            return placeholder.clientOffer && placeholder.clientOffer.status === 'optional'
        },

        isAdditional: function(placeholder) {
            return placeholder.clientOffer && placeholder.clientOffer.status === 'additional';
        },

        isAtLocation: function (placeholder) {
            return placeholder.clientOffer && placeholder.clientOffer.status === 'atLocation'
        },

        updatePlaceholder: function (placeholder, value) {
            const variationSelected = {
                ...this.variationSelected,
                ...has(placeholder, 'nights') && {
                    hotelPlaceholders: this.variationSelected.hotelPlaceholders
                        .map(hotelPlaceholder => {
                            if (hotelPlaceholder.id === placeholder.id) {
                                return {
                                    ...hotelPlaceholder,
                                    nights: toNumber(value)
                                }
                            }

                            return hotelPlaceholder
                        })
                },
                ...has(placeholder, 'durationDays') && {
                    otherPlaceholders: this.variationSelected.otherPlaceholders
                        .map(otherPlaceholder => {
                            if (otherPlaceholder.id === placeholder.id) {
                                return {
                                    ...otherPlaceholder,
                                    durationDays: toNumber(value)
                                }
                            }

                            return otherPlaceholder
                        })
                }
            }

            this.$emit('input', variationSelected)
            this.variationSelected = variationSelected;
        }
    },


    created: function () {
        if (this.conceptCategories.length === 0) {
            optionsDayConcept().then(response => {
                this.conceptCategories = response.data.categories;

            }, error => {
                notifyError('Die Kategorien der Tageskonzepte konnte nicht geladen werden! Server Error!');
            });
        }
    },


    watch: {
        variationSelected: function () {
            this.$emit('input', this.variationSelected);
        },


        searchTerm: debounce(function () {
            this.getConceptList();
        }, 900),

        destinationsString: function () {
            this.getConceptList();
        },
    },

    mounted() {
        if(this.searchTerm){
            this.getConceptList();
        }
    },


    components: {
        OtherServiceIcons
    }
}
</script>
