<template>
    <div>
        <modal-form
            editTitle="Aufgabe bearbeiten"
            createTitle="Neuen Aufgabe hinzufügen"
        >
            <table>
                <tr>
                    <th>
                        <label class="label">Tage vor Leistungsbeginn:</label>
                    </th>
                    <td>
                        <input type="text" class="input is-small" v-model.number="form.daysBeforeStartAt">
                    </td>
                </tr>
                <tr>
                    <th>
                        <label  class="label">Aufgabe:</label>
                    </th>
                    <td>
                        <multiselect
                            v-model="form.name"
                            v-bind:options="todoOptions"
                            v-bind:taggable="true"
                            v-on:tag="addName"
                            v-on:search-change="searchName"
                            v-on:close="closeName"
                        >
                            <span slot="caret"></span>
                        </multiselect>
                    </td>
                </tr>
                <tr>
                    <th>
                        <label  class="label">Art:</label>
                    </th>
                    <td>
                        <toggle-switch v-model="form.type">
                            <option value="external">Strenge Deadline</option>
                            <option value="internal">Weiche Deadline</option>
                        </toggle-switch>
                    </td>
                </tr>


                <tr>
                    <th class="is-top">
                        <label class="label">Gültig für:</label>
                    </th>
                    <td>
                        <div class="control">
                            <div class="checkboxes columns">
                                <div class="column is-one-third" v-for="status in options.requestStatuses" v-bind:key="status.value">
                                    <label class="checkbox">
                                        <input type="checkbox" v-model="validForRequestStatuses" v-bind:value="status.key" >
                                        {{ status.value }}
                                    </label>
                                </div>
                                <div class="column is-one-third">
                                    <label class="checkbox">
                                        <input type="checkbox" v-on:change="toggleCheckboxes" value="all" v-model="allStatuses">
                                        alle
                                    </label>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="is-top">
                        <label class="label">Hinzufügen:</label>
                    </th>
                    <td>
                        <div class="control">
                            <toggle-switch v-model="addToExistingOrders">
                                <option :value="false">nur in neue Reisen</option>
                                <option :value="true">in bestehende Reisen</option>
                            </toggle-switch>
                        </div>
                    </td>
                </tr>

            </table>
        </modal-form>

    </div>
</template>

<script>
import { ToggleSwitch } from '@components/form';
import TabForm from '../form'
import InputDate                             from '@components/form/InputDate';
import {notifyError, notifySuccess} from "@components/Notification";
import {getAxios, post} from "@api";
export default {
    mixins: [ TabForm ],
    form: {
        name: '',
        daysBeforeStartAt: null,
        type: 'external',
        validForRequestStatuses: [],
    },
    property: 'todoTemplates',

    data: function () {
        return {
            allStatuses: false,
            todoOptions: ['Storno', 'Zahlung', 'Namensliste'],
            nameSearch: '',
            addToExistingOrders: false,
        }
    },

  computed: {
    validForRequestStatuses: {
      get () {
        return this.form && this.form.validForRequestStatuses ? this.form.validForRequestStatuses.map(item => item.status) : [];
      },
      set (values) {
        this.form.validForRequestStatuses = values.map(item => ({
          status: item,
        }))
      }
    }
  },

    methods: {
        handleFormSubmit: function () {
            this.isLoading = true;
            this.$store.dispatch('updateProperty', {
                key: this.$options.property,
                data: this.form,
                ...this.locale && { locale: this.locale }

            })
                .then(data => {
                    if(this.addToExistingOrders) {
                        let templateId = '';
                        if(this.form.id) {
                            templateId = this.form.id;
                        } else {
                            templateId = data.todoTemplates.find(item => item.name === this.form.name && item.daysBeforeStartAt === this.form.daysBeforeStartAt).id
                        }
                        getAxios('todo_templates/' + templateId + '/add_to_orders').then(response => {
                            notifySuccess('Die Aufgabe wurde zu den bestehenden Reisen hinzufgefügt')
                        })
                    }

                    this.visible = false;
                    notifySuccess('Das Element wurde erfolgreich hinzugefügt!');
                    this.$emit('success', {
                        form: this.form,
                        data
                    });
                    this.isLoading = false;
                }, error => {
                    this.isLoading = false;
                    notifyError('Das Element konnte nicht hinzugefügt werden! Server Error!');
                });
        },

        toggleCheckboxes: function (event) {
            if (event.target.checked) {
                this.form.validForRequestStatuses = this.options.requestStatuses.map(status => {
                    return {
                        status: status.key
                    }
                })
            } else {
                this.form.validForRequestStatuses = [];
            }
        },
        addName (newName) {
            if (newName !== "") {
                this.form.name = newName;
                if(!this.todoOptions.includes(newName)) {
                    this.todoOptions = [...this.todoOptions, newName];
                }
            }
        },

        searchName: function (name) {
            this.nameSearch = name;
        },

        closeName: function () {
            this.addName(this.nameSearch)
        },

    },
    components: {
        ToggleSwitch,
        InputDate
    },

}
</script>
