<template>

    <div>
        <template v-for="element in elements">
            <div class="columns" v-for="row in rows">
                    <div class="column" v-for="col in row.cols">
                        <component
                            :is="col.component.type"
                            :component="col.component"
                            :new-item="element"
                            @buttonAction="payload => $emit('buttonAction', payload)"
                            @saveItem="(prop, value) => saveItem(prop, value, element)"
                            @saveItemDebounce="(prop, value) => saveItemDebounce(prop, value, element)"
                        />
                    </div>
            </div>
        </template>
    </div>


</template>

<script>

import Mixin from '../../tab';
import { TipTap } from '@components/form';
import HomepageInput from "@components/CRUD/Item/Tabs/Webflow/Components/HomepageInput";
import HomepageImage from "@components/CRUD/Item/Tabs/Webflow/Components/HomepageImage";
import HomepageEditor from "@components/CRUD/Item/Tabs/Webflow/Components/HomepageEditor";
import HomepagePriceTable from "@components/CRUD/Item/Tabs/Webflow/Components/HomepagePriceTable";
import HomepageStatus from "@components/CRUD/Item/Tabs/Webflow/Components/HomepageStatus";
import HomepageReady from "@components/CRUD/Item/Tabs/Webflow/Components/HomepageReady";
import HomepagePublish from "@components/CRUD/Item/Tabs/Webflow/Components/HomepagePublish";



export default {
    mixins: [ Mixin ],

    props: {
        component: {
            type: Object,
            required: true
        }
    },

    methods: {
        saveItem: function(prop, value, element) {
            this.$emit('saveItem', this.component.props.propertyArray, this.elements.map(item => ({
                ...item,
                ...item.id === element.id && {[prop]: value}
            })))
        },

        saveItemDebounce: function(prop, value, element) {
            this.$emit('saveItemDebounce', this.component.props.propertyArray, this.elements.map(item => ({
                ...item,
                ...item.id === element.id && {[prop]: value}
            })))
        },
    },

    computed: {
        elements: function() {
            return this.item[this.component.props.propertyArray];
        },
        rows: function() {
            return this.component.props.rows || [];
        },

        components: function () {
            return this.component.props.components
        }
    },

    components: {
        TipTap,
        HomepageInput,
        HomepageImage,
        HomepageEditor,
        HomepagePriceTable,
        HomepageStatus,
        HomepageReady,
        HomepagePublish,
    },
}
</script>
