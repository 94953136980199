<template>
    <div :class="{'is-additional': isAdditional}">
        <row v-on:dblclick.native="openModal" v-if="!hide">
            <template slot="type">
                <i class="material-icons">{{icon}}</i>
            </template>
            <template slot="name" :title="isSumRow && (isOther || isAirline) && allItemPrices.length > 0 ? name + ' + Zuschläge' : name">{{isSumRow && (isOther || isAirline) && allItemPrices.length > 0 ? name + ' + Zuschläge' : name }}</template>
            <template slot="date">{{date}}</template>
            <template slot="nights">
                <template v-if="days && isOther">{{days}} T</template>
                <template v-else-if="nights">{{nights}} N</template>
                <template v-else>-</template>
            </template>

            <template slot="marginTax">
                <margin-tax v-model="marginTaxEnabled" :tax="marginTax" :margin="grossMargin" />
            </template>

            <template slot="buyPriceCalculation" v-if="showExchangeRates">
                <span class="empty" v-if="hideInputs">-</span>
                <input-price
                    :linked="linked"
                    v-model="buyPriceInCalculationCurrency"
                    v-else/>
            </template>
            <template slot="buyPrice">
                <span class="empty" v-if="hideInputs">-</span>
                <span class="empty" v-else-if="isAtLocation">vor Ort</span>
                <input-price
                    :is-blue="hasTwoCurrencies"
                    :linked="isSumRow ? true : linked"
                    v-model="buyPrice"
                    :currency="currency"
                    :provider-prices="isSumRow ? []  : getProviderPrices(buyPriceType)"
                    v-else
                />
            </template>
            <template slot="sellPriceCalculation" v-if="showExchangeRates">
                <span class="empty" v-if="hideInputs">-</span>
                <input-price
                    v-model="sellPriceInCalculationCurrency"
                    v-else
                />
            </template>
            <template slot="sellPrice">
                <span class="empty" v-if="hideInputs">-</span>
                <input-price
                    v-model="sellPrice"
                    :provider-prices="isSumRow ? [] :  getProviderPrices(sellPriceType)"
                    :is-blue="hasTwoCurrencies"
                    v-else
                />
            </template>
            <template slot="profitCalculation" v-if="showExchangeRates">
                <span class="empty" v-if="hideInputs || isAtLocation">-</span>
                <input-price
                    v-model="profitInCalculationCurrency"
                    v-else/>
            </template>
            <template slot="profit">
                <span class="empty" v-if="hideInputs || isAtLocation">-</span>
                <input-price
                    v-model="profit"
                    :is-blue="hasTwoCurrencies"
                    v-else
                />
            </template>
            <template slot="margin">
                <span class="empty" v-if="hideInputs || isAtLocation">-</span>
                <input-price v-model="margin" v-else/>
            </template>
            <template slot="actions">
                <template v-if="isOther">
                    <i class="material-icons" v-on:click.prevent.stop="togglePriceType">
                        <template v-if="isPerPerson">people</template>
                        <template v-else>person</template>
                    </i>
                </template>
                <i class="material-icons" v-on:click.prevent.stop="toggleStatus">remove_shopping_cart</i>
                <i class="material-icons" v-on:click.prevent.stop="saveInCRUD" v-if="!isAirline">save</i>
            </template>
        </row>
        <div class="is-light" v-if="isHotel || isFerry || isTrain || isSumRow">
            <template v-if="isHotel">
                <night-provider name="Doppelzimmer + Zuschläge" v-bind:price="price" property="doubleRoom" :hideInputs="false" providerPriceProperty=""/>
                <night-provider name="Doppelzimmer | pro Nacht" v-bind:price="price" property="withoutItemPriceDoubleRoom" providerPriceProperty="doubleRoom" :hideInputs="true"/>
                <template v-for="item in doubleItemPricesBuyPrice">
                    <night-provider :name="item.name + ' | ' + getRequestItemTypeLabel(item)" v-bind:price="item" property="" :is-item-price="true" :key="item.key" :hideInputs="true" />
                </template>
                <night-provider name="Einzelzimmer + Zuschläge" v-bind:price="price" property="singleRoomPremium" :hideInputs="false" provider-price-property=""/>
                <night-provider name="Einzelzimmer | pro Nacht" v-bind:price="price" property="withoutItemPriceSingleRoomPremium" providerPriceProperty="singleRoomPremium" :hideInputs="true"/>
                <template v-for="item in singleItemPricesBuyPrice">
                    <night-provider :name="item.name + ' | ' + getRequestItemTypeLabel(item)" v-bind:price="item" property="" :is-item-price="true" :key="item.key" :hideInputs="true" />
                </template>

            </template>
            <template v-else-if="isFerry && placeholder.type === 'night'">
                <night-provider name="Doppelkabine + Zuschläge" v-bind:price="price" property="insideDoubleCabin" providerPriceProperty="" :hideInputs="false"/>
                <night-provider name="Doppelkabine" v-bind:price="price" property="withoutItemPriceInsideDoubleCabin" providerPriceProperty="insideDoubleCabin" :hideInputs="true"/>
                <template v-for="item in doubleItemPricesBuyPrice">
                    <night-provider :name="item.name + ' | ' + getRequestItemTypeLabel(item)" v-bind:price="item" property="" :is-item-price="true" :key="item.key" :hideInputs="true" />
                </template>
                <night-provider name="Buspreis | pro Gruppe" v-bind:price="price" property="bus" :hideInputs="true" providerPriceProperty="bus" />
                <night-provider name="Steuern - Bus | pro Gruppe" v-bind:price="price" property="busTax" providerPriceProperty="busTax" :hideInputs="true"/>
                <night-provider name="Überfahrt" v-bind:price="price" property="dayTicket" :hideInputs="true" provider-price-property="dayTicket"/>
                <night-provider name="Steuern - Überfahrt" v-bind:price="price" property="dayTicketTax" providerPriceProperty="dayTicketTax" :hideInputs="true"/>
                <night-provider name="Einzelkabine + Zuschläge" v-bind:price="price" property="insideSingleCabinPremium" providerPriceProperty="" :hideInputs="false"/>
                <night-provider name="Einzelkabine" v-bind:price="price" property="withoutItemPriceInsideSingleCabinPremium" providerPriceProperty="insideSingleCabinPremium" :hideInputs="true"/>
                <template v-for="item in singleItemPricesBuyPrice">
                    <night-provider :name="item.name + ' | ' + getRequestItemTypeLabel(item)" v-bind:price="item" property="" :is-item-price="true" :key="item.key" :hideInputs="true" />
                </template>
                <night-provider name="Doppelkabine Außen" v-bind:price="price" property="outsideDoubleCabin" providerPriceProperty="outsideDoubleCabin"/>
                <night-provider name="Einzelkabine Außen" v-bind:price="price" property="outsideSingleCabinPremium" providerPriceProperty="outsideSingleCabinPremium"/>
            </template>
            <template v-else-if="isFerry && placeholder.type === 'day'">
                <night-provider name="Überfahrt + Zuschläge" v-bind:price="price" property="dayTicket" providerPriceProperty=""/>
                <night-provider name="Überfahrt" v-bind:price="price" property="withoutItemPriceDayTicket" providerPriceProperty="dayTicket" :hideInputs="true"/>
                <template v-for="item in allItemPrices">
                    <night-provider :name="item.name + ' | ' + getRequestItemTypeLabel(item)" v-bind:price="item" property="" :is-item-price="true" :key="item.key" :hideInputs="true" />
                </template>
                <night-provider name="Buspreis | pro Gruppe" v-bind:price="price" property="bus" :hideInputs="true" providerPriceProperty="bus" />
                <night-provider name="Steuern - Bus | pro Gruppe" v-bind:price="price" property="busTax" providerPriceProperty="busTax" :hideInputs="true"/>
                <night-provider name="Steuern - Überfahrt" v-bind:price="price" property="dayTicketTax" providerPriceProperty="dayTicketTax" :hideInputs="true"/>
            </template>

            <template v-else-if="isOther">
                <night-provider :name="name"  v-if="allItemPrices.length > 0" v-bind:price="price" property="withoutItemPrice" providerPriceProperty="" :hideInputs="true"/>
                <template v-for="item in allItemPrices">
                    <night-provider :name="item.name + ' | ' + getRequestItemTypeLabel(item)" property="" :is-item-price="true" :price="item" :key="item.id" :hideInputs="true"/>
                </template>
            </template>
            <template v-else-if="isAirline">
                <night-provider :name="name"  v-if="allItemPrices.length > 0" v-bind:price="price" property="withoutItemPrice" providerPriceProperty="" :hideInputs="true"/>
                <template v-for="item in allItemPrices">
                    <night-provider :name="item.name + ' | ' + getRequestItemTypeLabel(item)" property="" :is-item-price="true" :price="item" :key="item.id" :hideInputs="true"/>
                </template>
            </template>
            <template v-else-if="isTrain && placeholder.type === 'night' && (placeholder.category === 'second' || placeholder.category === 'first_and_second')">
                <night-provider name="Doppelabteil + Zuschläge" v-bind:price="price" property="secondDoubleCabin" providerPriceProperty="" :hideInputs="false"/>
                <night-provider name="Doppelabteil" v-bind:price="price" property="withoutItemPriceSecondDoubleCabin" :hideInputs="true" providerPriceProperty="secondDoubleCabin"/>
                <template v-for="item in doubleItemPricesBuyPrice">
                    <night-provider :name="item.name + ' | ' + getRequestItemTypeLabel(item)" property="" :is-item-price="true" :price="item" :key="item.id" :hideInputs="true"/>
                </template>
                <night-provider  name="Einzelabteil + Zuschläge" v-bind:price="price" property="secondSingleCabinPremium" providerPriceProperty="" :hideInputs="false"/>
                <night-provider  name="Einzelabteil" v-bind:price="price" property="withoutItemPriceSecondSingleCabinPremium" :hideInputs="true" providerPriceProperty="secondSingleCabinPremium"/>
                <template v-for="item in singleItemPricesBuyPrice">
                    <night-provider :name="item.name + ' | ' + getRequestItemTypeLabel(item)" property="" :is-item-price="true" :price="item" :key="item.id" :hideInputs="true"/>
                </template>
                <template v-if="placeholder.category === 'first_and_second'">
                    <night-provider name="Zuschlag 1. Klasse im Doppelabteil" v-bind:price="price" property="firstDoubleCabin" providerPriceProperty="firstDoubleCabin" />
                    <night-provider name="Zuschlag 1. Klasse im Einzelabteil" v-bind:price="price" property="firstSingleCabinPremium" providerPriceProperty="firstSingleCabinPremium" />
                </template>
            </template>
            <template v-else-if="isTrain && placeholder.type === 'night' && placeholder.category === 'first'">
                <night-provider name="Doppelabteil 1. Klasse + Zuschläge" v-bind:price="price" property="firstDoubleCabin" providerPriceProperty="" :hideInputs="false"/>
                <night-provider  name="Doppelabteil 1. Klasse" v-bind:price="price" property="withoutItemPriceFirstDoubleCabin" :hideInputs="true" providerPriceProperty="firstDoubleCabin"/>
                <template v-for="item in doubleItemPricesBuyPrice">
                    <night-provider :name="item.name + ' | ' + getRequestItemTypeLabel(item)" property="" :is-item-price="true" :price="item" :key="item.id" :hideInputs="true"/>
                </template>
                <night-provider  name="Einzelabteil 1. Klasse + Zuschläge" v-bind:price="price" property="firstSingleCabinPremium" providerPriceProperty="" :hideInputs="false"/>
                <night-provider  name="Einzelabteil 1. Klasse" v-bind:price="price" property="withoutItemPriceFirstSingleCabinPremium" :hideInputs="true" providerPriceProperty="firstSingleCabinPremium"/>
                <template v-for="item in singleItemPricesBuyPrice">
                    <night-provider :name="item.name + ' | ' + getRequestItemTypeLabel(item)" property="" :is-item-price="true" :price="item" :key="item.id" :hideInputs="true"/>
                </template>
            </template>
            <template v-else-if="isTrain && placeholder.type === 'day' && (placeholder.category === 'second' || placeholder.category === 'first_and_second')">
                <night-provider name="Zugfahrt + Zuschläge" v-bind:price="price" property="secondSingleCabinPremium"/>
                <night-provider name="Zugfahrt" v-bind:price="price" providerPriceProperty="secondSingleCabinPremium" property="withoutItemPriceSecondSingleCabinPremium" :hideInputs="true"/>
                <template v-for="item in allItemPrices">
                    <night-provider :name="item.name + ' | ' + getRequestItemTypeLabel(item)" property="" :is-item-price="true" :price="item" :key="item.id" :hideInputs="true"/>
                </template>
                <template v-if="placeholder.category === 'first_and_second'">
                    <night-provider name="1. Klasse - Zuschlag" v-bind:price="price" property="firstSingleCabinPremium" provider-price-property="firstSingleCabinPremium"/>
                </template>
            </template>
            <template v-else-if="isTrain && placeholder.type === 'day' && (placeholder.category === 'first')">
                <night-provider name="1. Klasse + Zuschläge" v-bind:price="price" property="firstSingleCabinPremium" provider-price-property=""/>
                <night-provider name="1. Klasse" v-bind:price="price" providerPriceProperty="firstSingleCabinPremium" property="withoutItemPriceFirstSingleCabinPremium"  :hideInputs="true"/>
                <template v-for="item in allItemPrices">
                    <night-provider :name="item.name + ' | ' + getRequestItemTypeLabel(item)" property="" :is-item-price="true" :price="item" :key="item.id" :hideInputs="true"/>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import { serviceIcons } from '@utilities/variables';
import placeholderPriceMixin from '@calculations/placeholderPriceMixin';

import price from './price';

import NightProvider from './NightProvider';
import AirlinePackage from './AirlinePackage';
import Template from "@orders/subpages/TripDetails/General/Template";

export default {
    mixins: [ price, placeholderPriceMixin ],

    components: {
        Template,
        NightProvider,
        AirlinePackage,
    },

    computed: {
        isSumRow () {
            return (this.allItemPrices.length > 0);
        },

        allItemPrices () {
            return this.getItemPrices();
        },
        ferryDoubleShowSumRows: function() {
          if (this.isFerry && (this.doubleItemPricesBuyPrice.length > 0 || this.price.busBuyPrice.amount !== '0.00' || this.price.dayTicketBuyPrice.amount !== '0.00')) {
              return true
          } else {
              return false;
          }
        },

        doubleItemPricesBuyPrice: function() {
            return this.getItemPrices(['per_group', 'per_person', 'per_person_night', 'custom']);

        },

        singleItemPricesBuyPrice: function() {
            if(this.supplementPrices) {
                return this.getItemPrices(['per_single_room_night', 'per_single_room']);
            } else {
                this.getItemPrices(['per_group', 'per_person', 'per_person_night', 'custom', 'per_single_room_night', 'per_single_room']);
            }
        },

        hideInputs: function () {
            return (this.isFerry && this.placeholder.type === 'night') || this.isHotel || this.isAirlineIncluded || (this.isTrain) || (this.isSumRow && !this.isOther && !this.isAirline)
        },

        icon: function () {
            if (this.price._type === 'other') {
                return serviceIcons[this.placeholder.otherServiceType.category]
            } else if (this.isHotel) {
                return 'local_hotel'
            } else if (this.isFerry) {
                return 'directions_boat'
            } else if (this.isTrain) {
                return 'directions_train'
            } else if (this.price._type === 'airline') {
                return 'airplanemode_active'
            }
        },
        status: function () {
            if (this.price.placeholder.clientOffer.status === 'atLocation') {
                return 'vor Ort';
            }
        },

        isAdditional: function () {
            return this.price.placeholder.clientOffer.status === 'additional';
        },

        isAtLocation: function () {
            return this.price.placeholder.clientOffer.status === 'atLocation';
        },

        isHotel: function () {
            return this.price._type === 'hotel'
        },

        isFerry: function () {
            return this.price._type === 'ferry'
        },

        isTrain: function () {
            return this.price._type === 'train'
        },

        isDayTrain: function () {
            return this.price._type === 'train' && this.placeholder.type === 'day';
        },

        isAirline: function () {
            return this.price._type === 'airline'
        },

        isAirlineIncluded() {
            if (this.isAirline) {
                const flights = this.$store.getters.notIncludedPrices.airlinePrices
                    .filter(flight => flight.placeholder.id !== this.placeholder.id)
                    .filter(flight => flight.airlinePrice)
                    .find(flight => flight.airlinePrice === this.price.id);

                return !!flights
            }

            return false;
        },

        // Loaded prices
        buyPriceType: function() {  return 'buyPrice'; },
        sellPriceType: function() {  return 'sellPrice'; },
    },

    methods: {
        getRequestItemTypeLabel (item) {
            let addition = item.quantityType === 'custom' ? ': ' + item.quantity : '';
            return this.$store.state.options.quantityType.find(type => type.key === item.quantityType).value + addition || '';
        },

        openModal: function() {
            this.$store.commit('updateModalPlaceholder', this.price)
        },
        getItemPrices: function(statuses = []) {
            if (this.$store.state.prices && this.$store.state.prices.itemPrices && this.$store.state.prices.itemPrices.length > 0) {
                return this.$store.state.prices.itemPrices.filter(itemPrice =>
                    itemPrice.calculationInfo.placeholderId === this.price.placeholder.id &&
                    itemPrice.inCalculation &&
                    (statuses.includes(itemPrice.quantityType) || statuses.length === 0 )
                ).map(price => ({...price, placeholder: this.price.placeholder}));
            }
            return [];
        },

        saveInCRUD: function() {
            this.$store.commit('updateModalCRUD', this.price)
        }
    }
}
</script>
