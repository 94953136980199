<template>
    <modal-form
        editTitle="Bankverbindung editieren"
        createTitle="Neue Bankverbindung"
    >
        <table>
            <tr>
                <th><label for="iban" class="label">IBAN:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="iban" class="input" v-model="form.iban" @blur="getBankInformation(form.iban)">
                    </div>
                </td>
            </tr>

            <tr>
                <th><label for="bic" class="label">BIC:</label></th>
                <td>
                  <div class="field has-addons">
                    <div class="control is-expanded">
                      <input type="text" id="bic" class="input" v-model="form.bic" :disabled="bankDataDisabled">
                    </div>
                    <span class="control ml-2">
                        <i class="material-icons is-pointer" @click="bankDataDisabled = !bankDataDisabled">edit</i>
                    </span>
                  </div>

                </td>
            </tr>

            <tr>
              <th><label for="bic" class="label">Bank:</label></th>
              <td>
                <div class="field has-addons">
                  <div class="control is-expanded">
                    <input type="text" id="bank" class="input" v-model="form.bank" :disabled="bankDataDisabled">
                  </div>
                  <span class="control ml-2">
                          <i class="material-icons is-pointer" @click="bankDataDisabled = !bankDataDisabled">edit</i>
                      </span>
                </div>

              </td>
            </tr>


            <tr>
                <th><label for="accountHolder" class="label">Kontoinhaber:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="accountHolder" class="input" v-model="form.accountHolder">
                    </div>
                </td>
            </tr>

            <tr>
                <th class="is-top"><label for="notes" class="label">Zusätzliche Notizen:</label></th>
                <td>
                    <div class="control">
                        <tip-tap v-model="form.notes" />
                    </div>
                </td>
            </tr>

            <tr>
                <th><label for="primaryAccount" class="label">Primärkonto:</label></th>
                <td>
                   <div class="control">
                       <label class="radio">
                            <input type="radio" v-bind:value="true" v-model="form.primaryAccount">ja
                       </label>
                       <label class="radio">
                            <input type="radio" v-bind:value="false" v-model="form.primaryAccount">nein
                       </label>
                    </div>
                </td>
            </tr>
        </table>
    </modal-form>
</template>

<script>
import { TipTap } from '@components/form'
import TabForm from '../form';
import axios from "axios";

export default {
    mixins: [TabForm],

    form: {
        iban: '',
        bic: '',
        bank: '',
        accountHolder: '',
        notes: '',
        primaryAccount: true,
    },

    data () {
        return {
            bankDataDisabled: true,
        }
    },

    property: 'bankAccounts',

    methods: {
        getBankInformation (iban) {
          if(iban) {
            axios('https://openiban.com/validate/' + iban + '?getBIC=true&validateBankCode=true').then(response => {
              if(response.data.valid && response.data.bankData) {
                  this.form.bic = response.data.bankData.bic;
                  this.form.bank = response.data.bankData.name;
              }
            }, error => {
              this.bankDataDisabled = false;
            })
          }
        }
    },

    components: {
        TipTap
    },
}
</script>
