<template>
        <div
            class="placeholder-view"
            style="padding-left: 0; padding-right: 0"
            v-bind:class="{
            'has-requests'             : true,
            'is-optional'              : isOptional(placeholder),
            'is-additional'            : placeholder.clientOffer.status === 'additional',
        }"
        >
            <div v-bind:title="placeholder.serviceDescription">
                <i class="material-icons" title="Hotel" v-if="placeholder.placeholderType === 'hotel'">home</i>
                <i class="material-icons" title="Fähre" v-if="placeholder.placeholderType === 'ferry'">directions_boat</i>
                <other-service-icons v-if="placeholder.placeholderType === 'other'" v-bind:type="placeholder.otherServiceType.category"></other-service-icons>
                <i class="material-icons" title="Zug" v-if="placeholder.placeholderType === 'train'">directions_train</i>
                <i class="material-icons" title="Flug" v-if="placeholder.placeholderType === 'airline'">airplanemode_active</i>

                <template v-if="placeholder.clientOffer.status === 'optional'">Möglichkeit: </template>
                <template v-else-if="placeholder.clientOffer.status === 'atLocation'">vor Ort: </template>
                {{ placeholder.serviceDescription }}
            </div>

            <!--        time slot-->

<!--            <div class="is-100">
                <time-of-day v-if="placeholder.startTime" v-bind:start="placeholder.startTime" v-bind:end="placeholder.endTime"></time-of-day>
            </div>-->

            <div class="action-buttons"><slot></slot></div>



        </div>

</template>



<script>
import TimeOfDay from "@orders/placeholders/components/views/TimeOfDay.vue";
import OtherServiceIcons from '@components/OtherServiceIcons';
import Accordion from "@components/Accordion.vue";


export default {
    components: {
        Accordion,
        TimeOfDay,
        OtherServiceIcons
    },

    props: {
        placeholder: { type: Object,  required: true },
    },

    methods: {
        isOptional(placeholder) {
            return placeholder.clientOffer.status === 'optional' ||
                placeholder.clientOffer.status === 'atLocation';
        }
    }
}
</script>
