<template>
    <columns property="infos" v-on:add="openForm" :filter="{ type: 'note' }">

        <column-item
            v-bind:update="{updatedBy: item.updatedBy, updatedAt: item.updatedAt}"
            :api="'/api/infos/' + item.id"
            slot-scope="{item}"
            v-on:edit="openForm(item)"
            v-on:delete="deleteItem('infos', item)"
            v-bind:label="importance(item)"
            class="is-flex"
        >
            <div class="content-grow">
                <ul v-if="item.title">
                    <li><strong>{{item.title}}</strong></li>
                    <hr class="my-sm mb-sm" v-if="item.content || item.images.length > 0">
                </ul>

                <template v-if="item.images.length > 0">
                    <div class="columns is-multiline mb-0">
                        <div class="column is-one-third" v-for="image in item.images" v-bind:key="image.id">
                            <a v-bind:href="image.publicPath" target="_blank" class="image">
                                <img v-bind:src="image.publicPath" v-bind:alt="image.title">
                            </a>
                        </div>
                    </div>
                    <hr class="my-sm mb-sm" v-if="item.content">
                </template>

                <p v-if="item.content" class="nl2br" v-html="item.content"></p>
            </div>

            <hr class="mt-sm mb-sm" v-if="item.sections.length > 0 || item.showInConnectedProviders">

            <div class="buttons" style="padding-bottom: 20px" v-if="item.sections.length > 0 || item.showInConnectedProviders">
                <template v-if="item.sections">
                    <span class="button is-small" v-if="item.agency && api !== 'agencies' && item.showInConnectedProviders"> <a  style="color:#60A4AB" :href="`/agencies/${item.agency.id}`">von {{item.agency.name}}</a></span>
                    <span class="button is-small" v-if="api === 'agencies' && item.showInConnectedProviders"> <a style="color:#60A4AB">Gültig für verbundene Anbieter</a></span>
                    <span class="button is-small" v-for="section in item.sections" v-bind:key="section">{{sectionName(section)}}</span>

                </template>
            </div>

        </column-item>

        <info-form ref="form" slot="form" />
    </columns>
</template>

<script>
import has from 'lodash/has';

import { infoSections } from '@clientSpecific/utilities/variables';
import { getValueWithKey } from '@utilities/functions';

import Mixin from '../tab';
import InfoForm from './Form';
import {getAxios} from "@api";

export default {
    mixins: [ Mixin ],
    components: { InfoForm },
    methods: {
        sectionName: function (section) {
            if (has(infoSections, section)) {
                return infoSections[section];
            }
            return section;
        },

        importance: function (item) {
            return getValueWithKey({key: item.importance, optionList: this.options.infos ? this.options.infos.importance : []})
        },
    },
    computed: {
        api: function () {
            return this.$store.state.api; },

        getFilter () {
            return {
                ...['hotels', 'ferries', 'others', 'trains', 'airlines'].includes(this.api) && {'provider.id': this.item.id},
                ...this.api === 'agencies' && {'agency.id': this.item.id},
                ...this.api === 'clients' && {'client.id': this.item.id},
                ...this.api === 'destinations' && {'destination.id': this.item.id},
                ...this.api === 'areas' && {'area.id': this.item.id},
                ...this.api === 'places' && {'place.id': this.item.id},
                ...this.api === 'predefined_route_details' && {'predefinedRouteDetail.id': this.item.id},
                ...this.api === 'other_service_types' && {'otherServiceType.id': this.item.id},
            }
        }
    },
    mounted () {
        this.$store.state.data.infos = [];
        getAxios('infos', {_groups: ['image_read'], ...this.getFilter}).then(response => {
            this.$store.state.data.infos = response.data;
        })
    }
}
</script>
