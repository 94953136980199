<template>
    <div v-bind:class="['email', classNames, !incoming ? 'is-outgoing' : '']">

        <div class="column">
            <div>
                <div class="user-image"><user-image :user="email.user"/></div>
                <i class="material-icons phone is-turqoise-text" v-if="email.type === 'phone'">phone</i>
                <i class="material-icons incoming" style="color: limegreen" v-else-if="incoming">send</i>
                <i class="material-icons" style ="color: darkblue" v-else>send</i>
            </div>

        </div>

<!--        <div class="column">
            <i class="material-icons" style="color: limegreen" v-if="incoming">archive</i>
            <i class="material-icons" style ="color: darkblue" v-if="!incoming">send</i>

        </div>-->


        <div class="column">
            <div >
                <div class="title" v-if="fromName" :title="incoming ? email.fromAddress : email.toAddresses && email.toAddresses[0].email">{{ fromName }}</div>
                <div class="title" v-if="!fromName">{{ incoming ? email.fromAddress : email.toAddresses && email.toAddresses[0].email }}</div>
                <div class="date">{{ email.date }}</div>
            </div>

<!--            <div class="title" v-if="incoming">{{ email.fromAddress }}</div>
            <div class="title" v-if="!incoming">{{ email.toAddresses[0].email }}</div>-->
            <div class="description" v-if="email.contact && email.contact.emailInfos && email.contact.emailInfos.length > 0"><a :href="email.contact.emailInfos[0]">{{email.contact.emailInfos[1]}}</a></div>
            <div class="contact" v-if="!email.contact">nicht zugeordnet</div>

            <div class="description">{{ email.subject }}</div>


                <div class="buttons email-view" v-if="(email.tags && email.tags.length > 0) || nrOfAttachments > 0">
                    <!--                 <a
                                        v-if="email.links.length === 1"
                                        class="tag is-white"
                                        v-bind:href="'/orders/' + email.links[0].order.id"
                                    >
                                        <i aria-hidden="true" class="fa fa-folder-open has-margin-right"></i>
                                        {{email.links[0].order.id}}
                                    </a>

                                    <span
                                        v-if="email.links.length > 1"
                                        class="tag"
                                        title="It has multiple orders"
                                    >mehrere Reisen</span>-->
                    <template v-if="email.tags && email.tags.length > 0">
                        <span v-for="tag in email.tags" :key="tag" class="button is-small" style="overflow: hidden; justify-content: left">{{ tag }}</span>
                    </template>

                </div>


            <div class="attachments">
                <i class="material-icons" v-if="nrOfAttachments > 0">attach_file</i>
            </div>
        </div>

    </div>


</template>



<script>
import { dateISOtoView, fullYear } from '@utilities/functions';
import store from '../store';
import moment from 'moment'
import UserImage from '@components/UserImage';
import {settings} from "@clientSpecific/utilities/settings";


export default {
    components: {UserImage},
    store: store,


    props: {
        email:      { type: Object, required: true },
        classNames: { type: String, required: true }
    },

    methods: {
        moment
    },


    computed: {
        isPhone: function () {
            return this.email.type === "phone";
        },
        fromName: function () {
            if (this.isPhone) {
                return this.email.contact && this.email.contact.emailInfos && this.email.contact.emailInfos.length > 0 && this.email.contact.emailInfos[2];
            } else {
                if(this.incoming){
                    return this.email.fromName;
                } else if(this.email.toAddresses && this.email.toAddresses.length > 0 && this.email.toAddresses[0].name) {
                    return this.email.toAddresses[0].name;
                }
                return null;
            }
        },
        nrOfAttachments: function () {

            if (this.email.attachments) {
                return this.email.attachments.filter(attachment => attachment.disposition !== 'inline' || attachment.type !== 'image').length;

            } else {
                return 0;
            }

        },


        hasOrders: function () {
            if (this.email.links.length > 0) {
                for (let i = 0, len = this.email.links.length; i < len; i++) {
                    if (this.email.links[i].order) {
                        return true;
                    }
                }
            }

            return false;
        },

        incoming: function() {
            return this.email && this.email.fromAddress && !settings.emails.organisationHosts.some(el => this.email.fromAddress.includes(el));
        }
    },


    filters: {
        dateISOtoView,
        fullYear
    }
}
</script>
