<template>
    <modal-form
        editTitle="Zuschlag/Eintritt bearbeiten"
        createTitle="Neuen Zuschlag/Eintritt hinzufügen"
    >
        <table>
            <tr>
                <th>
                    <label for="name" class="label">Zuschlag:</label>
                </th>
                <td>
                    <div class="control">
                        <input type="text" class="input" v-model="form.name" />
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="priceType" class="label">Preisart:</label></th>
                <td>
                    <div class="control">
                        <multiselect
                            v-model="quantityType"
                            v-bind:options="quantityTypes"
                            trakc-by="key"
                            label="value"
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="price" class="label">Preis:</label></th>
                <td>
                    <input-price id="price" v-model="amount" class="is-medium" :prefix="currency" />
                </td>
            </tr>

            <tr>
                <th><label for="currency" class="label">Währung:</label></th>
                <td>
                    <multiselect
                        v-model="currency"
                        :options="options.potentialCurrencies ? options.potentialCurrencies : ['EUR']"
                        placeholder="EUR"
                        class="is-small"
                        :allow-empty="false"
                    />
                </td>
            </tr>

            <tr>
                <th>
                    <label for="" class="label">Gültigkeit:</label>
                </th>
                <td>
                    <div class="level">
                        <div class="level-left">
                            <div class="level-item">
                                <div class="control is-expanded">
                                    <multiselect
                                        v-model="limitType"
                                        v-bind:options="limitTypes"
                                        track-by="key"
                                        label="value"
                                        class="is-full-width"
                                    />
                                </div>
                            </div>
                            <div class="level-item">
                                <input type="text" class="input is-small" v-model.number="form.minPersons" placeholder="Min" />
                            </div>
                            <div class="level-item">
                                <span>-</span>
                            </div>
                            <div class="level-item">
                                <input type="text" class="input is-small" v-model.number="form.maxPersons" placeholder="Max" />
                            </div>
                            <div class="level-item">
                                <template v-if="form.quantityType === 'per_single_room'"><span>EZ</span></template>
                                <template v-else><span>Pax</span></template>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>

            <tr>
                <th>
                    <label for="startAt" class="label">Gültig von:</label>
                </th>
                <td>
                    <input-date v-model="form.startAt" class="is-small" v-if="visible"/>
                </td>
            </tr>

            <tr>
                <th>
                    <label for="endAt" class="label">Gültig bis:</label>
                </th>
                <td>
                    <input-date v-model="form.endAt" class="is-small" v-if="visible"/>
                </td>
            </tr>

            <!--<tr>
                <th><label for="billingType" class="label">Einfügen als:</label></th>
                <td>
                    <multiselect
                        v-bind:options="billingTypes"
                        v-model="billingType"
                        track-by="key"
                        label="value"
                        class="is-medium"
                    />
                </td>
            </tr>-->

            <!--<tr>
                <th></th>
                <td>
                    <div class="control">
                        <label class="checkbox">
                            <input type="checkbox" v-bind:value="true" v-model="form.automaticallyAddToOrder">Zuschlag automatisch in jeder Reise hinzufügen
                        </label>
                    </div>
                </td>
            </tr>-->
        </table>
    </modal-form>
</template>

<script>
import TabForm from '../form'
import { InputPrice, InputDate, ToggleSwitch } from '@components/form';
import {omit} from "lodash";

export default {
    mixins: [ TabForm ],

    form: {
        name: '',
        quantityType: 'per_person',
        type: 'supplement',
        price: {
            amount: '0',
            currency: 'EUR'
        },
        startAt: null,
        endAt: null,
        primaryPrice: false,
        automaticallyAddToOrder: false,
        minPersons: null,
        maxPersons: null,
        billingType: null,
        limitType: ''
    },

    property: 'itemPrices',

    components: {
        InputPrice, InputDate, ToggleSwitch
    },

    methods: {
        handleSubmit: function () {
            this.form = omit(this.form, ['provider']);
            this.handleFormSubmit();
        },
    },

    computed: {
        quantityTypes: function () {
            return this.options && this.options.quantityType ? this.options.quantityType : []
        },

        limitTypes: function() {
            return this.options && this.options.limitType ? this.options.limitType : []
        },

        quantityType: {
            get: function () {
                return this.quantityTypes.find(type => type.key === this.form.quantityType)
            },

            set: function (value) {
                this.form.quantityType = !!value ? value.key : null
            }
        },

        limitType: {
            get: function () {
                return this.limitTypes.find(type => type.key === this.form.limitType)
            },

            set: function (value) {
                this.form.limitType = !!value ? value.key : null
            }
        },

        billingTypes: function () {
            return this.options.billingType ? this.options.billingType : []
        },

        currency: {
            get: function () {
                return this.form && this.form.price && this.form.price.currency ? this.form.price.currency : 'EUR'
            },

            set: function (value) {
                this.form.price.currency = value
            }
        },

        amount: {
            get: function () {
                return this.form && this.form.price && this.form.price.amount ? this.form.price.amount : 'EUR'
            },

            set: function (value) {
                this.form.price.amount = value
            }
        },

        billingType: {
            get: function () {
                return this.billingTypes.find(billingType => billingType.key === this.form.billingType)
            },
            set: function (value) {
                this.form.billingType = !!value ? value.key : null
            }
        }
    }
}
</script>
