<template>
    <emails
        v-bind:preselected-order-concept="item"
        titleText="ZUGEORDNETE EMAILS"
        isSimpleList
    ></emails>

</template>

<script>
import Mixin from '../tab';
import Emails from "@components/emails/index.vue";

export default {
    mixins: [ Mixin ],

    components: {Emails },

    data: function () {
        return {
        }
    },
}
</script>
