<template>
    <aside class="emails-list">
        <div class="emails-list_categories">

            <div class="organisation-select">
                <email-select v-model="emailSelected" v-on:input="changeMailbox"/>
                <button
                    class="button is-larger"

                    @click.prevent.stop="getFolder(true)"
                >
                    <i class="material-icons">loop</i> Aktualisieren
                </button>
            </div>

            <div class="new-email-button">
                <button
                    class="button is-success is-large"
                    v-on:click="isModalVisible = true"
                ><i aria-hidden="true" class="fa fa-plus-circle"></i> Neue Email</button>
            </div>

            <div class="content" v-if="dataReady">
                <folder-button
                    v-for      = "(folderObject, folderName) in folders"
                    v-bind:key = "folderName"
                    v-bind:folderName     = "folderName"
                    v-bind:folder         = "getFolderByPath(folderObject.folder)"
                    v-bind:folderSelected = "folderSelected"
                    class                 = "is-0"
                    v-on:selectFolder     = "value => selectFolder(value)"
                ></folder-button>
            </div>

            <loading is-vertical v-else></loading>
        </div>



        <div class="emails-list_items">
            <!-- <header>Emails <span v-if="folders[folderSelected].list">{{ folders[folderSelected].list.length }}</span></header> -->
            <header>
                <h2>Emails</h2>
                <span v-if="list">{{ list.length }}</span>
            </header>

            <div class="emails-list-filters">
                <i aria-hidden="true" class="fa fa-filter"></i>
                <div class="select">
                    <select v-model="daysAgo">
                        <option value="7">7 Tage</option>
                        <option value="14">14 Tage</option>
                        <option value="30">30 Tage</option>
                        <option value="90">90 Tage</option>
                        <option value="-1">ohne Limit</option>
                    </select>
                </div>
            </div>


            <div class="content">
                <loading v-if="isLoading" />

                <template v-if="list">
                    <ListItem
                        v-for="email in list"
                        v-bind:key="email.uid"

                        v-bind:email="email"
                        v-bind:classNames="emailClass(email)"

                        v-on:click.native="event => $store.commit('updateEmailsSelected', { event, email })"
                    ></ListItem>
                </template>

                <div class="is-empty" v-if="list !== null && list.length === 0">In diesem Ordner befinden sich keine E-Mails.</div>
            </div>
        </div>
        <!-- <div class="buttons-wrapper">
            <button
                class="button is-large"
                v-bind:class="{ 'is-primary' : emailsSelected.direction === 'in' }"
                v-on:click="$store.commit('updateDirection', 'in')"
            ><i class="material-icons">arrow_downward</i> Posteingang</button>

            <button
                class="button is-large"
                v-bind:class="{ 'is-primary' : emailsSelected.direction === 'out' }"
                v-on:click="$store.commit('updateDirection', 'out')"
            ><i class="material-icons">arrow_upward</i> Postausgang</button>
        </div> -->


        <!-- <template v-if="areServerEmailsReady">
            <accordion
                v-for="type in filteredTypes"
                v-bind:key="type.type"
                v-if="emailsSelected.direction === type.direction"
            >
                <h4 slot="header" v-bind:class="'is-' + type.type.replace('travelize.', '')">
                    {{ type.title }}
                    <span class="nr-of-emails">{{ emails[emailsSelected.direction][type.type].length }}</span>
                </h4>

                <div slot="content">
                    <draggable
                        v-bind:list="emails[emailsSelected.direction][type.type]"
                        class="dragArea"
                        v-bind:options="{ group: 'emails', disabled: draggableIsDisabled }"
                        v-on:change="event => move(event, type.type)"
                    >
                        <loading v-if="type.type.indexOf('travelize') === -1 && !areLiveEmailsReady"></loading>

                        <ListItem
                            v-else
                            v-for="email in emails[emailsSelected.direction][type.type]"
                            v-bind:key="getKey(email)"

                            v-bind:email="email"
                            v-bind:classNames="emailClass(email)"

                            v-on:click.native="event => $store.commit('updateEmailsSelected', { event, email })"
                        ></ListItem>
                    </draggable>
                </div>
            </accordion>
        </template>

        <loading v-else></loading> -->


        <new-email
            v-if = "isModalVisible"
            v-bind:isModalVisible   = "isModalVisible"
            v-bind:preselected-order = "preselectedOrder"
            v-on:closeModal = "closeModal"
        ></new-email>
    </aside>
</template>



<script>
import axios from 'axios';
import get from 'lodash/get';
import set from 'lodash/set';
import Draggable from 'vuedraggable';

import Loading from '@components/Loading';
import { notifyError, notifySuccess } from '@components/Notification';
import { addEmail, editEmail, optionsEmails } from '@api';
import {  Multiselect } from '@components/form';

import NewEmail   from '../modals/newEmail/index';
import ListItem from './Item';
import mixins from './mixins';
import FolderButton from './FolderButton';
import {compareDates, currentUser} from "@utilities/functions";
import EmailSelect from "@components/form/EmailSelect";


export default {
    mixins: [mixins],


    props: {
        preselectedOrder: { default: null },
        useLiveEmails:    { type: Boolean, required: true }
    },


    computed: {
        filteredTypes: function () {
            if (this.useLiveEmails) {
                return this.types;

            } else {
                return this.types.filter(item => item.type.includes('travelize'));
            }
        },


        updateFolder: function () { return this.$store.state.updateFolder; },
        folderObject: function () {
            const path = this.folderSelected;
            return get(this.folders, path)
        },
        daysFolder:   function () {
            return get(this.folderObject, ['lists', this.daysAgo], {
                items: [],
                fetched: null
            })
        },
        list:         function () { return this.daysFolder.items },
        isLoading:    function () { return this.$store.state.isLoading || this.$store.state.loadingFolder },
    },


    data: function () {
        return {
            call: null,
            folderSelected: 'INBOX',
            emailSelected: null,
            folders: {
                'INBOX': {
                    name: 'Posteingang',
                    icon: 'email',
                    level: 0,
                    lists: {},
                    folder: 'INBOX'
                },
            },
            daysAgo: 7,

            isModalVisible:      false,
            draggableIsDisabled: false,
            dataReady:           false,
            refreshing:          false
        }
    },


    methods: {
        selectFolder: function (folder) {
            this.folderSelected = folder;
            this.getFolder();
        },


        getFolderByPath: function(urlPath) {
            const path = urlPath.split('/').join('/subfolders/').split('/');
            return get(this.folders, path)
        },


        getFolder: function (force = false) {
            let folder = this.getFolderByPath(this.folderSelected);
            const { fetched } = this.daysFolder;

            if (!fetched || (+new Date() - fetched > 300000) || force) {
                this.$store.commit('loadingFolder', true);
                this.updateList([]);

                this.apiCallWithCancel({
                    method: 'get',
                    url: '/api/emails/live/get?folder=' + this.folderSelected + '&daysAgo=' + this.daysAgo,
                })
                    .then(response => {
                        const items = response.data;
                        const firstEmail = items.length > 0
                            ? [items[0]]
                            : [];

                        this.updateList(items);
                        this.$store.commit('updateChangeFolder', firstEmail);
                    }, error => { })
                    .then(() => {
                        this.$store.commit('updateFolder', false);
                        this.$store.commit('loadingFolder', false);
                        this.$store.commit('updateLoading', false);
                    });
            } else {
                const list = get(folder, ['lists', this.daysAgo, 'items'], []);
                let firstEmail = list && list.length > 0
                    ? [list[0]]
                    : [];

                this.$store.commit('updateChangeFolder', firstEmail);

                if (this.call) {
                    this.call.cancel();
                }
            }
        },


        updateList(items) {
            const path = this.folderObject.folder.split('/').join('/subfolders/').split('/');
            const folder = get(this.folders, path);
            set(this.folders, path, {
                ...folder,
                ...folder,
                lists: {
                    ...folder.lists,
                    [this.daysAgo]: {
                        items,
                        fetched: items.length > 0
                            ? + new Date()
                            : null
                    }
                }
            })
        },


        move: function (event, folderType) {
            if (event.added) {
                if (this.$store.getters.isLive(folderType) === true) {
                    notifyError('Sie können dort keine Emails verschieben!');
                    return false;
                }

                let email = event.added.element;

                // For preventing DND
                this.draggableIsDisabled = true;
                this.$store.commit('updateLoading', true);

                if (this.$store.getters.isLive(email)) {
                    // If it is on Live we need to add it to the Server
                    addEmail(email, folderType).then(response => {
                            this.$store.commit('movedFromLive', { old: email, new: response.data });
                            // email = response.data;
                            notifySuccess('Email verschoben!');

                        }, error => {
                            // TODO - revert the move
                            notifyError('Konnte die email nicht hinzufügen! Server Error!');
                        }).then(() => {
                            this.draggableIsDisabled = false;
                            this.$store.commit('updateLoading', false);
                        });

                } else {
                    // We just update the folder
                    editEmail({ id: email.id, data: { id: email.id }, params: '?toFolder=' + folderType }).then(response => {
                            this.$store.commit('updateEmailOnServer', response.data);
                            notifySuccess('Email verschoben!');

                        }, error => {
                            // TODO - revert the move
                            notifyError('Konnte die email nicht hinzufügen! Server Error!');
                        }).then(() => {
                            this.draggableIsDisabled = false;
                            this.$store.commit('updateLoading', false);
                        });
                }
            }
        },


        selectEmailFromExternal: function () {
            // Viewing the email selected on another page passed here via URL
            let urlParams = new URLSearchParams(window.location.search);

            if (urlParams.get('fe-external-id')) {
                const email = this.$store.getters.email({
                        id:     urlParams.get('fe-external-id'),
                        folder: urlParams.get('fe-external-folder')
                    });

                if (email) {
                    // TODO - new select
                    // TODO - test
                    this.$store.commit('updateEmailsSelected', { email });
                }
            }
        },


        emailClass: function (email) {
            const emails = this.emailsSelected[this.emailsSelected.direction];

            for (let i = 0, len = emails.length; i < len; i++) {
                if (email.id ? emails[i].id === email.id : emails[i].uid === email.uid) {
                    return 'is-selected';
                }
            }

            return '';
        },


        closeModal: function () {
            this.$store.commit('updateAction', null);
            this.isModalVisible = false;
        },

        createFolderStructure: function (folderName, localStructure, folderStructure, level, path) {

            if (folderStructure && folderStructure.length !== 0) {
                // it has subfolders
                localStructure[folderName].level      = level;
                localStructure[folderName].subfolders = {};
                localStructure[folderName].folder    = (path ? path + '/' : '') + folderName;
                Object.keys(folderStructure).forEach(subfolder => {
                    localStructure[folderName].subfolders[subfolder] = {
                        lists: {},
                        level: level + 1,
                        subfolders: {},
                        folder: localStructure[folderName].folder + '/' + subfolder,
                    };
                    this.createFolderStructure(subfolder, localStructure[folderName].subfolders, folderStructure[subfolder], level + 1, localStructure[folderName].folder);
                });
            }
        },

        fetchCurrentEmail: function () {
            let currentOrganisation = "/api/organisations/" + currentUser('currentOrganisation').id;
            this.emailSelected = currentUser('userEmails').find(email => email.organisation === currentOrganisation);
        },

        fetchFolders: function() {
            this.dataReady = false;

            optionsEmails('?_groups[]=organisation_email_folder').then(response => {
                this.folders = response.data;

                for(const folder in this.folders){
                    if(folder.isDefault){
                        this.folderSelected = folder.folder;
                    }
                }

                //this.folderSelected = this.folders.find(t => t.default === true);
                this.$store.commit('updateAction', null);

                axios.get('/api/emails/live/folders').then(response => {
                    let foldersStructure = response.data,
                        level   = 0;

                    Object.keys(this.folders).forEach(folderName => {
                        this.createFolderStructure(folderName, this.folders, foldersStructure[folderName], 0, '');
                    });



                    this.dataReady = true;
                });
            });


        },

        apiCallWithCancel: function(config = {}) {
            if (this.call) {
                this.call.cancel();
            }
            this.call = axios.CancelToken.source();

            config.cancelToken = this.call.token;
            return axios(config);
        },

        changeMailbox: function () {
            this.fetchFolders();
            this.getFolder();
            this.fetchCurrentEmail();
        }
    },


    created: function () {
        this.fetchFolders();
        this.getFolder();
        this.fetchCurrentEmail();
    },


    watch: {
        daysAgo: function () {
            this.getFolder();
        },


        updateFolder: function () {
            if (this.updateFolder) {
                this.$store.commit('updateLoading', true);
                this.getFolder(true);
            }
        },


        /*areServerEmailsReady: function () {
            if (this.areServerEmailsReady && !this.areLiveEmailsReady) {
                this.selectEmailFromExternal();
            }
        },

        areLiveEmailsReady: function () {
            if (this.areServerEmailsReady && !this.areLiveEmailsReady) {
                this.selectEmailFromExternal();
            }
        }*/
    },


    components: {
        EmailSelect,
        Draggable,
        FolderButton,
        Loading,
        NewEmail,
        Multiselect
    }
}
</script>
