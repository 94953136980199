<template>
    <div class="cell-content is-full is-flex">
        <template v-if="homepageStatus">
            <i class="material-icons is-green">radio_button_checked</i>
        </template>
        <template v-else>
            <i class="material-icons is-red">radio_button_unchecked</i>
        </template>
    </div>

</template>

<script>
import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {

        homepageStatus() {
            return this.row.homepageStatus;
        },
    },
}
</script>
