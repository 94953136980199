var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("loading")
        : _c("div", [
            _c("div", { staticClass: "level" }, [
              _c(
                "div",
                { staticClass: "level-left" },
                [
                  _c("multiselect", {
                    staticClass: "level-item",
                    staticStyle: { width: "80px" },
                    attrs: { "allow-empty": false, options: [2023, 2024] },
                    model: {
                      value: _vm.year,
                      callback: function ($$v) {
                        _vm.year = $$v
                      },
                      expression: "year",
                    },
                  }),
                  _vm._v(" "),
                  !_vm.published
                    ? _c(
                        "button",
                        {
                          staticClass: "button is-success level-item",
                          on: {
                            click: function ($event) {
                              return _vm.publish(true)
                            },
                          },
                        },
                        [_vm._v("Auf Website veröffentlichen")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.published
                    ? _c(
                        "button",
                        {
                          staticClass: "button is-success level-item",
                          on: {
                            click: function ($event) {
                              return _vm.publish(true)
                            },
                          },
                        },
                        [_vm._v("Daten aktualisieren")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.published
                    ? _c(
                        "button",
                        {
                          staticClass: "button is-danger level-item",
                          on: {
                            click: function ($event) {
                              return _vm.publish(false)
                            },
                          },
                        },
                        [_vm._v("Von Website löschen")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "level" }, [
              _c("div", { staticClass: "level-left" }, [
                _c("span", { staticClass: "title subtitle" }, [
                  _vm._v(
                    "Veröffentlicht für " +
                      _vm._s(_vm.year) +
                      ": " +
                      _vm._s(
                        _vm.published ? _vm.published : "Nicht veröffentlicht"
                      )
                  ),
                ]),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }