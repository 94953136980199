<template>
    <div class="row darken" :class="startView === 'day' ? 'has-background-grey-lighter' : '' ">
        <div class="cell is-icon-cell"><strong>SUMME</strong></div>
        <div class="cell is-multiple-date-cell"></div>
        <div class="cell is-small-cell"></div>
        <div class="cell is-name-cell">
            <span>umgerechnet auf eine Mindestteilnehmerzahl von {{doubleRooms}}</span>
        </div>
        <div class="cell is-provider-cell"></div>
        <div class="cell is-price-cell is-small">
            <span class="is-currency">{{ showCurrency }}</span>
        </div>

        <div class="cell is-price-cell">
            <input-price readOnly :value="summaryInPreferenceCurrency.group" :valueInOrderCurrency="summary.group" />
        </div>
        <div class="cell is-price-cell">
            <input-price readOnly :value="summaryInPreferenceCurrency.person" :valueInOrderCurrency="summary.person" />
        </div>
        <div class="cell is-price-cell is-margin-cell">
            <input-price readOnly :value="summaryInPreferenceCurrency.single" :valueInOrderCurrency="summary.single" />
        </div>
        <div class="cell is-actions is-small-cell"></div>
    </div>
</template>

<script>
import store from '@calculations/store';
import { InputPrice } from '@calculations/components';
import {settings} from "@clientSpecific/utilities/settings";

export default {
    store,

    data: function () {
        return {
            settings: settings
        }
    },
    components: {
        InputPrice
    },

    computed: {
        startView: {
            get () {
                return this.$store.state.startView;
            },
        },

        doubleRooms: function () { return this.$store.getters.rooms.double },

        summaryPrices: function () {
            return this.$store.getters.summaryPrices;
        },

        showCurrency: function () {
            return this.$store.state.order.calculationCurrency;
        },

        summary: function () {
            return {
                group: this.summaryPrices.reduce((sum, {_summary}) => {
                    return sum + Number(_summary.group)
                }, 0),
                person: this.summaryPrices.reduce((sum, {_summary}) => {
                    return sum + Number(_summary.person)
                }, 0),
                single: this.summaryPrices.reduce((sum, {_summary}) => {
                    return sum + Number(_summary.single)
                }, 0),
            }
        },

        summaryInPreferenceCurrency: function () {
            return {
                group: this.summary.group / this.exchangeRate,
                person: this.summary.person / this.exchangeRate,
                single: this.summary.single / this.exchangeRate,
            }
        },

        exchangeRate: function () {
            return this.$store.state.exchangeRates && this.$store.state.exchangeRates[this.showCurrency] ? this.$store.state.exchangeRates[this.showCurrency] : 0;
        },
    }
}

</script>
