var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "columns" }, [
        _c(
          "div",
          {
            staticClass: "column is-narrow",
            staticStyle: { "align-self": "center", "padding-left": "85px" },
          },
          [_vm._v("Bildtitel: ")]
        ),
        _c(
          "div",
          { staticClass: "column", staticStyle: { "padding-right": "85px" } },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.title,
                  expression: "title",
                },
              ],
              staticClass: "input",
              attrs: { type: "text", id: "title" },
              domProps: { value: _vm.title },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.title = $event.target.value
                },
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("cropper", {
        ref: "cropper",
        staticClass: "cropper",
        staticStyle: { height: "80vh" },
        attrs: {
          src: _vm.image.src,
          "stencil-props": {
            aspectRatio: _vm.component.props.cropRatio,
          },
        },
        on: { change: _vm.change },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }