var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.getIcon
    ? _c(
        "i",
        {
          staticClass: "material-icons",
          style: {
            color: _vm.getIcon.color,
            fontsize: "25px",
            "font-weight": 900,
          },
          attrs: {
            title:
              "Rechnungs-Nr: " +
              _vm.row.lastClientInvoiceId +
              " | " +
              _vm.getIcon.title,
          },
        },
        [_vm._v(_vm._s(_vm.getIcon.icon))]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }