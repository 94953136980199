var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "has-margin-top" }, [
    _c(
      "div",
      {
        staticClass: "bp-panel is-form has-no-title",
        staticStyle: { "min-height": "auto" },
      },
      [
        _vm.isLoading
          ? _c("loading")
          : _c("div", { staticClass: "panel-content is-table-form" }, [
              _c(
                "table",
                [
                  _vm._l(_vm.component.props.rows, function (row) {
                    return [
                      _c(
                        "tr",
                        [
                          _vm._l(row.fields, function (field) {
                            return [
                              field.type === "label"
                                ? _c("th", [
                                    _c("label", { staticClass: "label" }, [
                                      _vm._v(_vm._s(field.label) + ":"),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              field.type === "computedLabel"
                                ? _c("th", [
                                    _c("label", { staticClass: "label" }, [
                                      _vm._v(
                                        _vm._s(_vm.item[field.property]) + ":"
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              field.type === "multiselect"
                                ? _c("td", { staticClass: "pl-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "control" },
                                      [
                                        field.getValuesFromOptions
                                          ? _c("multiselect", {
                                              attrs: {
                                                value:
                                                  _vm.getValuesFromOptions(
                                                    field
                                                  ),
                                                options: field.options,
                                                label: field.label,
                                                "track-by": field.trackBy,
                                                multiple: field.multiple,
                                              },
                                              on: {
                                                input: (value) =>
                                                  _vm.setValuesFromOptions(
                                                    value,
                                                    field
                                                  ),
                                              },
                                            })
                                          : field.getValueFromOptions
                                          ? _c("multiselect", {
                                              attrs: {
                                                value:
                                                  _vm.getValueFromOptions(
                                                    field
                                                  ),
                                                options: field.options,
                                                label: field.label,
                                                "track-by": field.trackBy,
                                                multiple: field.multiple,
                                              },
                                              on: {
                                                input: (value) =>
                                                  _vm.setValueFromOptions(
                                                    value,
                                                    field
                                                  ),
                                              },
                                            })
                                          : _c("multiselect", {
                                              attrs: {
                                                options: field.options,
                                                label: field.label,
                                                "track-by": field.trackBy,
                                                multiple: field.multiple,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.saveItem(field)
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.element[field.property],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.element,
                                                    field.property,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "element[field.property]",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              field.type === "input"
                                ? _c("td", { staticClass: "pl-4" }, [
                                    _c("div", { staticClass: "control" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.element[field.property],
                                            expression:
                                              "element[field.property]",
                                          },
                                        ],
                                        staticClass: "input",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.element[field.property],
                                        },
                                        on: {
                                          input: [
                                            function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.element,
                                                field.property,
                                                $event.target.value
                                              )
                                            },
                                            function ($event) {
                                              return _vm.saveItem(field)
                                            },
                                          ],
                                        },
                                      }),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              field.type === "numericalInput"
                                ? _c("td", { staticClass: "pl-4" }, [
                                    _c("div", { staticClass: "control" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.number",
                                            value: _vm.element[field.property],
                                            expression:
                                              "element[field.property]",
                                            modifiers: { number: true },
                                          },
                                        ],
                                        staticClass: "input",
                                        attrs: { type: "number" },
                                        domProps: {
                                          value: _vm.element[field.property],
                                        },
                                        on: {
                                          input: [
                                            function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.element,
                                                field.property,
                                                _vm._n($event.target.value)
                                              )
                                            },
                                            function ($event) {
                                              return _vm.saveItem(field)
                                            },
                                          ],
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              field.type === "buttons"
                                ? _c("td", { staticClass: "pl-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "field is-grouped" },
                                      [
                                        _vm._l(
                                          field.buttons,
                                          function (button) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "control" },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass: "button",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.$emit(
                                                            "buttonAction",
                                                            {
                                                              action:
                                                                button.buttonAction,
                                                              element:
                                                                _vm.element,
                                                            }
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(button.property)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              field.type === "toggleSwitch"
                                ? _c("td", { staticClass: "pl-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "control" },
                                      [
                                        _c(
                                          "toggle-switch",
                                          {
                                            on: {
                                              input: function ($event) {
                                                return _vm.saveItem(field)
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.element[field.property],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.element,
                                                  field.property,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "element[field.property]",
                                            },
                                          },
                                          _vm._l(
                                            field.options,
                                            function (option) {
                                              return _c(
                                                "option",
                                                {
                                                  domProps: {
                                                    value: option.value,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(option.label) +
                                                      "\n                                            "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              field.type === "checklistItem"
                                ? _c("td", { staticClass: "pl-4" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "field is-grouped is-grouped-right",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "control" },
                                          [
                                            _c("toggle", {
                                              attrs: {
                                                value: _vm.getChecklistItem(
                                                  field.propertyType
                                                ),
                                                height: "18",
                                              },
                                              on: {
                                                input: (input) =>
                                                  _vm.setChecklistItem(
                                                    input,
                                                    field.propertyType
                                                  ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              field.type === "dropZone"
                                ? _c(
                                    "td",
                                    { staticClass: "pl-4" },
                                    [
                                      [
                                        _c(
                                          "documents",
                                          _vm._b(
                                            {
                                              ref: "documents",
                                              refInFor: true,
                                              attrs: {
                                                "has-head": true,
                                                "has-form": false,
                                                "has-title": false,
                                                "has-filter": false,
                                                dropzone: false,
                                                "has-border": false,
                                                title:
                                                  "ABGESPEICHERTE MENÜLISTEN ZUR REISE",
                                              },
                                            },
                                            "documents",
                                            _vm.documentProps(field),
                                            false
                                          )
                                        ),
                                      ],
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }