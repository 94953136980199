var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.groupedPrices.length > 0
    ? _c(
        "div",
        [
          _vm.groupName
            ? _c("div", { staticClass: "row is-headline" }, [
                _vm._v(_vm._s(_vm.groupName)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.sortedPrices, function (price, index) {
            return _c(
              `${price.placeholder.info.type}-price`,
              {
                key: `${price.placeholder.info.type}-${price.placeholder.id}`,
                ref: "price",
                refInFor: true,
                tag: "component",
                attrs: {
                  price: price,
                  isFirstDayItem: _vm.isFirstDayItem(price),
                  isFirstDay: index === 0,
                },
              },
              [
                _c(`${price.placeholder.info.type}-subrows`, {
                  tag: "component",
                  attrs: { price: price },
                }),
                _vm._v(" "),
                _c("item-prices-subrows", {
                  attrs: { price: price },
                  on: {
                    unlink: function ($event) {
                      return _vm.handleUnlink(index)
                    },
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }