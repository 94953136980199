<template>
    <div>
        <button
            v-bind:class = "{ 'is-selected' : folderSelected === folder.folder }"
            v-on:click   = "selectFolder"
        >
            <i
                v-if  = "folder.subfolders"
                class = "material-icons is-arrow"
                v-bind:class    = "{ 'is-down' : areSubfoldersVisible }"
                v-on:click.stop = "toggleSubfolders"
            >play_arrow</i>

            <i class="material-icons" v-if="folder.level === 0 || !folder.level">{{ folder.icon }}</i>
            {{ folder.name ? folder.name : folderName }}

            <span>
                <template v-if="folder.list">{{ folder.list.length ? folder.list.length : '' }}</template>
                <i class="material-icons icon-spin" v-else-if="isLoading && folderSelected === folder.folder">refresh</i>
            </span>
        </button>

<!--        <div class="subfolders" v-bind:class="{ 'are-closed' : !areSubfoldersVisible }">
            <folder-button
                v-for        = "(object, subfolderName) in folder.subfolders"
                v-bind:key   = "subfolderName"
                v-bind:class = "['is-' + object.level]"
                v-bind:folderName     = "subfolderName"
                v-bind:folder         = "object"
                v-bind:folderSelected = "folderSelected"
                v-on:selectFolder     = "value => $emit('selectFolder', value)"
            ></folder-button>
        </div>-->
    </div>
</template>



<script>
//import FolderButton from './FolderButton';
import store           from '../store';


export default {
    name: 'folder-button',
    store: store,


    props: {
        folderName:     { type: String, required: true },
        folder:         { required: true },
        folderSelected: { required: true }
    },


    computed: {
        isLoading: function () { return this.$store.state.loadingFolder; }
    },


    data: function () {
        return {
            areSubfoldersVisible: false
        };
    },


    methods: {
        toggleSubfolders: function () {
            this.areSubfoldersVisible = !this.areSubfoldersVisible;
        },

        selectFolder: function() {
            if (this.folder.toggleSubfolders) {
                this.toggleSubfolders()
            } else {
                this.$emit('selectFolder', this.folder.folder)
            }
        }
    },


    components: {
     //   FolderButton
    }
}
</script>
