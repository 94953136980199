<template>
    <modal
        classes="new-email"
        v-bind:visible="isModalVisible"
        v-on:closeModal="$emit('closePhoneModal')"
    >
        <template slot="header">
            <div class="name" v-if="!action">NEUES TELEFONPROTOKOLL</div>
            <div class="name" v-else>TELEFONPROTOKOLL BEARBEITEN</div>
            <div>
                <toggle-switch v-model="emailType">
                    <option value="client">Telefonat mit Kunde</option>
                    <option value="provider">Telefonat mit Leistungsträger</option>
                </toggle-switch>
            </div>
        </template>


        <template slot="content">
            <orders-select
                :params="{_groups: ['client_read']}"
            ></orders-select>


            <phone-form
                v-bind:preselected-order     = "preselectedOrder"
                v-bind:preselectedContact = "preselectedContact"
                v-on:closeModal = "refresh => $emit('closePhoneModal', refresh)"
                ref="form"
                v-on="$listeners"
            ></phone-form>
        </template>
    </modal>
</template>



<script>
import Modal    from '@components/Modal';
import store        from '@components/emails/store';
import PhoneForm    from './PhoneForm';
import OrdersSelect from './OrdersSelect';
import ToggleSwitch from "@components/form/ToggleSwitch";


export default {
    store: store,


    props: {
        preselectedOrder:   { default: null },
        preselectedContact: { default: null },
        isModalVisible:     { type: Boolean, required: true },
    },


    computed: {
        emailType: {
            get: function() {
                return this.$store.state.emailType;
            },
            set: function(value) {
                this.$store.state.emailType = value;
                if(value === 'provider') {
                    this.$store.dispatch('getRequests');
                }
                this.$store.dispatch('resetToContacts', {contactToSelect: null, selectContact: true});
            }
        },

        emailSelected: function () { return this.$store.state.emailsSelected[this.$store.state.emailsSelected.direction][0]; },

        action:        function () { return this.$store.state.action; },

    },

    data: function () {
        return {
            ordersSelected: [],
            customers: null,
        }
    },

    components: {
        PhoneForm,
        Modal,
        OrdersSelect,
        ToggleSwitch
    }
}
</script>
