var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "has-margin-top" },
    [
      _vm.component.props.headline
        ? _c("div", { staticClass: "mb-2" }, [
            _c("b", [_vm._v(_vm._s(_vm.component.props.headline))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("tip-tap", {
        attrs: {
          "default-size": _vm.component.props.defaultSize,
          "menu-items": _vm.component.props.menuItems,
          warning: _vm.compareWarning,
        },
        on: {
          input: function ($event) {
            return _vm.saveForm(_vm.component.props)
          },
          reset: _vm.buttonAction,
        },
        model: {
          value: _vm.element[_vm.component.props.property],
          callback: function ($$v) {
            _vm.$set(_vm.element, _vm.component.props.property, $$v)
          },
          expression: "element[component.props.property]",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }