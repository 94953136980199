<template>
    <div class="bp-row-form">
        <div>
            <div class="bp-row-form__title">Fährstrecke</div>

            <div>
                <i class="material-icons" title="Fähre">directions_boat</i>

                <div class="buttons has-addons toggle-switch has-margin-right">
                    <button
                        class="button is-large"
                        v-on:click.prevent="copyPlaceholder.type = 'night'"
                        v-bind:class="{ 'is-primary': copyPlaceholder.type === 'night' }"
                    >Nacht</button>
                    <button
                        class="button is-large"
                        v-on:click.prevent="copyPlaceholder.type = 'day'"
                        v-bind:class="{ 'is-primary': copyPlaceholder.type === 'day' }"
                    >Tag</button>
                </div>


                <async-select
                    api     = "ports"
                    class   = "has-margin-right"
                    ref     = "startPort"
                    v-model = "copyPlaceholder.startPort"
                    v-bind:custom-label = "item => item.place.name"
                    crudLinkTitle       = "Fügen Sie einen neuen Port hinzu"
                    v-bind:clearable    = "false"
                    v-bind:allow-empty  = "false"
                    placeholder         ="Abfahrtshafen"
                />
                <div>
                    <button
                        class        = "btn-single-toggle has-margin-right"
                        v-bind:class = "[endPortRestriction ? '' : 'is-active']"
                        v-on:click   = "endPortRestriction = !endPortRestriction"
                        title = "Nur bestehende Fährverbindungen suchen/alle Häfen suchen"
                    ><i class="material-icons" style="vertical-align: -2px;">timeline</i></button>
                </div>
                <async-select
                    api     = "ports"
                    v-model = "copyPlaceholder.endPort"
                    v-bind:custom-label = "item => item.place.name"
                    crudLinkTitle       = "Fügen Sie einen neuen Port hinzu"
                    v-bind:clearable    = "false"
                    v-bind:allow-empty  = "false"
                    :params="endPortRestriction && copyPlaceholder.startPort ? {_groups: ['port:service_day_time'], 'endRoutes.startPort.id': copyPlaceholder.startPort.id } : {_groups: ['port:service_day_time']}"
                    placeholder         = "Ankunftshafen"
                />


            </div>
        </div>


        <div class="is-160 has-time">
            <div class="bp-row-form__title">Zeitangaben</div>

            <div>
                <button
                    class        = "btn-single-toggle has-margin-right"
                    v-bind:class = "[onlyTime ? '' : 'is-active']"
                    v-on:click   = "onlyTime = !onlyTime"
                    title = "Uhrzeit"
                    v-if="serviceDayTimes.length > 0"
                ><i class="material-icons" style="vertical-align: -1px;">access_time</i></button>
                <i title="Uhrzeit" class="material-icons" v-else>access_time</i>
                <template v-if="onlyTime">
                    <date-time class="has-margin-right" v-model="copyPlaceholder.startTime" time-only></date-time> -
                    <date-time class="has-margin-left" v-model="copyPlaceholder.endTime" time-only></date-time>
                </template>
                <template v-else>
                    <div class="select">
                        <multiselect
                            v-bind:options="serviceDayTimes"
                            track-by="id"
                            v-bind:custom-label="serviceDayTimeLabel"
                            v-model="serviceDayTimeSelected"
                            v-on:select="selectServiceTime"
                        />
                    </div>
                </template>
            </div>
            <div v-if="onlyTime">
                <i title="Einfschiffung" class="material-icons">av_timer</i>
                <date-time v-model="copyPlaceholder.checkInTime" time-only></date-time>
            </div>
        </div>

        <div>
            <div class="bp-row-form__title">Besondere Fährdetails</div>

            <div>
                <button
                    class        = "btn-single-toggle has-margin-right"
                    v-bind:class = "[copyPlaceholder.hideClass ? '' : 'is-active']"
                    v-on:click   = "copyPlaceholder.hideClass = !copyPlaceholder.hideClass"
                    title = "Klasse auswählen"
                ><i class="material-icons" style="vertical-align: -2px;">receipt</i></button>

                <multiselect
                    style="width: 180px"
                    v-if="!copyPlaceholder.hideClass && (options.ferryLabels.categorySelect)"
                    v-model="classCategory"
                    track-by="key"
                    label="value"
                    placeholder="Klasse"
                    v-bind:options="objectToKeyValueArray(options.ferryLabels.categorySelect)"
                />
                <multiselect
                    style="width: 180px"
                    v-if="copyPlaceholder.hideClass"
                    v-model="hideClass"
                    :options="[{key: true, value: 'ohne Klasse'}, {key: false, value: 'Klasse festlegen'}]"
                    track-by="key"
                    label="value"
                    placeholder="Klasse"
                />
            </div>
        </div>


        <div>
            <div class="bp-row-form__title">Abrechnung</div>

            <div>
                <i title="Set as Additional" class="material-icons">shopping_cart</i>
                <div class="select">
                    <select v-model="copyPlaceholder.clientOffer.status">
                        <option value="included">inkludiert</option>
                        <option value="optional">optional</option>
                        <option value="additional">gesondert</option>
                        <option value="atLocation">vor Ort</option>
                    </select>
                </div>
            </div>
        </div>


        <button
            class="button is-close"
            v-on:click="$emit('cancel')"
            title="Verwerfen"
        ><i class="material-icons">close</i></button>

        <button
            class        = "button is-save is-primary"
            v-bind:class = "{ 'is-loading': isLoading }"
            v-on:click   = "save"
            title        = "Speichern"
        ><i class="material-icons">check</i></button>
    </div>
</template>



<script>
import AsyncSelect from '@components/form/AsyncSelect';
import mixins from './mixins';
import {objectToKeyValueArray} from "@utilities/functions";


export default {
    mixins: [mixins],


    data: function () {
        return {
            copyPlaceholder: {
                startPort: null,
                endPort: null,
                clientOffer: {
                    status: 'included',
                    priceType: 'per_person',
                    price: '0.00'
                },
                hideClass: true,
                classCategory: 'second',
                startTime: '12:00',
                endTime:   '12:00',
                checkInTime: '00:00',
                type: 'night'
            },
            serviceDayTimes: [],
            serviceDayTimeSelected: null,
            onlyTime:      true,
            endPortRestriction: true,
        }
    },

    computed: {
        hideClass: {
            get: function () {
                return !this.copyPlaceholder.hideClass ? {key: false, value: 'Klasse festlegen'} : {key: true, value: 'ohne Klasse'};
            },
            set: function (value) {
                if(value.key) {
                    this.copyPlaceholder.classCategory = 'second';
                }
                this.copyPlaceholder.hideClass = value.key;
            }
        },

        classCategory: {
            get: function () {
                return objectToKeyValueArray(this.options.ferryLabels.categorySelect).find(label => label.key === this.copyPlaceholder.classCategory);

            },
            set: function (value) {
                this.copyPlaceholder.classCategory = value.key;
            }
        },
    },


    methods: {
        objectToKeyValueArray,
        onKeyUp: function (event) {
            if (event.keyCode === 13 &&
                this.placeholder === undefined &&
                this.copyPlaceholder.startPort &&
                this.copyPlaceholder.endPort
                ) {
                this.$nextTick(() => {
                    if (this.placeholderStringify === JSON.stringify(this.copyPlaceholder)) {
                        this.save();
                    }
                });
            }
        },

        selectServiceTime: function (selectedTime) {
            //setTime
            if(selectedTime){
                const startAt = selectedTime.startTimeOfDay ? selectedTime.startTimeOfDay : '00:00:00';
                const endAt = selectedTime.endTimeOfDay ? selectedTime.endTimeOfDay : '00:00:00';
                const checkInAt = selectedTime.checkInTimeOfDay ? selectedTime.checkInTimeOfDay : '00:00:00';
                this.copyPlaceholder.startTime = startAt;
                this.copyPlaceholder.endTime = endAt;
                this.copyPlaceholder.checkInTime = checkInAt;
                this.onlyTime = true;
            }
        },

        serviceDayTimeLabel: function (serviceDayTime) {
            let customLabel = serviceDayTime.startTimeOfDay.substring(0, serviceDayTime.startTimeOfDay.length - 3);
            customLabel += serviceDayTime.endTimeOfDay ? ' - ' + serviceDayTime.endTimeOfDay.substring(0, serviceDayTime.endTimeOfDay.length - 3) : '';
            customLabel += serviceDayTime.checkInTimeOfDay ? ' (Einschiffung: ' + serviceDayTime.checkInTimeOfDay.substring(0, serviceDayTime.checkInTimeOfDay.length - 3) + ')' : '';
            customLabel += ' - ' + serviceDayTime.provider;
            return customLabel;
        },
    },


    mounted: function () {
        if (!this.placeholder) {
            this.$refs.startPort.$el.querySelector('input').focus();
        }
    },

    watch: {
        'copyPlaceholder.endPort': function() {
            if(this.copyPlaceholder.endPort && this.copyPlaceholder.startPort){
                if(this.copyPlaceholder.endPort.allServiceDayTimes && this.copyPlaceholder.endPort.allServiceDayTimes[this.copyPlaceholder.startPort.id]){
                    this.serviceDayTimes = this.copyPlaceholder.endPort.allServiceDayTimes[this.copyPlaceholder.startPort.id];
                    this.onlyTime = false;
                } else {
                    this.serviceDayTimes = [];
                    this.onlyTime = true;
                }
            }
        },
    },


    components: { AsyncSelect }
}
</script>
