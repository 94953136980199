<template>
    <div
        v-if         = "isRouteView"
        class        = "placeholder-view has-requests"
        v-bind:class = "{ 'has-no-route-description' : !placeholder.text && isRouteView }"
    >
        <div v-bind:title="placeholder.text | stripHtml">
            <i class="material-icons" title="Freitext">view_headline</i>

            <template v-if="placeholder.text">
                {{ placeholder.text | stripHtml }}
            </template>
            <template v-else>Freitext ist leer</template>
        </div>

        <div :class="{ 'is-hidden-on-small' : !isConcept }"></div>


        <div class="is-60" v-if="!isConcept"></div>
        <div class="is-100 is-hidden-on-small">
            <time-of-day v-if="isConcept" v-bind:start="placeholder.startTime" end="00:00"></time-of-day>
            <time-of-day v-else v-bind:start="placeholder.startAt" end="00:00"></time-of-day>
        </div>

        <div class="contingent-section" v-if="!isConcept"></div>

        <div v-if="!isConcept"></div>

        <div class="is-truncated is-250"></div>

        <div class="is-130" v-if="!isConcept"></div>

        <div class="action-buttons"><slot></slot></div>

        <div
            v-if="placeholder.sortGroup"
            class="group-icon"
            v-bind:class="'is-' + placeholder.sortGroup"
        ></div>
    </div>
</template>



<script>
import mixins              from './mixins';
import { stripHtml } from '@utilities/functions'



export default {
    mixins: [mixins],

    computed: {
        hasContingentWarning: function () { return false; },
    },

    filters: {
        stripHtml
    },

}
</script>
