var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "placeholder-view",
      class: {
        "has-requests": true,
        "is-optional": _vm.isOptional(_vm.placeholder),
        "is-additional": _vm.placeholder.clientOffer.status === "additional",
      },
      staticStyle: { "padding-left": "0", "padding-right": "0" },
    },
    [
      _c(
        "div",
        { attrs: { title: _vm.placeholder.serviceDescription } },
        [
          _vm.placeholder.placeholderType === "hotel"
            ? _c(
                "i",
                { staticClass: "material-icons", attrs: { title: "Hotel" } },
                [_vm._v("home")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.placeholder.placeholderType === "ferry"
            ? _c(
                "i",
                { staticClass: "material-icons", attrs: { title: "Fähre" } },
                [_vm._v("directions_boat")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.placeholder.placeholderType === "other"
            ? _c("other-service-icons", {
                attrs: { type: _vm.placeholder.otherServiceType.category },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.placeholder.placeholderType === "train"
            ? _c(
                "i",
                { staticClass: "material-icons", attrs: { title: "Zug" } },
                [_vm._v("directions_train")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.placeholder.placeholderType === "airline"
            ? _c(
                "i",
                { staticClass: "material-icons", attrs: { title: "Flug" } },
                [_vm._v("airplanemode_active")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.placeholder.clientOffer.status === "optional"
            ? [_vm._v("Möglichkeit: ")]
            : _vm.placeholder.clientOffer.status === "atLocation"
            ? [_vm._v("vor Ort: ")]
            : _vm._e(),
          _vm._v(
            "\n                " +
              _vm._s(_vm.placeholder.serviceDescription) +
              "\n            "
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "action-buttons" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }