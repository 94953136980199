var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    { staticClass: "emails-list is-simple" },
    [
      _c(
        "div",
        {
          staticClass: "emails-list_items",
          class: _vm.isConcept ? "is-concept" : "",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          !_vm.isConcept
            ? _c("div", { staticClass: "new-email-button" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-success is-large",
                    on: { click: _vm.openEmailForm },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-envelope",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" Neue Email"),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button is-success is-large",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("addPhoneCall", {
                          preselectedContact: _vm.contact,
                        })
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-phone",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" Neues Telefonprotokoll"),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "emails-list-filters level" },
            [
              _c(
                "toggle-switch",
                {
                  attrs: { value: _vm.type, "btn-class": "is-larger" },
                  on: { input: _vm.changeType },
                },
                [
                  _c("option", { attrs: { value: "person" } }, [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        attrs: { title: "Emails mit Kunden" },
                      },
                      [_vm._v("person")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "hotel" } }, [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        attrs: { title: "Emails mit Hotels" },
                      },
                      [_vm._v("hotel")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "other" } }, [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        attrs: {
                          title: "Emails mit Leistungsträgern (ohne Hotels)",
                        },
                      },
                      [_vm._v("more_horiz")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "agency" } }, [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        attrs: { title: "Emails mit Agenturen" },
                      },
                      [_vm._v("face")]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button is-larger",
                  class: [{ "is-primary": _vm.isActive }],
                  staticStyle: { left: "5%" },
                  on: { click: _vm.toggleButton },
                },
                [
                  _c(
                    "i",
                    {
                      staticClass: "material-icons",
                      staticStyle: { padding: "0", margin: "0" },
                      attrs: {
                        title: "Gesamten Inhalt der Emails durchsuchen",
                      },
                    },
                    [_vm._v("zoom_in")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search,
                    expression: "search",
                  },
                ],
                staticClass: "input is_search",
                staticStyle: { "margin-left": "21px" },
                attrs: { type: "text", placeholder: "Suche Emails" },
                domProps: { value: _vm.search },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.search = $event.target.value
                    },
                    _vm.getEmails,
                  ],
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.dataReady
        ? _c("div", { staticClass: "list" }, [
            _c(
              "div",
              { staticClass: "list-scrollable" },
              [
                _vm.emails.length > 0
                  ? [
                      _vm._l(_vm.emails, function (email) {
                        return _c("ListItem", {
                          key: email.id,
                          attrs: {
                            email: email,
                            classNames: _vm.emailClass(email),
                          },
                          nativeOn: {
                            click: function ($event) {
                              return ((event) =>
                                _vm.$store.commit("updateEmailsSelected", {
                                  event,
                                  email,
                                })).apply(null, arguments)
                            },
                            dblclick: function ($event) {
                              return _vm.handleEmailExport(email.id)
                            },
                          },
                        })
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "is-empty" },
                        [
                          _c("pagination", {
                            attrs: { simple: true },
                            on: { input: _vm.getEmails },
                            model: {
                              value: _vm.pagination,
                              callback: function ($$v) {
                                _vm.pagination = $$v
                              },
                              expression: "pagination",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _c("div", { staticClass: "is-empty" }, [
                      _vm._v("Keine Emails vorhanden"),
                    ]),
              ],
              2
            ),
          ])
        : _c("loading"),
      _vm._v(" "),
      _vm.isModalVisible
        ? _c("new-email", {
            attrs: {
              isModalVisible: _vm.isModalVisible,
              "preselected-order": _vm.order,
              "preselected-contact": _vm.contact,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "level" }, [
      _c("div", { staticClass: "level-left" }, [
        _c("h2", [_vm._v("Emails")]),
        _vm._v(" "),
        _c("p", { staticClass: "text-muted" }, [
          _vm._v("Doppelklick um Email in Outlook zu öffnen"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }