var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        { staticClass: "button is-large", on: { click: _vm.openModal } },
        [
          _c("i", { staticClass: "material-icons" }, [_vm._v("help")]),
          _vm._v(" Zuordnen"),
        ]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          staticClass: "is-bp-form is-large",
          attrs: { visible: _vm.isVisible },
          on: { closeModal: _vm.closeModal },
        },
        [
          _c("template", { slot: "header" }, [
            _vm._v("EMAIL EINEM ODER MEHREREN VORGÄNGEN ZUORDNEN"),
          ]),
          _vm._v(" "),
          _c("template", { slot: "content" }, [
            _c(
              "div",
              [
                _c("label", [_vm._v("Vorgänge:")]),
                _vm._v(" "),
                _c("order-select", {
                  attrs: { multiple: "" },
                  model: {
                    value: _vm.ordersSelected,
                    callback: function ($$v) {
                      _vm.ordersSelected = $$v
                    },
                    expression: "ordersSelected",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("label", [_vm._v("Beschreibung:")]),
                _vm._v(" "),
                _c("tag-select", {
                  attrs: { "option-tags": _vm.optionsTags },
                  model: {
                    value: _vm.tagsSelected,
                    callback: function ($$v) {
                      _vm.tagsSelected = $$v
                    },
                    expression: "tagsSelected",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c(
              "button",
              { staticClass: "button is-large", on: { click: _vm.closeModal } },
              [
                _c("i", { staticClass: "material-icons" }, [_vm._v("close")]),
                _vm._v(" Verwerfen"),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button is-primary is-large",
                class: { "is-loading": _vm.isLoading.save },
                on: { click: _vm.assign },
              },
              [
                _c("i", { staticClass: "material-icons" }, [_vm._v("check")]),
                _vm._v(" Zuordnen"),
              ]
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }