<template>

    <div class="has-margin-top">
        <div class="bp-panel is-form has-no-title" style="min-height: auto">
            <div class="panel-content is-table-form">
                <table>
<!--                    <tr>
                        <th>
                            <div class="control">
                                <toggle-switch v-model="preview">
                                    <option :value="false">Eingabe</option>
                                    <option :value="true">Ausgabe</option>
                                </toggle-switch>
                            </div>
                        </th>
                        <td>
                        </td>
                    </tr>-->
                    <tr>
                        <th>
                            <label class="label">Zeiträume (Zeilen):</label>
                        </th>
                        <td>
                            <div class="control">
                                <input type="text" class="input" v-model.number="periods" @input="setPeriods">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label class="label">Kategorien (Spalten):</label>
                        </th>
                        <td>
                            <div class="control">
                                <input type="text" class="input" v-model.number="columns" @input="setPeriods">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td :colspan="(columns + 1)">
                            <h2></h2>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td v-for="col in columns">
                            <div class="control">
                                <input
                                    type="text"
                                    class="input"
                                    :value="getTableElement(0, 0,col)"
                                    @input="input => setTableElement(0, 0,col, input)"
                                    placeholder="3*"
                                >
                            </div>
                        </td>
                    </tr>
                    <template v-for="period in periods">
                        <tr>
                            <td :colspan="(columns + 1)">
                                <h2>{{period}}. Preisblock</h2>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <div class="control">
                                    <input
                                        type="text"
                                        class="input is-large"
                                        :value="getTableElement(period, 0,0)"
                                        @input="input => setTableElement(period, 0,0, input)"
                                        placeholder="April 2023"
                                    >
                                </div>
                            </th>
                            <td v-for="col in columns">
                                <div class="control">
                                    <input
                                        v-if="period === 0"
                                        type="text"
                                        class="input"
                                        :value="getTableElement(period, 0,col)"
                                        @input="input => setTableElement(period, 0,col, input)"
                                        placeholder="3*"
                                    >
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label class="label">Preise pro Person im DZ:</label>
                            </th>
                            <td v-for="col in columns">
                                <div class="control">
                                    <input
                                        type="text"
                                        class="input"
                                        :value="getTableElement(period, 1,col)"
                                        @input="input => setTableElement(period, 1,col, input)"
                                        placeholder="1.234 €"
                                    >
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label class="label">Einzelzimmer-Zuschlag:</label>
                            </th>
                            <td v-for="col in columns">
                                <div class="control">
                                    <input
                                        type="text"
                                        class="input"
                                        :value="getTableElement(period, 2,col)"
                                        @input="input => setTableElement(period, 2,col, input)"
                                        placeholder="1.234 €"
                                    >
                                </div>
                            </td>
                        </tr>
                    </template>
                </table>
            </div>
        </div>
    </div>


</template>

<script>

import Mixin from '../../tab';
import {Multiselect, ToggleSwitch, Resizable} from "@components/form";


export default {
    mixins: [ Mixin ],

    props: {
        component: {
            type: Object,
            required: true
        }
    },

    data: function () {
        return {
            periods: 1,
            columns: 1,
            preview: false,
        }
    },

    methods: {

        saveItem: function() {
            this.$emit('saveItemDebounce', this.component.props.property, this.getProperty)
        },

        setPeriods: function() {
            let property = this.getProperty;
            let cols = this.columns;
            if(!property){
                this.item[this.component.props.property] = [];
            }
            let diff = this.getProperty.length - (this.periods + 1);

            //Define the array
            for(let t=this.getProperty.length; t<this.periods+1; t++){
                //[block][row][col]
                this.item[this.component.props.property][t] = [];
                this.item[this.component.props.property][t][0] = [];
                if(t > 0) {
                    this.item[this.component.props.property][t][1] = [];
                    this.item[this.component.props.property][t][2] = [];
                    this.item[this.component.props.property][t][0][0] = '';
                    this.item[this.component.props.property][t][1][0] = 'Preis pro Person im DZ';
                    this.item[this.component.props.property][t][2][0] = 'Einzelzimmer-Zuschlag';
                }
            }
            //count cols
            let count = this.item[this.component.props.property].length > 0 ? this.item[this.component.props.property][0][0].length : 0;
            let diff2 = count - (cols+1);


            for(let t=0; t<this.periods+1; t++){
                for(let c = 0; c < (cols+1) ; c++) {
                    if(!this.item[this.component.props.property][t][0][c]) {
                        this.item[this.component.props.property][t][0][c] = '';
                    }
                    if(t > 0) {
                        if(!this.item[this.component.props.property][t][1][c]) {
                            this.item[this.component.props.property][t][1][c] = '';
                        }
                        if(!this.item[this.component.props.property][t][2][c]) {
                            this.item[this.component.props.property][t][2][c] = '';
                        }
                    }
                }
                if(diff2 > 0) {
                    this.item[this.component.props.property][t][0].splice(count-diff2, diff2);
                    if(this.item[this.component.props.property][t].length > 1) {
                        this.item[this.component.props.property][t][1].splice(count-diff2, diff2);
                        this.item[this.component.props.property][t][2].splice(count-diff2, diff2);
                    }

                }
            }
            this.item[this.component.props.property][0][0][0] = 'Kategorien';

            if(diff2 !== 0) {
                this.saveItem();
            }

            if(diff !== 0){
                if(diff > 0) {
                    this.item[this.component.props.property].splice(this.getProperty.length-diff, diff);
                }
                this.saveItem();
            }

        },

        setTableElement(block, row, col, inputEvent) {
            this.getProperty[block][row][col] = inputEvent.target.value;
            this.saveItem();
        },

        getTableElement(block, row, col) {
            return this.getProperty && this.getProperty[block] && this.getProperty[block][row] && this.getProperty[block][row][col] ? this.getProperty[block][row][col] : '';
        }
    },

    computed: {
        getProperty: function () {
            return this.item[this.component.props.property];
        },

        pricePreview: function () {
            let text = '<div>\n';
            let row = [];
            let col = [];
            let priceBlock = [];
            for (let i = 0; i < this.getProperty.length; i++) {
                text += '   <div class="w-layout-grid grid-price">\n';
                priceBlock = this.getProperty[i];
                for (let r = 0; r < priceBlock.length; r++) {
                    row = priceBlock[r];
                    for (let c = 0; c < row.length; c++) {
                        col = row[c];
                        if (r === 0) {
                            text += '      <div class="text-block-date">' + col + '</div>\n';
                        } else {
                            text += '      <div class="text-block-price">' + col + '</div>\n';
                        }
                    }
                }
                text += '   </div>\n';
            }
            text += '</div>';
            return text;
        }
    },

    mounted: function () {
        let property = this.item[this.component.props.property];
        this.periods = property && property.length > 0 ? property.length - 1 : 1;
        this.columns = property && property[0] ? property[0][0].length - 1 : 1;
        this.setPeriods();
    },

    components: {
        Resizable,
        ToggleSwitch,
        Multiselect,
    },
}
</script>
