var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "redesign-table" },
    [
      _c(
        "div",
        { staticClass: "row is-header" },
        [
          _c("h2", [_vm._v("KALKULATION DER INBEGRIFFENEN LEISTUNGEN")]),
          _vm._v(" "),
          _c("simple-menu", [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.addIncludedPrice("per_person")
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                _vm._v(
                  " Kalkulatorische Leistung pro Person im DZ\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.addIncludedPrice("per_single_room")
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                _vm._v(
                  " Kalkulatorische Leistung pro Person im EZ\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.addIncludedPrice("per_group")
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                _vm._v(" Kalkulatorische Leistung pro Gruppe\n            "),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "row",
        {
          staticClass: "is-head",
          class: _vm.startView === "day" ? "has-background-grey-lighter" : "",
        },
        [
          _c("template", { slot: "type" }, [_vm._v("Art")]),
          _vm._v(" "),
          _c("template", { slot: "date" }, [_vm._v("Datum")]),
          _vm._v(" "),
          _c("template", { slot: "nights" }, [_c("div")]),
          _vm._v(" "),
          _c("template", { slot: "name" }, [_vm._v("Leistungsbeschreibung")]),
          _vm._v(" "),
          _c("template", { slot: "provider" }, [_vm._v("Anbieter")]),
          _vm._v(" "),
          _c("template", { slot: "currency" }, [_c("div")]),
          _vm._v(" "),
          _c("template", { slot: "sellPrice" }, [_vm._v("pro Gruppe")]),
          _vm._v(" "),
          _c("template", { slot: "profit" }, [_vm._v("pro Person im DZ")]),
          _vm._v(" "),
          _c("template", { slot: "margin" }, [
            _vm._v(
              _vm._s(this.supplementPrices ? "EZ-Zuschlag" : "pro Person im EZ")
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _vm._l(_vm.sortGroups, function (sortGroup) {
        return _c("group", {
          key: sortGroup.key,
          attrs: { sortGroup: sortGroup },
        })
      }),
      _vm._v(" "),
      _c("group"),
      _vm._v(" "),
      _vm._l(_vm.treatPackages, function (treatPackage, index) {
        return _c("treat-package", {
          key: `treatPackage-${treatPackage.id}`,
          attrs: { treatPackage: treatPackage, isFirstPackage: index === 0 },
        })
      }),
      _vm._v(" "),
      _vm._l(_vm.includedPrices, function (price) {
        return _c("included-price", {
          key: `included-${price.key}`,
          attrs: { price: price },
          on: {
            delete: function ($event) {
              return _vm.deleteIncluded(price)
            },
          },
        })
      }),
      _vm._v(" "),
      _c("price-summary"),
      _vm._v(" "),
      _vm._l(_vm.sortGroups, function (sortGroup) {
        return _c("group-summary", {
          key: `summary-${sortGroup.key}`,
          attrs: { sortGroup: sortGroup },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }