<template>
    <div>
        <div
            v-for="placeholder in sortedPlaceholders"
            v-bind:key="placeholder.placeholderType + '-' + placeholder.id"
            v-bind:class="{
                'is-optional'   : isOptional(placeholder) || isAtLocation(placeholder),
                'has-more-info' : placeholder.placeholderType === 'route',
                'is-additional' : isAdditional(placeholder)
            }"
            class="is-placeholder"
        >
            <div class="level-left">
                <i class="material-icons" v-if="placeholder.placeholderType === 'ferry'">directions_boat</i>
                <i class="material-icons" v-else-if="placeholder.placeholderType === 'airline'">airplanemode_active</i>
                <other-service-icons v-else-if="placeholder.placeholderType === 'other'" v-bind:type="placeholder.otherServiceType.category" />
                <i class="material-icons" v-else-if="placeholder.placeholderType === 'hotel'">home</i>
                <i v-if="placeholder.placeholderType === 'route'" class="material-icons">filter_hdr</i>

                <span
                    v-bind:class="{'is-optional' : isOptional(placeholder) || isAtLocation(placeholder), 'is-additional' : isAdditional(placeholder)}"
                    v-tooltip="{content: description(placeholder), delay: {show: 1000}}"
                >
                    <span class="is-truncated">
                        <span v-if="isOptional(placeholder)">Möglichkeit:</span>
                        <span v-else-if="isAdditional(placeholder)">vor Ort:</span>
                        <span>{{description(placeholder)}}</span>
                    </span>
                </span>
            </div>

            <div v-if="placeholder.placeholderType === 'hotel'" class="form">
                <input
                    type="text"
                    class="input is-tiny"
                    v-bind:value="placeholder.nights"
                    v-on:input="e => { updatePlaceholder(placeholder, e.target.value) }"
                >
                Nächte
            </div>

            <div v-if="placeholder.placeholderType === 'other'" class="form">
                <input
                    type="text"
                    class="input is-tiny"
                    v-bind:value="placeholder.durationDays"
                    v-on:input="e => { updatePlaceholder(placeholder, e.target.value) }"
                >
                Tage
            </div>
        </div>
    </div>
</template>



<script>
import OtherServiceIcons from '@components/OtherServiceIcons';
import has from 'lodash/has';

export default {
    props: {
        value: {
            type: [Boolean, Object],
            required: true
        }
    },

    computed: {
        sortedPlaceholders: function () {
            return [
                ...this.day.airlinePlaceholders.map(item => Object.assign({}, item, { placeholderType: `airline` })),
                ...this.day.hotelPlaceholders.map(  item => Object.assign({}, item, { placeholderType: `hotel` })),
                ...this.day.ferryPlaceholders.map(  item => Object.assign({}, item, { placeholderType: `ferry` })),
                ...this.day.otherPlaceholders.map(  item => Object.assign({}, item, { placeholderType: `other` })),
                ...this.day.routeDetails.map(       item => Object.assign({}, item, { placeholderType: `route` }))
            ].sort((a,b) => {
                return this.day.sortHelper.indexOf(a.placeholderType + '-' + a.id) < this.day.sortHelper.indexOf(b.placeholderType + '-' + b.id) ? -1 : 1;
            });
        },

        day: {
            get: function () { return this.value },
            set: function (day) { this.$emit('input', day) },
        }
    },

    methods: {
        isOptional: function (placeholder) {
            return placeholder.clientOffer && placeholder.clientOffer.status === 'optional'
        },

        isAtLocation: function (placeholder) {
            return placeholder.clientOffer && placeholder.clientOffer.status === 'atLocation'
        },

        isAdditional: function(placeholder) {
            return placeholder.clientOffer && placeholder.clientOffer.status === 'additional';
        },

        description: function (placeholder) {
            if (placeholder.placeholderType === 'route') {
                return placeholder.routeDescription
            } else {
                return placeholder.serviceDescription
            }
        },

        updatePlaceholder: function (placeholder, value) {
            const day = {
                ...this.day,
                ...has(placeholder, 'nights') && {
                    hotelPlaceholders: this.day.hotelPlaceholders
                        .map(hotelPlaceholder => {
                            if (hotelPlaceholder.id === placeholder.id) {
                                return {
                                    ...hotelPlaceholder,
                                    nights: Number(value)
                                }
                            }

                            return hotelPlaceholder
                        })
                },
                ...has(placeholder, 'durationDays') && {
                    otherPlaceholders: this.day.otherPlaceholders
                        .map(otherPlaceholder => {
                            if (otherPlaceholder.id === placeholder.id) {
                                return {
                                    ...otherPlaceholder,
                                    durationDays: Number(value)
                                }
                            }

                            return otherPlaceholder
                        })
                }
            }

            this.day = day;
        },
    },

    components: {
        OtherServiceIcons
    }
}
</script>
