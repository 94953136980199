var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("loading")
        : _c(
            "div",
            [
              _c("div", { staticClass: "level" }, [
                _c("div", { staticClass: "level-left" }, [
                  _c("h2", { staticClass: "level-item mb-0" }, [
                    _vm._v("Einkaufsjahr:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "level-item" },
                    [
                      _c("multiselect", {
                        staticStyle: { width: "65px", height: "35px" },
                        attrs: {
                          options: _vm.years ? _vm.years : [],
                          "allow-empty": false,
                        },
                        model: {
                          value: _vm.year,
                          callback: function ($$v) {
                            _vm.year = $$v
                          },
                          expression: "year",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "level-right" }, [
                  _c(
                    "div",
                    { staticClass: "level-item" },
                    [
                      _c(
                        "toggle-switch",
                        {
                          model: {
                            value: _vm.showOptionalServices,
                            callback: function ($$v) {
                              _vm.showOptionalServices = $$v
                            },
                            expression: "showOptionalServices",
                          },
                        },
                        [
                          _c("option", { domProps: { value: true } }, [
                            _vm._v("Möglichkeiten einblenden"),
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: false } }, [
                            _vm._v("Möglichkeiten ausblenden"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "days" }, [
                _c("div", { staticClass: "days-header pr-0" }, [
                  _c("div", { staticStyle: { width: "auto" } }, [
                    _vm._v("Leistungsträger"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "150px" } }, [
                    _vm._v("Angefragt am"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "150px" } }, [
                    _vm._v("Preise erfasst am"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { width: "150px", "padding-left": "0" } },
                    [_vm._v("Dokumente")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "85px" } }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "is-relative" },
                  _vm._l(_vm.sortedDays, function (day, index) {
                    return _c(
                      "div",
                      {
                        key: day.id,
                        staticClass: "accordion day has-hidden-arrow",
                        class: { "is-empty": !_vm.hasDayPlaceholders(day) },
                      },
                      [
                        _c("div", { staticClass: "accordion-header" }, [
                          _c("header", [
                            _c("span", [
                              _vm._v(
                                "Tag " +
                                  _vm._s(index + 1) +
                                  " - " +
                                  _vm._s(day.name)
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "day-action-buttons" },
                              [
                                _c(
                                  "simple-menu",
                                  {
                                    attrs: {
                                      material: "",
                                      icon: "more_vert",
                                      title: "Menu",
                                    },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.editDay(day.id)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("mode_edit")]
                                        ),
                                        _vm._v(" Bearbeiten"),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.deleteDay(day.id)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("delete")]
                                        ),
                                        _vm._v(" Löschen"),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "accordion-content" },
                          [
                            _vm.hasDayPlaceholders(day)
                              ? [
                                  _vm._l(
                                    _vm.dayPlaceholders(day),
                                    function (placeholder) {
                                      return [
                                        _vm.showOptionalServices ||
                                        !_vm.isOptional(placeholder)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "placeholder-wrapper",
                                              },
                                              [
                                                _c(
                                                  "placeholder",
                                                  {
                                                    key:
                                                      placeholder.placeholderType +
                                                      "-" +
                                                      placeholder.id,
                                                    attrs: {
                                                      placeholder: placeholder,
                                                    },
                                                  },
                                                  [
                                                    placeholder.placeholderType !==
                                                    "route"
                                                      ? _c(
                                                          "simple-menu",
                                                          {
                                                            attrs: {
                                                              material: "",
                                                              icon: "more_vert",
                                                              title: "Menu",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.openConceptProviderForm(
                                                                        {
                                                                          providers:
                                                                            placeholder.providers,
                                                                          dayId:
                                                                            day.id,
                                                                          placeholder:
                                                                            placeholder,
                                                                          id: placeholder.id,
                                                                        }
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "i",
                                                                  {
                                                                    staticClass:
                                                                      "material-icons",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "add"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " Leistungsträger auswählen"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                placeholder.providers.length > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "requests",
                                                      },
                                                      _vm._l(
                                                        placeholder.providers,
                                                        function (provider) {
                                                          return _c(
                                                            "provider",
                                                            {
                                                              key: provider.id,
                                                              attrs: {
                                                                procurementInfo:
                                                                  _vm.procurementInfo,
                                                                placeholder:
                                                                  placeholder,
                                                                provider:
                                                                  provider,
                                                                year: _vm.year,
                                                              },
                                                              on: {
                                                                addPrice:
                                                                  _vm.addPrice,
                                                                openEmail:
                                                                  _vm.openEmailForm,
                                                                markAsRequested:
                                                                  _vm.markAsRequested,
                                                                deletePriceRequest:
                                                                  _vm.deletePriceRequest,
                                                                documentSaved:
                                                                  _vm.documentSaved,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ]
                              : _c("div", { staticClass: "placeholder" }, [
                                  _c("span", { staticClass: "text-sm" }, [
                                    _vm._v(
                                      "Das Tageskonzept enthält keine weiteren Elemente"
                                    ),
                                  ]),
                                ]),
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _vm.modalCRUD
                ? _c(
                    "div",
                    [
                      _c(`modal-${_vm.modalCRUD.component}-CRUD`, {
                        tag: "component",
                        attrs: {
                          initialValue: _vm.modalCRUD,
                          providerType: "calculation",
                        },
                        on: {
                          closeModal: _vm.handleCloseModalCRUD,
                          submit: _vm.handleCloseModalCRUD,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("concept-provider-form", {
                ref: "conceptProviderForm",
                attrs: { options: [] },
              }),
              _vm._v(" "),
              _vm.isModalEmailVisible
                ? _c("concept-email", {
                    attrs: {
                      preselectedContact: _vm.contact,
                      "price-request-year": _vm.year,
                      "preselected-order-concept": _vm.item,
                      "select-template": "procurement",
                      "preselected-concept-provider":
                        _vm.preselectedConceptProvider,
                      isModalVisible: _vm.isModalEmailVisible,
                    },
                    on: { closeModal: _vm.handleEmailFormClose },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }