var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "users" }, [
    _c("div", { staticClass: "user is-favicon is-small" }, [
      _c("div", {
        staticClass: "user__avatar",
        style: _vm.faviconStyle(_vm.row.organisationFilter),
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }