<template>
    <div class="is-fullwidth">

        <template v-for="document in documents">
            <div>
                <a
                    class="has-text-centered"
                    title="Herunterladen"
                    v-bind:href="downloadDocument(document)"
                    download
                    target="_blank"
                    style="width: 100%"
                >
                    <i class="material-icons">attach_file</i>
                </a>
            </div>
        </template>
        <loading class="in-row is-small" v-show="isLoading" text-in-front="" text-after="" style="height: 30px; margin:0 0 0 5px; padding: 0; width: unset"></loading>
        <template v-show="!isLoading">
            <vue-dropzone
                v-show="!isLoading"
                ref="dropzone"
                :id="toString(docId)"
                :resize-height="500"
                v-bind:options="dropzoneOptions"
                v-on:vdropzone-sending="sendingEvent"
                v-on:vdropzone-success="fileSuccess"
                v-on:vdropzone-complete="fileCompleted"
                v-on:vdropzone-files-added="filesAdded"
                useCustomSlot
            >
                <div id="documentDropZone" class="dz-dropzone is-in-row" v-show="!isLoading"
                ><i class="material-icons mr-0" style="font-size: 14px">upload</i></div>
            </vue-dropzone>
        </template>
    </div>
</template>



<script>

import vue2Dropzone  from 'vue2-dropzone'
import {authHeader, getFilePath} from "@utilities/functions";
import Loading from "@components/Loading.vue";
import vueDropzone from "vue2-dropzone";
import toString from "lodash/toString";


export default {

    props: {
        uploadData: { type: Object,  default: () => {} },
        documents: { type: Array,  default: () => [] },
        options: { type: Object},
        docId: { type: [Number,String]},
    },

    computed: {
        template: function () {
            return `<div class="dz-preview dz-file-preview" style="display:none">
                        <div class="dz-details">
                            <span data-dz-name></span>
                            <div class="dz-error-message">
                                <span data-dz-errormessage></span>
                            </div>
                        </div>
                        <div class="dz-progress">
                            <span class="dz-upload" data-dz-uploadprogress></span>
                        </div>

                        <div class="dz-remove">
                            <i class="material-icons" data-dz-remove>delete</i>
                        </div>
                    </div>`;
        },
        dropzoneOptions: function () {
            let defaultOptions = {
                url: '/api/documents/upload',
                maxFilesize: 20,
                headers: authHeader(),
                previewTemplate: this.template,
                createImageThumbnails: false,
                clickable: '#documentDropZone',
                paramName: "user_file_upload"
            }
            return {...defaultOptions, ...this.options};
        }
    },

    data: function () {
        return {
            isLoading: false,
        }
    },

    methods: {
        toString,

        downloadDocument: function(document) {
            if (document) {
                if(document && document.cloudStorage){
                    return document.path;
                }
                return getFilePath(document.id)
            }
        },


        sendingEvent: function (file, xhr, formData) {
            this.isLoading = true;

            formData.append('name', file.name.split('.').slice(0, -1).join('.'));
            if (!!this.uploadData && typeof this.uploadData === 'object') {
                Object.keys(this.uploadData).forEach(key => {
                    if (!!this.uploadData[key]) {
                        if (key === 'links') {
                            this.uploadData.links.forEach(link => {
                                formData.append('links[]', JSON.stringify(link))
                            })
                        } else {
                            formData.append(key, this.uploadData[key])
                        }
                    }
                })
            }
        },

        fileSuccess: function (file, response) {

            this.$refs.dropzone.removeFile(file);
            this.isLoading = false;
            this.$emit('documentSaved', response);
        },

        fileCompleted: function (file) {
            this.queueSize--;

            if (this.queueSize === 0) {
            }
        },

        filesAdded: function (files) {
            this.queueSize = files.length;
        },


    },

    components: {
        vueDropzone,
        Loading,
        vue2Dropzone
    }
}
</script>
