var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "emails-page" },
    [
      _vm.isSimpleList
        ? _c("list-simple", {
            ref: "emailList",
            attrs: {
              "is-concept": !!_vm.preselectedOrderConcept,
              apiQuery: _vm.filterQuery,
              order: _vm.preselectedOrder
                ? _vm.preselectedOrder
                : _vm.preselectedOrderConcept,
            },
            on: { addPhoneCall: _vm.addPhoneCall },
          })
        : _c("list", {
            attrs: {
              preselectedOrder: _vm.preselectedOrder,
              apiQuery: _vm.preselectedOrder
                ? "links.order.id=" + _vm.preselectedOrder.id
                : "" + "&" + _vm.apiQuery,
              useLiveEmails: _vm.useLiveEmails,
            },
          }),
      _vm._v(" "),
      _c(
        "main",
        [
          _c(
            "div",
            { staticClass: "action-buttons" },
            [
              !_vm.preselectedOrderConcept ? _c("btn-assign") : _vm._e(),
              _vm._v(" "),
              _vm.email && _vm.email.type === "phone"
                ? _c(
                    "button",
                    {
                      staticClass: "button is-large",
                      on: {
                        click: function ($event) {
                          return _vm.fromEmailAction("edit")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("edit"),
                      ]),
                      _vm._v(" Bearbeiten"),
                    ]
                  )
                : [
                    _c(
                      "button",
                      {
                        staticClass: "button is-large",
                        on: {
                          click: function ($event) {
                            return _vm.fromEmailAction("reply")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("reply"),
                        ]),
                        _vm._v(" Antworten"),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button is-large",
                        on: {
                          click: function ($event) {
                            return _vm.fromEmailAction("replyAll")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("reply_all"),
                        ]),
                        _vm._v(" Allen Antworten"),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button is-large",
                        on: {
                          click: function ($event) {
                            return _vm.fromEmailAction("forward")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("arrow_forward"),
                        ]),
                        _vm._v(" Weiterleiten"),
                      ]
                    ),
                  ],
              _vm._v(" "),
              _c("btn-delete"),
            ],
            2
          ),
          _vm._v(" "),
          _c("email-view"),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c("loading", { attrs: { classNames: "is-global" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isModalVisible && !_vm.preselectedOrderConcept
        ? _c("new-email", {
            attrs: {
              isModalVisible: _vm.isModalVisible,
              "preselected-order": _vm.preselectedOrder,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isModalVisible && _vm.preselectedOrderConcept
        ? _c("concept-email", {
            attrs: {
              "is-modal-visible": _vm.isModalVisible,
              "preselected-order-concept": _vm.preselectedOrderConcept,
              isModalVisible: _vm.isModalVisible,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isPhoneModalVisible
        ? _c("new-phone", {
            attrs: {
              isModalVisible: _vm.isPhoneModalVisible,
              "preselected-order": _vm.preselectedOrder,
              "preselected-contact": _vm.preselectedContact,
            },
            on: { closePhoneModal: _vm.closePhoneModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }