<template>
    <loading v-if="isLoading" />

    <div v-else-if="email">
        <header>
            <div><b>Betreff:</b> {{ email.subject }}</div>
            <template v-if="email.type === 'email'">
                <div><b>Von:</b> {{ email.fromAddress }} <div class="tag-gray" v-if="email.fromName">{{ email.fromName }}</div></div>
                <div>
                    <b>An:</b>
                    <template v-for="(address, index) in email.toAddresses">
                        <template v-if="index > 0">, </template>{{ address.email || address }}
                    </template>
                </div>
            </template>
            <template v-else>
                <div><b>Telefonat von:</b> {{ email.user.firstName }}</div>
                <div>
                    <b>Telefonat mit:</b> {{email.contact.emailInfos &&  email.contact.emailInfos.length > 0 ? email.contact.emailInfos[2] : ''}}
                </div>
            </template>


            <div>
                <a v-if="email.links.length == 1 && email.links[0].order" class="tag-white" v-bind:href="'/orders/' + email.links[0].order.id" title="Go to Order">{{ email.links[0].order.client.companyName }} | {{ email.links[0].order.startAt | dateISOtoView | dayMonth }} - {{ email.links[0].order.endAt | dateISOtoView | fullYear }} <template v-for="destination in email.links[0].order.trip.destinations"> | {{ destination.name }} </template></a>
                <div v-if="email.tags && email.links.length > 1" class="tag-white">mehrere Reisen</div>


                <template v-if="email.tags && email.tags.length > 0" v-for="(tag, index) in email.tags">
                    <div class="tag-white" v-if="email.tags && email.tags.length > 0">{{ tag }}</div>
                </template>
            </div>
            <div class="attachments" v-if="nrOfAttachments > 0">
                <div class="list">
                    <a
                        v-for="attachment in email.attachments"
                        v-if="attachment.disposition !== 'inline' || attachment.type !== 'image'"
                        class="attachment"
                        v-bind:href="getHref(attachment)"
                        target="_blank"
                        download
                    >
                        <span>
                            <i :class="attachmentIcon(attachment.type, attachment.subtype)"></i><b v-bind:title="attachment.filename">{{ attachment.filename }}</b>
                        </span>

                    </a>
                </div>
            </div>

            <div class="date">{{ email.date || dateWithLocalTime }}</div>
        </header>


        <div class="email" ref="iframe1">
            <email-html v-if="email" v-bind:email="email"></email-html>
        </div>
    </div>


    <div v-else class="empty-view">
        Keine Email ausgewählt
    </div>
</template>



<script>
import { dateISOtoView, dayMonth, fullYear, dateWithLocalTime } from '@utilities/functions';
import Loading from '@components/Loading'
import store from '../store';
import EmailHtml from './EmailHtml';



export default {
    store: store,


    computed: {
        isLoading:       function () { return this.$store.state.isLoading || this.$store.state.loadingFolder },
        email:           function () { return this.$store.state.emailsSelected[this.$store.state.emailsSelected.direction][0]; },
        nrOfAttachments: function () {
            if (this.email.attachments) {
                return this.email.attachments.filter(attachment => attachment.disposition !== 'inline' || attachment.type !== 'image').length;

            } else {
                return 0;
            }
        },

    },


    methods: {
        attachmentIcon(type, subtype) {
            if(type === 'image') {
                return 'fa fa-file-image-o';
            }
            if(type === 'application') {
                if(subtype === 'pdf') {
                    return 'fa fa-file-pdf-o'
                }
                if(subtype === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' || subtype === 'vnd.ms-excel' || subtype === 'vnd.ms-excel.sheet.macroEnabled.12' || subtype === 'vnd.ms-excel.sheet.binary.macroEnabled.12') {
                    return 'fa fa-file-excel-o'
                }
                if(subtype === 'msword' || subtype === 'vnd.ms-word.document.macroEnabled.12' || subtype === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    return 'fa fa-file-word-o'
                }
            }
            return 'fa fa-file-o'
        },

        getHref(attachment) {
            const user = localStorage.getItem('user');
            const token = user ? JSON.parse(user).token : '';


            if (this.$store.getters.isLive(this.email)) {
                return '/api/emails/live/download_attachment/' + this.email.uid +
                       '/' + attachment.id +
                       '?folder=' + this.email.folder +
                        '&bearer=' + token
                    ;

            } else {
                return '/files/' + attachment.filePath;
            }
        }
    },


    filters: {
        dateISOtoView,
        dayMonth,
        fullYear
    },


    components: {
        EmailHtml,
        Loading
    }
}
</script>
