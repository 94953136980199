import {settings} from "@clientSpecific/utilities/settings";
import {getAccommodationLabel} from "@utilities/functions";
import Row from "@calculations/components/Row";
import InputPrice from "@calculations/components/InputPrice";
import store from "@calculations/store"

export default {
    store,

    props: {
        price: {
            type: Object,
            required: true
        },
        isFirstDayItem: {
            type: Boolean
        },
    },

    computed: {

        supplementPrices: function () { return this.$store.state.options.clientConfig.calculation.supplementPrices; },

        singleRoomLimit: function () { return settings.calculation.singleRoomLimit },

        calculationSaved: function () {
            return this.$store.state.calculationSaved;
        },
        marginTaxOn: function() {
            return this.$store.state.marginTaxOn;
        },
        roomTypes: function() {return this.$store.state.options.roomTypes.crud_prices ? this.$store.state.options.roomTypes.crud_prices : [] },
    },
    methods: {
        getAccommodationLabel
    },

    components: {
        Row,
        InputPrice
    }
};

