var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "span",
      { staticClass: "badges" },
      _vm._l(_vm.years, function (year) {
        return _c(
          "span",
          { key: year, staticClass: "badge", class: `is-${year}` },
          [_vm._v(_vm._s(year))]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }