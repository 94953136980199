var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.startView === "day"
        ? [
            _vm.isFirstDayItem
              ? _c(
                  "div",
                  {
                    staticClass: "row is-headline",
                    class: _vm.isFirstDay ? "" : "mt-5",
                  },
                  [
                    _vm._v(
                      "\n            TAG " +
                        _vm._s(_vm.day.info.dayNumber) +
                        " - " +
                        _vm._s(_vm._f("fullYear")(_vm.day.date)) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "row",
        {
          nativeOn: {
            dblclick: function ($event) {
              return _vm.openModal.apply(null, arguments)
            },
          },
        },
        [
          _c("template", { slot: "type" }, [
            _c("i", { staticClass: "material-icons" }, [
              _vm._v(_vm._s(_vm.icon)),
            ]),
          ]),
          _vm._v(" "),
          _c("template", { slot: "date" }, [_vm._v(_vm._s(_vm.date))]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "nights" },
            [
              _vm.nights
                ? [
                    _vm.isHotel || _vm.isNight
                      ? [_vm._v(_vm._s(_vm.nights) + " N")]
                      : [_vm._v(_vm._s(_vm.nights + 1) + " T")],
                  ]
                : _c("span", { staticClass: "empty" }, [_vm._v("-")]),
            ],
            2
          ),
          _vm._v(" "),
          _c("template", { slot: "name" }, [_vm._v(_vm._s(_vm.name))]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "provider" },
            [
              _vm.info.agency || _vm.info.provider
                ? [
                    _vm.info.agency
                      ? [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `/agencies/${_vm.info.agency.id}`,
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(_vm.info.agency.name))]
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.info.agency && _vm.info.provider
                      ? [_vm._v(" - ")]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.info.provider
                      ? [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.info.provider.api,
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(_vm.info.provider.name))]
                          ),
                        ]
                      : _vm._e(),
                  ]
                : [_vm._v("-")],
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "currency" },
            [
              _vm.editCurrency
                ? _c("multiselect", {
                    attrs: {
                      options: _vm.potentialCurrencies,
                      placeholder: "EUR",
                    },
                    on: { input: _vm.updateOrderCurrency },
                    model: {
                      value: _vm.placeholderCurrency,
                      callback: function ($$v) {
                        _vm.placeholderCurrency = $$v
                      },
                      expression: "placeholderCurrency",
                    },
                  })
                : [
                    _c("div", { staticClass: "is-currency level-left" }, [
                      _c("span", { staticClass: "level-item" }, [
                        _vm._v(_vm._s(_vm.currency)),
                      ]),
                      _vm._v(" "),
                      !_vm.editCurrency
                        ? _c(
                            "i",
                            {
                              staticClass:
                                "material-icons level-item is-actions",
                              on: {
                                click: function ($event) {
                                  _vm.editCurrency = true
                                },
                              },
                            },
                            [_vm._v("edit")]
                          )
                        : _vm._e(),
                    ]),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "sellPrice" },
            [
              !_vm.hasGroupPrice && !_vm.groupPrice
                ? _c("span", { staticClass: "empty" }, [_vm._v("-")])
                : _c("input-price", {
                    attrs: {
                      readOnly: !_vm.hasGroupPrice,
                      linked: _vm.linked,
                      "value-in-order-currency": _vm.groupPriceInOrderCurrency,
                      "provider-prices": _vm.getProviderPrices(
                        _vm.groupPriceType
                      ),
                      "price-currency": _vm.currency,
                    },
                    on: { unlink: _vm.handleUnlink },
                    model: {
                      value: _vm.groupPrice,
                      callback: function ($$v) {
                        _vm.groupPrice = $$v
                      },
                      expression: "groupPrice",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "profit" },
            [
              !_vm.hasPersonPrice && !_vm.personPrice
                ? _c("span", { staticClass: "empty" }, [_vm._v("-")])
                : _c("input-price", {
                    attrs: {
                      readOnly: !_vm.hasPersonPrice || _vm.linked,
                      linked: _vm.linked,
                      highlight: !!_vm.highlight.person,
                      "value-in-order-currency": _vm.personPriceInOrderCurrency,
                      "provider-prices": _vm.getProviderPrices(
                        _vm.personPriceType
                      ),
                      "price-currency": _vm.currency,
                    },
                    on: { unlink: _vm.handleUnlink },
                    model: {
                      value: _vm.personPrice,
                      callback: function ($$v) {
                        _vm.personPrice = $$v
                      },
                      expression: "personPrice",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "margin" },
            [
              !_vm.hasSinglePrice
                ? _c("span", { staticClass: "empty" }, [_vm._v("-")])
                : !_vm.isNight
                ? _c("input-price", {
                    attrs: {
                      value: _vm.singlePrice,
                      readOnly: true,
                      linked: _vm.linked,
                      highlight: !!_vm.highlight.single,
                      "value-in-order-currency": _vm.singlePriceInOrderCurrency,
                      "price-currency": _vm.currency,
                    },
                    on: { unlink: _vm.handleUnlink },
                  })
                : _c("input-price", {
                    attrs: {
                      readOnly: !_vm.hasSinglePrice || _vm.linked,
                      linked: _vm.linked,
                      highlight: !!_vm.highlight.single,
                      "value-in-order-currency": _vm.singlePriceInOrderCurrency,
                      "provider-prices": _vm.getProviderPrices(
                        _vm.singlePriceType
                      ),
                      "price-currency": _vm.currency,
                    },
                    on: { unlink: _vm.handleUnlink },
                    model: {
                      value: _vm.singlePrice,
                      callback: function ($$v) {
                        _vm.singlePrice = $$v
                      },
                      expression: "singlePrice",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _vm.isOther
                ? [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.togglePriceType.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm.isPerPerson
                          ? [_vm._v("people")]
                          : [_vm._v("person")],
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "i",
                {
                  staticClass: "material-icons",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.toggleStatus.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("remove_shopping_cart")]
              ),
              _vm._v(" "),
              !_vm.isAirline
                ? _c(
                    "i",
                    {
                      staticClass: "material-icons",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.saveInCRUD.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("save")]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }