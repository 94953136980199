var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cell-content is-full is-flex" },
    [
      _vm.homepageStatus
        ? [
            _c("i", { staticClass: "material-icons is-green" }, [
              _vm._v("radio_button_checked"),
            ]),
          ]
        : [
            _c("i", { staticClass: "material-icons is-red" }, [
              _vm._v("radio_button_unchecked"),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }