<template>
    <row v-on:dblclick.native="openModal" v-if="!hide" :class="hideInputs ? 'isItemPriceRow' : ''">
        <template slot="type">
            <i class="material-icons">subdirectory_arrow_right</i>
        </template>
        <template slot="date">-</template>
        <template slot="nights">-</template>
        <template slot="name">{{name}}</template>

        <template slot="marginTax">
            <margin-tax v-model="marginTaxEnabled" :tax="marginTax" :margin="grossMargin" />
        </template>

        <template slot="buyPriceCalculation" v-if="showExchangeRates">
            <input-price
                :linked="linked"
                v-model="buyPriceInCalculationCurrency"
            />
        </template>

        <template slot="buyPrice">
            <span class="empty" v-if="isAtLocation">vor Ort</span>
            <input-price
                :linked="linked"
                v-model="buyPrice"
                :provider-prices="getProviderPrices(buyProviderPriceType)"
                :currency="currency"
                :is-blue="hasTwoCurrencies"
                v-else
            />
        </template>

        <template slot="sellPriceCalculation" v-if="showExchangeRates">
            <input-price
                v-model="sellPriceInCalculationCurrency"
            />
        </template>

        <template slot="sellPrice">
            <span class="empty" v-if="hideInputs">-</span>
            <input-price
                v-else
                v-model="sellPrice"
                :is-blue="hasTwoCurrencies"
            />
        </template>

        <template slot="profitCalculation" v-if="showExchangeRates">
            <span class="empty" v-if="isAtLocation || hideInputs">-</span>
            <input-price
                v-model="profitInCalculationCurrency"
                v-else
            />
        </template>

        <template slot="profit">
            <span class="empty" v-if="isAtLocation || hideInputs">-</span>
            <input-price
                v-model="profit"
                :is-blue="hasTwoCurrencies"
                v-else
            />
        </template>
        <template slot="margin">
            <span class="empty" v-if="isAtLocation || hideInputs">-</span>
            <input-price v-else v-model="margin"/>
        </template>
    </row>
</template>

<script>
import price from './price';

 export default {
    mixins: [ price ],

    props: {
        name: {
            type: String,
        },

        property: {
            type: String,
            required: true
        },
        hideInputs: {
            type: Boolean,
            default: false
        },
        providerPriceProperty: {
            type: String,
        },
        isItemPrice: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        buyPriceType: function() {  return `${this.property}BuyPrice`; },
        buyProviderPriceType () {
            if (this.providerPriceProperty === "") {return 'buyPrice'}
            return this.providerPriceProperty ? `${this.providerPriceProperty}BuyPrice` : this.buyPriceType;
        },
        sellPriceType: function() {  return `${this.property}SellPrice`; },

        isAtLocation: function () {
            return this.placeholder.clientOffer.status === 'atLocation';
        },

        placeholder: function () { return this.price.placeholder },

        buyPrice: {
            get: function () {


                if (this.isItemPrice) {
                    return this.price.price.amount * this.requestsCurrencyExchange;

                }
                if(!this.price || !this.price[`${this.property}BuyPrice`]) {
                    return 0;
                }
                return this.price[`${this.property}BuyPrice`].amount * this.requestsCurrencyExchange;
            },

            set: function (value) {
                this.updatePrice({[`${this.property}BuyPrice`]: {
                        amount: value,
                        currency: this.orderCurrency
                    }})
            }
        },

        sellPrice: {
            get: function () {
                return this.price[`${this.property}SellPrice`] ? this.price[`${this.property}SellPrice`].amount : null
            },

            set: function (value) {
               this.updatePrice({[`${this.property}SellPrice`]: {
                       amount: value,
                       currency: this.orderCurrency
                   }})
            }
        },


    },

    methods: {
        openModal: function() {
            this.$store.commit('updateModalPlaceholder', this.price)
        }
    }
}
</script>
