<template>
    <div>

        <vue-dropzone
            ref="dropzone"
            id="dropzone"

            v-bind:options="dropzoneOptions"
            useCustomSlot
            v-on:vdropzone-sending="sendingEvent"
            v-on:vdropzone-success="fileSuccess"
            v-on:vdropzone-complete="fileCompleted"
            v-on:vdropzone-files-added="filesAdded"
        >
            <div class="columns has-margin-top">
                <loading v-if="isLoading"></loading>
                <gallery
                    v-if="!isLoading"
                    :folder-images="folderImages"
                    :enable-google-maps="false"
                    :add-title="component.props.addTitle"
                    :enable-zoom="component.props.enableZoom"
                    :column-classes="component.props.columnClasses"
                    :column-style="component.props.columnStyle"
                    v-on:openForm="openForm"
                    v-on:deleteImage="deleteImage"
                    v-on:zoom="zoom"
                    :multi-images="component.props.multiImages"
                    :has-label="false"
                    :is-cover-image="component.props.isCoverImage"
                    :dropzone-id="component.props.dropzoneID"
                    ref="gallery"
                    :maxFileSize="component.props.maxFileSize"
                />
                <div class="dz-area">
                    <span>Zum Hochladen Bild hier hineinziehen</span>
                </div>

                <image-form ref="form" v-on:submit="handleImageSubmit" :show-type="!this.component.props.filterParams.type"/>
            </div>
        </vue-dropzone>

        <template v-if="imageEditorVisible">
            <modal-form
                ref="form"
                createTitle="Ausschnitt wählen"
                v-on:closeModal="imageEditorVisible = false"
                class="is-large is-gray is-block"
                :has-second-submit-button="true"
                submit-button="Nur Ausschnitt speichern"
                second-submit-button="Originalbild & Ausschnitt speichern"
                v-on:submit="upload(false)"
                v-on:submit2="upload(true)"
            >
                <image-cropper
                    ref="imageCropper"
                    v-if="image"
                    :component="component"
                    :image="image"
                    @startUpload="startUpload"
                    @uploaded="uploaded"
                />


<!--
                <image-editor :menu="['crop', 'undo', 'redo']" ref="imageEditor" :scale-factor="this.canvasScale" :crop-height="cropHeight" :crop-width="cropWidth" :crop-only="true" :canvas-height="canvasHeight" :canvas-width="canvasWidth" />
-->

            </modal-form>
        </template>
    </div>
</template>

<script>
import vue2Dropzone  from 'vue2-dropzone';
import VueGallery from 'vue-gallery';
import has from 'lodash/has';
import ImageCropper from "@components/ImageCropper.vue";
const {post} = require("@api");
import ModalForm from '@components/ModalForm';

import { store } from '@components/CRUD/Item/store';

import Loading from '@components/Loading';
import ColumnItem from '@components/CRUD/Item/ColumnItem';
import ImageForm from '@components/CRUD/Item/Tabs/Images/Form';


import { authHeader, prepareForBackend } from '@utilities/functions';
import { getImages, optionsImages, deleteImage, editImage, addGoogleImage } from '@api';
import {min, omit} from "lodash";
import Gallery from "@components/CRUD/Item/Tabs/Images/Gallery";


function getMimeType(file, fallback = null) {
    const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
    }
    switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}

export default {
    store,

    props: {
        component: {
            type: Object,
            required: true
        },
    },

    data: function () {
        let clickable = this.component.props.dropzoneID ? '.bp-panel.is-add.' + this.component.props.dropzoneID : '.bp-panel.is-add.dropzone-id-0';

        return {
            image: {
                src: null,
                type: null,
                name: '',
                original: null,
            },
            imageEditorVisible: false,

            zoomIndex: null,
            images: [],
            isLoading: false,
            isSecondButtonLoading: false,
            formData: new FormData,
            title: '',
            uploadImageId: null,

            dropzoneOptions: {
                autoProcessQueue: false,
                url: '/api/images/upload',
                maxFilesize: 100,
                timeout: 180000,
                headers: authHeader(),
                previewTemplate: this.template(),
                createImageThumbnails: false,
                clickable: clickable,
                paramName: "user_file_upload"
            },

            queueSize: 0,
            editFile: {
                id: []
            },

            selectedFolder: null
        }
    },

    mounted: function () {
        this.fetchImages();
        this.fetchOptions();

        let lastTarget = null;

        window.addEventListener("dragenter", (e) => {
            lastTarget = e.target; // cache the last target here
            document.getElementById("dropzone").classList.add("dz-drag-hover")
        });

        window.addEventListener("dragleave", (e) => {
            if(e.target === lastTarget || e.target === document) {
                document.getElementById("dropzone").classList.remove("dz-drag-hover")
            }
        });
    },

    computed: {
        id: function () { return this.$store.state.id },
        api: function () { return this.$store.state.api },
        options: function () { return this.$store.state.options.images },
        gallery: function () { return this.images.map(image => image.publicPath )},
        folderImages: function() {
            return this.images;
        },

        paramKey: function () {
            return {
                'airlines': 'provider',
                'others': 'provider',
                'ferries': 'provider',
                'hotels': 'provider',
                'destinations': 'destination',
                'order_concepts': 'orderConcept',
            }[this.api]
        },

        params: function () {
            return {
                [`${this.paramKey}.id`]: this.id,
                ...this.component.props.filterParams,
            }
        },
    },

    methods: {
        startUpload(post) {
            this.isLoading = true;
            this.imageEditorVisible = false;

            post.then(response => {
                this.isLoading = false;
                this.images.push(response.data);
            })
        },

        uploaded () {
            this.isLoading = false;
        },

        upload (original = false) {
            this.$refs.imageCropper.upload(original);
        },

        zoom: function(index) {
            this.zoomIndex = index;
        },

        fetchImages: function () {

            getImages(this.params)
                .then(response => {
                    this.images = response.data;
                })
        },

        fetchOptions: function () {
            optionsImages()
                .then(response => {
                    this.$store.commit('updateOptions', {
                        images: response.data
                    })
                })
        },

        label: function (image) {
            const type = !!this.options
                && this.options.categories.find(category => category.key === image.type);
            return !!type ? type.value : ''
        },

        deleteImage: function ({id}) {
            if (confirm('Really want to delete this image?')) {
                deleteImage(id)
                    .then(() => {
                        this.images = this.images
                            .filter(image => image.id !== id)
                    })
            }
        },

        openForm: function (data) {
            this.$refs.form.open({...data, ...this.component.props.filterParams.type && {type: this.component.props.filterParams.type}});
        },

        handleImageSubmit: function (data) {
            if (has(data, 'id')) {
                this.handleImageEdit(data)
            }
        },

        handleImageEdit: function (data) {
            if (Array.isArray(data.id)) {
                Promise.all(data.id.map(id => editImage({id, data: {
                    ...omit(data, 'id'),
                    type: data.type
                }})))
                .then(responses => {
                    const newImages = responses
                        .map(response => response.data);

                    this.images = [
                        ...this.images.filter(image => {
                            return !newImages.some(newImage => newImage.id === image.id)
                        }),
                        ...newImages
                    ];

                    this.$refs.form.close();
                })
            } else {
                editImage({id: data.id, data: {
                    ...prepareForBackend(data),
                }})
                    .then(response => {
                        this.images = this.images.map(image => {
                            if (image.id === data.id) {
                                return response.data
                            }

                            return image;
                        });

                        this.$refs.form.close();
                    })
            }
        },

        // Dropzone
        template: function () {
            return `<div class="dz-preview dz-file-preview" style="display:none">
                        <div class="dz-details">
                            <span data-dz-name></span>
                            <div class="dz-error-message">
                                <span data-dz-errormessage></span>
                            </div>
                        </div>
                        <div class="dz-progress">
                            <span class="dz-upload" data-dz-uploadprogress></span>
                        </div>

                        <div class="dz-remove">
                            <i class="material-icons" data-dz-remove>delete</i>
                        </div>
                    </div>`;
        },

        sendingEvent: function (file, xhr, formData) {
        },


        fileSuccess: function (file, response) {
        },

        fileCompleted: function (file, response) {
        },


        filesAdded: function (files) {
            this.editFile = { id: [] };
            //this.queueSize = files.length;

            // Ensure that you have a file before attempting to read it
            if (files && files[0]) {

                // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
                if (this.image) {
                    URL.revokeObjectURL(this.image.src)
                }
                // 2. Create the blob link to the file to optimize performance:
                const blob = URL.createObjectURL(files[0]);

                // 3. The steps below are designated to determine a file mime type to use it during the
                // getting of a cropped image from the canvas. You can replace it them by the following string,
                // but the type will be derived from the extension and it can lead to an incorrect result:
                //
                // this.image = {
                //    src: blob;
                //    type: files[0].type
                // }

                // Create a new FileReader to read this image binary data
                const reader = new FileReader();
                // Define a callback function to run, when FileReader finishes its job
                reader.onload = (e) => {
                    let name = files[0].name.split('.');
                    name.pop();
                    // Note: arrow function used here, so that "this.image" refers to the image of Vue component
                    this.image = {
                        // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
                        src: blob,
                        // Determine the image type to preserve it during the extracting the image from canvas:
                        type: getMimeType(e.target.result, files[0].type),
                        name: name.join('.'),
                        original: files[0]
                    };
                    this.imageEditorVisible=true;
                };
                // Start the reader job - read file as a data url (base64 format)
                reader.readAsArrayBuffer(files[0]);
            }
        },
    },

    destroyed() {
        // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
            URL.revokeObjectURL(this.image.src)
        }
    },

    components: {
        Gallery,
        Loading,
        ColumnItem,
        vueDropzone: vue2Dropzone,
        VueGallery,
        ImageForm,
        ImageCropper,
        ModalForm
    }
}
</script>
