var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popover",
    { attrs: { trigger: "hover" } },
    [
      _c("span", { on: { mouseover: _vm.loadBookingStand } }, [
        _vm._v(_vm._s(_vm.bookings)),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "popover" },
        [
          _c("div", { staticClass: "has-text-weight-semibold" }, [
            _vm._v("Buchungsstandentwicklung:"),
          ]),
          _vm._v(" "),
          _vm.bookingStand.loading
            ? _c("span", [_vm._v("Bitte warten...")])
            : [
                _vm.bookingStand.items.length > 0
                  ? _c(
                      "ul",
                      _vm._l(_vm.bookingStand.items, function (stand) {
                        return _c("li", { key: stand.id }, [
                          _vm._v(
                            _vm._s(_vm._f("fullYear")(stand.date)) +
                              " - " +
                              _vm._s(stand.numberOfPersons) +
                              " Pax"
                          ),
                        ])
                      }),
                      0
                    )
                  : [_vm._v("Es wurde noch nichts eingetragen")],
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }