<template>
    <div>

        <div class="level">
            <div class="level-left">
                <toggle-switch div-class="is-half" btn-class="is-large" v-model="shortVersion">
                    <option :value="false">Large Version</option>
                    <option :value="true">Kurze Version</option>
                </toggle-switch>
            </div>
            <div class="level-right">
                <button
                    class="button is-success"
                    v-bind:class="{ 'is-loading' : isLoading }"
                    v-on:click.prevent="generate"
                ><i class="material-icons">add_circle</i>Textdatel generieren</button>
            </div>

        </div>
        <div>
            <h2 class="is-headline">Einleitungstext</h2>
            <tip-tap
                v-model="item.catalogText"
                v-on:input="storeCatalogText"
            />
            <br>
        </div>
        <catalog-day
            v-bind:short-version="shortVersion"
            v-bind:is-concept="true"
            v-for="day in orderedDays"
            v-bind:key="day.id"
            v-bind:day="day"
            v-bind:options="{...options, orderOfDays: item.orderOfDays}"
        />
    </div>
</template>

<script>

import { TipTap } from '@components/form';
import Mixin       from '../tab';
import {notifyError, notifySuccess} from "@components/Notification";
import CatalogDay from "@orders/subpages/Catalog/Day";
import ToggleSwitch from "@components/form/ToggleSwitch";
import debounce from "lodash/debounce";
import {getFilePath} from "@utilities/functions";
import { saveAs }   from 'file-saver';
import axios    from 'axios';
import {getAxios} from "@api";


export default {
    mixins: [Mixin],
    data() {
        return {
            shortVersion: false,
            isLoading: false,
        }
    },
    computed: {
        orderID: function () { return this.$store.state.order.id; },
        orderedDays: function () {
            return this.item.orderedDays;
        }
    },
    methods: {
        storeCatalogText: debounce(function (data) {
            this.$store.dispatch('storeProperty', {key: 'catalogText', data, update: false})
                .then(() => notifySuccess('Der Text wurde gespeichert!'), error => notifyError('Notes not saved!'))
        }, 1000),

        generate: function () {
            axios.post(
                '/api/concepts/orders/' + this.item.id + '/catalog/generate-document',
                {},
                { headers: { 'Content-Type': 'application/json', } }
            ).then(response => {
                notifySuccess('Dokument generiert!');
                let document = response.data;
                saveAs(
                    getFilePath(document.id),
                    document.name ? document.name : document.path.split('/').pop()
                );

            }, error => { notifyError('Could\'t generate the Document! Server Error!'); })
                .then(() => { this.isLoading = false; });
        },

    },

    components: {
        ToggleSwitch,
        TipTap,CatalogDay
    },
}
</script>
