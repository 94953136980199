var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is-truncated is-250" },
    [
      _vm.agency
        ? _c(
            "a",
            {
              attrs: {
                href: "/agencies/" + _vm.agency.id,
                title: _vm.agency.name,
                target: "_blank",
              },
            },
            [_vm._v(_vm._s(_vm.truncatedAgency))]
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.agency && _vm.agency.id && _vm.provider && _vm.provider.id) ||
      (!_vm.agency && !_vm.provider)
        ? [_vm._v("-")]
        : _vm._e(),
      _vm._v(" "),
      _vm.provider && _vm.provider.id
        ? _c(
            "a",
            {
              staticClass: "is-contents",
              staticStyle: { display: "contents" },
              attrs: {
                href: _vm.provider.api,
                title: _vm.provider.name,
                target: "_blank",
              },
            },
            [_vm._v(_vm._s(_vm.provider.name))]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }